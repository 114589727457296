/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 05/05/2022
 * @Example
 */

export const types = {
    WA3F2041_LOAD_CBO_INVENTORY: "WA3F2041_LOAD_CBO_INVENTORY",
    WA3F2041_LOAD_EVENT: "WA3F2041_LOAD_EVENT",
    WA3F2041_LOAD_CBO_UNIT: "WA3F2041_LOAD_CBO_UNIT",
    WA3F2041_LOAD_FORM: "WA3F2041_LOAD_FORM",
    WA3F2041_LOAD_GRID_DETAIL: "WA3F2041_LOAD_GRID_DETAIL",
    WA3F2041_ON_SAVE: "WA3F2041_ON_SAVE",
    WA3F2041_LOAD_CBO_DROPDOWN: "WA3F2041_LOAD_CBO_DROPDOWN",
    WA3F2041_LOAD_GRID3: "WA3F2041_LOAD_GRID3",
};

export function loadCboInventory(params, cb) {
    return {
        type: types.WA3F2041_LOAD_CBO_INVENTORY,
        cb,
        params,
    };
}

export function loadEvent(params, cb) {
    return {
        type: types.WA3F2041_LOAD_EVENT,
        cb,
        params,
    };
}

export function loadCboUnit(params, cb) {
    return {
        type: types.WA3F2041_LOAD_CBO_UNIT,
        cb,
        params,
    };
}

export function loadForm(params, cb) {
    return {
        type: types.WA3F2041_LOAD_FORM,
        cb,
        params,
    };
}

export function onSave(params, cb) {
    return {
        type: types.WA3F2041_ON_SAVE,
        cb,
        params,
    };
}

export function loadGridDetail(params, cb) {
    return {
        type: types.WA3F2041_LOAD_GRID_DETAIL,
        cb,
        params,
    };
}

export function loadCboDropDown(params, cb) {
    return {
        type: types.WA3F2041_LOAD_CBO_DROPDOWN,
        cb,
        params,
    };
}

export function loadGrid3(params, cb) {
    return {
        type: types.WA3F2041_LOAD_GRID3,
        cb,
        params,
    };
}
/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 12/07/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from "../../../../services/api";
import { types } from "./WA3F2010_actions";

export default function WA3F2010Sagas() {
  return [
    watchLoadForm(),
    watchOnSaveEdit(),
    watchGetBookingNo(),
    watchOnSaveAdd(),
    watchLoadGridMenu(),
    watchCheckEdit(),
    watchLoadDepositAmount01(),
    watchLoadPromotions(),
    watchCheckBookingID(),
    watchLoadCboCTKM(),
    watchLoadGridPromotionService(),
    watchCheckReceipt(),
    watchCheckBeo()
  ]
}

export function* loadForm(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/load-form", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load form error", e);
  }
}

export function* watchLoadForm() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_LOAD_FORM, loadForm);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* onSaveEdit(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/edit", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("save edit data error", e);
  }
}

export function* watchOnSaveEdit() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_ON_SAVE_EDIT, onSaveEdit);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getBookingNo(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/get-booking-no", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("get booking no error", e);
  }
}

export function* watchGetBookingNo() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_GET_BOOKING_NO, getBookingNo);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* onSaveAdd(data) {
  try {
    yield delay(300);
    const res = yield Api.post("/wa3f2010/add", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("save add data error", e);
  }
}

export function* watchOnSaveAdd() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_ON_SAVE_ADD, onSaveAdd);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* loadGridMenu(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/load-menu", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load data grid menu error", e);
  }
}

export function* watchLoadGridMenu() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_LOAD_DATA_GRID_MENU, loadGridMenu);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* checkEdit(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/check-edit", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("check edit error", e);
  }
}

export function* watchCheckEdit() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_CHECK_EDIT, checkEdit);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* loadDepositAmount01(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/load-deposit-amount01", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load deposit amount01 error", e);
  }
}

export function* watchLoadDepositAmount01() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_LOAD_DEPOSIT_AMOUNT01, loadDepositAmount01);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* loadPromotions(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/load-promotions", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load promotions error", e);
  }
}

export function* watchLoadPromotions() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_LOAD_PROMOTIONS, loadPromotions);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* checkBookingID(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/check-booking-id", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("check booking id error", e);
  }
}

export function* watchCheckBookingID() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_CHECK_BOOKING_ID, checkBookingID);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* loadCboCTKM(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/load-combo-code-ctkm", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load cbo CTKM error", e);
  }
}

export function* watchLoadCboCTKM() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_LOAD_CBO_CTKM, loadCboCTKM);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* loadGridPromotionService(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/load-grid-promotion-service", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load grid promotion service error", e);
  }
}

export function* watchLoadGridPromotionService() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_LOAD_GRID_PROMOTION_SERVICE, loadGridPromotionService);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* checkReceipt(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/check-reciept", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("check receipt error", e);
  }
}

export function* watchCheckReceipt() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_CHECK_RECEIPT, checkReceipt);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* checkBeo(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2010/check-beo", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("check beo error", e);
  }
}

export function* watchCheckBeo() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2010_CHECK_BEO, checkBeo);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
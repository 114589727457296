export const localize = {
    "vi": {
        "BEM_%p_dang_nho_hon_%p_,ban_vui_long_kiem_tra_lai!": "%p đang nhỏ hơn %p, bạn vui lòng kiểm tra lại!",
        "BEM_403_loi": "Bạn không có quyền truy cập để xem trang này",
        "BEM_404_loi": "Trang bạn đang tìm kiếm có thể đã bị xóa, có tên thay đổi hoặc tạm thời không có.",
        "BEM_AC_vui_long_chon_y_kien_cua_minh": "Anh/Chị vui lòng chọn ý kiến của mình tương ứng với mức độ đồng ý cho những dịch vụ cung cấp dưới đây",
        "BEM_Ban_can_nhap_chi_tiet_booking": "Bạn cần nhập chi tiết Booking",
        "BEM_Ban_chua_chon_%p_,ban_co_muon_tiep_tuc_khong?": "Bạn chưa chọn %p, bạn có muốn tiếp tục không?",
        "BEM_Ban_chua_nhap_gia_tri": "Bạn chưa nhập giá trị",
        "BEM_Ban_chua_nhap_thong_tin_dich_vu_dat_them_trang_tri_ban_co_muon_tiep_tuc_khong?": "Bạn chưa nhập thông tin dịch vụ đặt thêm/ trang trí, bạn có muốn tiếp tục không?",
        "BEM_Ban_chua_nhap_thong_tin_tren_luoi": "Bạn chưa nhập thông tin trên lưới",
        "BEM_Ban_co_chac_muon_huy?": "Bạn có chắc muốn hủy?",
        "BEM_Ban_co_chac_muon_xoa?": "Bạn có chắc muốn xoá?",
        "BEM_Ban_co_muon_huy_phieu_nay_hay_khong": "Bạn có muốn hủy phiếu này hay không?",
        "BEM_Ban_co_muon_huy_phieu_nay_hay_khong?": "Bạn có muốn hủy phiếu này hay không?",
        "BEM_Ban_co_muon_huy_phieu_nay_khong": "Bạn có muốn hủy phiếu này không?",
        "BEM_Ban_co_muon_roi_khoi_trang_nay_khong?": "Bạn có muốn rời khỏi trang này không?",
        "BEM_Ban_co_muon_tiep_tuc_nhap_thong_tin_tren_luoi_khong?": "Bạn có muốn tiếp tục nhập thông tin trên lưới không ?",
        "BEM_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "Bạn đã đăng nhập sai quá số lần cho phép, vui lòng chờ %p để đăng nhập lại",
        "BEM_Ban_phai_chon_doanh_nghiep": "Bạn phải chọn doanh nghiệp",
        "BEM_Ban_phai_chon_gio_tu_truoc": "Bạn phải chọn giờ từ trước",
        "BEM_Ban_phai_nhap_don_gia_truoc_khi_bo_chon_mon": "Bạn phải nhập đơn giá trước khi bỏ chọn món",
        "BEM_Ban_phai_nhap_du_lieu_luoi": "Bạn phải nhập dữ liệu lưới",
        "BEM_Ban_phai_nhap_thong_tin_chi_tiet": "Bạn phải nhập thông tin chi tiết",
        "BEM_Ban_phai_thiet_lap_mau_bao_cao_truoc_khi_xuat_du_lieu": "Bạn phải thiết lập mẫu báo cáo trước khi xuất dữ liệu.",
        "BEM_BEO": "BEO",
        "BEM_Bo_phan_tiep_nhan": "Bộ phận tiếp nhận",
        "BEM_Boi": "Bởi",
        "BEM_Ca_nhan": "Cá nhân",
        "BEM_Cac_gop_y_khac": "Các góp ý khác",
        "BEM_Cac_tham_so_la_bat_buoc": "Các tham số là bắt buộc",
        "BEM_Cac_thong_so_luong": "Các thông số lương",
        "BEM_Cac_tieu_chi_la_bat_buoc_nhap": "Các chỉ tiêu bắt buộc nhập",
        "BEM_Cai_dat": "Cài đặt",
        "BEM_Cap_co_cau": "Cấp cơ cấu",
        "BEM_Cap_nhat_Booking": "Cập nhật Booking",
        "BEM_Cap_nhat_su_kien": "Cập nhật sự kiện",
        "BEM_Cap_nhat_thanh_cong": "Cập nhật thành công.",
        "BEM_Cap_nhat_thong_tin_Booking": "Cập nhật thông tin Booking",
        "BEM_Cap_nhat_trang_thai": "Cập nhật trạng thái",
        "BEM_Chi_ho_tro_dinh_kem_cac_file_co_dinh_dang": "Chỉ hỗ trợ đính kèm các file có định dạng",
        "BEM_Chi_tiet": "Chi tiết",
        "BEM_Chinh_sua_thanh_cong": "Chỉnh sửa thành công",
        "BEM_Cho_duyet": "Chờ duyệt",
        "BEM_Chon": "Chọn",
        "BEM_Chon_doanh_nghiep": "Chọn doanh nghiệp",
        "BEM_Chon_goi_menu": "Chọn gói menu",
        "BEM_Chon_ma_booking": "Chọn mã booking",
        "BEM_Chon_ma_khach_hang": "Chọn mã khách hàng",
        "BEM_Chon_ma_su_kien": "Chọn mã sự kiện",
        "BEM_Chon_nghiep_vu": "Chọn nghiệp vụ",
        "BEM_Chon_thoi_gian": "Chọn thời gian",
        "BEM_Chot_mon": "Chốt món",
        "BEM_Chu_the_thanh_vien": "Chủ thẻ thành viên",
        "BEM_Chua_co_lien_ket_nao": "Chưa có liên kết nào",
        "BEM_Chua_co_mau_thong_tin_tiec_dinh_kem_ban_co_muon_luu_khong?": "Chưa có mẫu thông tin tiệc đính kèm. Bạn có muốn lưu không?",
        "BEM_Chua_dap_ung": "Chưa đáp ứng",
        "BEM_Chua_doc": "Chưa đọc",
        "BEM_Chuc_nang_chua_phat_trien": "Chức năng chưa phát triển",
        "BEM_Chuc_vu": "Chức vụ",
        "BEM_Chuc_vu_bat_buoc_nhap": "Chức vụ bắt buộc nhập",
        "BEM_Chuong_trinh_khuyen_mai_dich_vu": "Chương trình khuyến mãi dịch vụ",
        "BEM_Chuyen_YCTC": "Chuyển YCTC",
        "BEM_Co": "Có",
        "BEM_Co_cau_cha": "Cơ cấu cha",
        "BEM_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "Có lỗi xảy ra trong quá trình xử lý.",
        "BEM_Co1": "Có",
        "BEM_Con_lai": "Còn lại",
        "BEM_Cong_suat_toi_da": "Công suất tối đa",
        "BEM_Cong_trinh/Cong_ty": "Công trình/Công ty",
        "BEM_Cot": "Cột",
        "BEM_Cung_ngay_su_kien_gio_tu_den_phai_khac_nhau": "Cùng ngày sự kiện Giờ từ- đến phải khác nhau",
        "BEM_Da_dat_coc": "Đã đặt cọc",
        "BEM_Da_duoc_book_vui_long_kiem_tra_lai": "đã được book, vui lòng kiểm tra lại",
        "BEM_Da_duyet": "Đã duyệt",
        "BEM_Da_ket_noi": "Đã kết nối tới internet",
        "BEM_Da_ky_Ngay": "Đã ký Ngày",
        "BEM_Da_phat_hanh_BEO": "Đã phát hành BEO",
        "BEM_Da_thu": "Đã thu",
        "BEM_Dang_duyet": "Đang duyệt",
        "BEM_Dang_nhap": "Đăng nhập",
        "BEM_dang_nhap_lai": "đăng nhập lại",
        "BEM_Dang_nhap_lai2": "Bạn đã đăng nhập tài khoản trên một thiết bị khác. Vui lòng đăng nhập lại.",
        "BEM_Dang_xuat1": "Đăng xuất",
        "BEM_Danh_dau_tat_ca_la_da_doc": "Đánh dầu tất cả là đã đọc",
        "BEM_Danh_sach_bien_nhan_coc": "Danh sách biên nhận cọc",
        "BEM_DANH_SACH_BIEN_NHAN_COC_CHO": "Danh sách biên nhận cọc chỗ",
        "BEM_Danh_sach_chi_tiet_thuc_don": "Danh sách chi tiết thực đơn",
        "BEM_Danh_sach_dinh_kem": "Danh sách đính kèm",
        "BEM_DANH_SACH_PHIEU_BEO": "Danh sách phiếu BEO",
        "BEM_Danh_sach_phieu_tinh_tien_tiec": "Danh sách phiếu tính tiền tiệc",
        "BEM_Dap_ung": "Đáp ứng",
        "BEM_Dat_coc": "Đặt cọc",
        "BEM_Den": "Đến",
        "BEM_Den_gio": "Đến giờ",
        "BEM_Den_ngay": "Đến ngày",
        "BEM_Dia_chi": "Địa chỉ",
        "BEM_Dia_diem_to_chuc_vi_tri": "Địa điểm tổ chức vị trí",
        "BEM_Dich_vu": "Dịch vụ",
        "BEM_Dich_vu_dat_them": "Dịch vụ đặt thêm",
        "BEM_Dich_vu_khuyen_mai": "Dịch vụ khuyến mãi",
        "BEM_Dien_giai": "Diễn giải",
        "BEM_Dien_giai_HD": "Diễn giải HĐ",
        "BEM_Dien_giai_hop_dong": "Diễn giải hợp đồng",
        "BEM_Dien_giai_phieu": "Diễn giải phiếu",
        "BEM_Dieu_chinh_hop_dong": "Điều chỉnh hợp đồng",
        "BEM_Dieu_chinh_su_kien": "Điều chỉnh sự kiện",
        "BEM_Dieu_khoan_TM": "Điều khoản TM",
        "BEM_Dieu_khoang_thuong_mai": "Điều khoảng thương mại",
        "BEM_Dinh_dang_file_khong_hop_le": "Định dạng file không hợp lệ",
        "BEM_Dinh_kem": "Đính kèm",
        "BEM_Doi_tuong_ap_dung": "Đối tượng áp dụng",
        "BEM_Doi_tuong_GTGT": "Đối tượng GTGT",
        "BEM_Don_gia": "Đơn giá",
        "BEM_Don_vi": "Đơn vị",
        "BEM_Don_vi_tinh": "Đơn vị tính",
        "BEM_Dong1": "Đóng",
        "BEM_DongC": "Đồng",
        "BEM_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Dữ liệu chưa được lưu. Bạn có muốn lưu không?",
        "BEM_Du_lieu_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc_khong?": "Dữ liệu trên lưới %p sẽ bị xóa, bạn có muốn tiếp tục không?",
        "BEM_Dung_luong_File_khong_duoc_lon_hon": "Dung lượng File không được lớn hơn",
        "BEM_Duyet": "Duyệt",
        "BEM_Duyet_phieu_BEO": "Duyệt phiếu BEO",
        "BEM_DVT": "ĐVT",
        "BEM_File_da_duoc_dinh_kem": "File đã được đính kèm",
        "BEM_File_khong_dung_dinh_dang": "File không đúng định dạng",
        "BEM_File_vuot_qua_dung_luong_cho_phep": "File vượt quá dung lượng cho phép",
        "BEM_File_vuot_qua_so_luong_cho_phep": "File vượt quá số lượng cho phép",
        "BEM_Ghi_chu": "Ghi chú",
        "BEM_Gia_ban": "Giá bán",
        "BEM_Gia_ban/ban": "Gía bán/ bàn",
        "BEM_Gia_menu": "Giá menu",
        "BEM_Gia_tri_bat_buoc_nhap": "Giá trị bắt buộc nhập!",
        "BEM_Gia_tri_hoa_don": "Giá trị hóa đơn",
        "BEM_Gia_tri_hop_dong": "Giá trị hợp đồng",
        "BEM_Giai_doan": "Giai đoạn",
        "BEM_Giam_gia": "Giảm giá",
        "BEM_Giam_gia_thuc_don": "Giảm giá thực đơn",
        "BEM_Giay": "Giây",
        "BEM_Gio": "Giờ",
        "BEM_Gio_su_kien": "Giờ sự kiện",
        "BEM_Gio_tiec": "Giờ tiệc",
        "BEM_Gio_tu": "Giờ từ",
        "BEM_Gio_tu_phai_nho_hon_Gio_den": "Giờ từ phải nhỏ hơn Giờ đến.",
        "BEM_Giu_cho": "Giữ chỗ",
        "BEM_Gui": "Gửi",
        "BEM_Hai_long_can_cai_tien_them": "Hài lòng cần cải tiến thêm",
        "BEM_Hanh_dong": "Hành động",
        "BEM_He_thong_dang_trong_qua_trinh_nang_cap": "Hệ thống đang trong quá trình nâng cấp.",
        "BEM_Hien_thi_phieu_da_huy": "Hiển thị phiếu đã hủy",
        "BEM_Hieu_luc": "Hiệu lực",
        "BEM_Hinh_thuc_tiec": "Hình thức tiệc",
        "BEM_Hoan_toan_hai_long": "Hoàn toàn hài lòng",
        "BEM_Hom_nay": "Hôm nay",
        "BEM_Hom_qua": "Hôm qua",
        "BEM_Hop_dong_dich_vu": "Hợp đồng dịch vụ",
        "BEM_Huy": "Hủy",
        "BEM_Huy_BEO": "Hủy BEO",
        "BEM_Huy_phieu": "Hủy phiếu",
        "BEM_Huy_phieu1": "Hủy phiếu",
        "BEM_Huy_thanh_cong": "Hủy thành công.",
        "BEM_In": "&In",
        "BEM_In1": "In",
        "BEM_Khac": "Khác",
        "BEM_Khach_hang": "Khách hàng",
        "BEM_Khao_sat_y_kien_khach_hang": "Khảo sát ý kiến khách hàng",
        "BEM_Khoi_la_bat_buoc_nhap": "Khối bắt buộc nhập",
        "BEM_Khong": "Không",
        "BEM_khong_co_du_lieu": "Không có dữ liệu",
        "BEM_Khong_co_ket_noi": "Không có kết nối tới internet",
        "BEM_Khong_co_thong_bao": "Không có thông báo",
        "BEM_Khong_du_quyen": "Bạn không đủ quyền, Vui lòng đăng nhập lại",
        "BEM_Khong_tim_thay_EOFFICE_URL": "Không tìm thấy EOFFICE URL",
        "BEM_Khong_tim_thay_trang": "KHÔNG TÌM THẤY TRANG",
        "BEM_Khong_xet1": "Không xét",
        "BEM_Khuyen_mai": "Khuyến mãi",
        "BEM_Lap_hop_dong_dich_vu": "Lập hợp đồng dịch vụ",
        "BEM_Lap_phieu_BEO": "Lập phiếu BEO",
        "BEM_Lap_phieu_bien_nhan_coc_cho": "Lập phiếu biên nhận cọc chỗ",
        "BEM_Lap_phieu_bien_nhan_coc_su_kien": "Lập phiếu biên nhận cọc sự kiện",
        "BEM_Lap_phieu_tinh_tien_tiec": "Lập phiếu tính tiền tiệc",
        "BEM_Lich": "Lịch",
        "BEM_Lich_chi_tiet": "Lịch chi tiết",
        "BEM_Lich_su_tac_dong": "Lịch sử tác động",
        "BEM_Loai_ban_tiec": "Loại bàn tiệc",
        "BEM_Loai_dieu_chinhU": "Loại điều chỉnh",
        "BEM_Loai_doi_tuong_ap_dung": "Loại đối tượng áp dụng",
        "BEM_Loai_doi_tuong_GTGT": "Loại đối tượng GTGT",
        "BEM_Loai_hinh_su_kien": "Loại hình sự kiện",
        "BEM_Loai_hinh_su_kien_": "Loại hình sự kiện",
        "BEM_Loai_hinh_tiec": "Loại hình tiệc",
        "BEM_Loai_hop_dong": "Loại hợp đồng",
        "BEM_Loai_phieu": "Loại phiếu",
        "BEM_Loai_quy_trinh": "Loại quy trình",
        "BEM_Loai_thuc_don": "Loại thực đơn",
        "BEM_Loai_tien": "Loại tiền",
        "BEM_Loc": "Lọc",
        "BEM_Loc_chon_hanh_dong": "Lọc chọn hành động",
        "BEM_Loi_chua_xac_dinh": "Lỗi chưa xác định",
        "BEM_Loi_khong_lay_duoc_token_CDN": "Lỗi không lấy được CDN token",
        "BEM_Luoi_%p_chua_nhap_lieu_ban_co_muon_luu_khong?": "Lưới %p chưa nhập liệu, bạn có muốn lưu không?",
        "BEM_Luoi_thong_tin_thanh_toan_chua_nhap_lieu,_ban_khong_duoc_luu": "Lưới thông tin thanh toán chưa nhập liệu, bạn không được lưu",
        "BEM_Luoi_thong_tin_thanh_toan_chua_nhap_lieu.Ban_khong_duoc_luu": "Lưới thông tin thanh toán chưa nhập liệu, bạn không được lưu",
        "BEM_Luu": "Lưu",
        "BEM_Luu_lich_su_khong_thanh_cong": "Lưu lịch sử không thành công",
        "BEM_Luu_thanh_cong": "Lưu thành công",
        "BEM_Luu_thong_tin_chung_tu_khong_thanh_cong": "Lưu thông tin chứng từ không thành công",
        "BEM_Luu_va_nhap_tiep": "Lưu và Nhập tiếp",
        "BEM_Ly_do": "Lý do",
        "BEM_Ma": "Mã",
        "BEM_Ma_bo_phan": "Mã bộ phận",
        "BEM_Ma_booking": "Mã Booking",
        "BEM_Ma_booking_nay_da_duoc_lap_trong_ma_su_kien_%p_ban_khong_the_luu": "Mã Booking này đã được lập trong mã sự kiện %p bạn không thể lưu",
        "BEM_Ma_co_cau": "Mã cơ cấu",
        "BEM_Ma_co_ky_tu_khong_hop_le": "Mã có ký tự không hợp lệ.",
        "BEM_Ma_CTKM": "Mã CTKM",
        "BEM_Ma_dich_vu": "Mã dịch vụ",
        "BEM_Ma_doi_tuong": "Mã đối tượng",
        "BEM_Ma_doi_tuong_GTGT": "Mã đối tượng GTGT",
        "BEM_Ma_hang": "Mã hàng",
        "BEM_Ma_KH": "Mã KH",
        "BEM_Ma_khach_hang": "Mã khách hàng",
        "BEM_Ma_khach_hangU": "Mã khách hàng",
        "BEM_Ma_menu": "Mã Menu",
        "BEM_Ma_mon_an": "Mã món ăn",
        "BEM_Ma_nguoi_tiep_nhan": "Mã người tiếp nhận",
        "BEM_Ma_NVKD": "Mã NVKD",
        "BEM_Ma_phieu_BEO": "Mã phiếu BEO",
        "BEM_Ma_sanh_tiec": "Mã sảnh tiệc",
        "BEM_Ma_so_thue": "Mã số thuế",
        "BEM_Ma_su_kien": "Mã sự kiện",
        "BEM_Mat_khau": "Mật khẩu",
        "BEM_Mau_bao_cao": "Mẫu báo cáo",
        "BEM_Mau_giao_dien": "Màu giao diện",
        "BEM_Menu_nay_da_duoc_chon": "Menu này đã được chọn",
        "BEM_Menu_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc?": "Menu trên lưới %p sẽ bị xóa, bạn có muốn tiếp tục ?",
        "BEM_Mo_ta": "Mô tả",
        "BEM_Mon_an": "Món ăn",
        "BEM_Muc_do_dong_y": "Mức độ đồng ý",
        "BEM_Nam_nay": "Năm nay",
        "BEM_Nam_truoc": "Năm trước",
        "BEM_Nam1": "Nam",
        "BEM_Ngan_hang": "Ngân hàng",
        "BEM_Ngay": "Ngày",
        "BEM_Ngay_am_lich": "Ngày âm lịch",
        "BEM_Ngay_danh_gia_bat_buoc_nhap": "Ngày đánh giá bắt buộc nhập",
        "BEM_Ngay_duong_lich": "Ngày dương lịch",
        "BEM_Ngay_HD": "Ngày HĐ",
        "BEM_Ngay_hop_dong": "Ngày hợp đồng",
        "BEM_Ngay_lap": "Ngày lập",
        "BEM_Ngay_phieu": "Ngày phiếu",
        "BEM_Ngay_su_kien": "Ngày sự kiện",
        "BEM_Ngay_su_kien_phai_lon_hon_hoac_bang_ngay_lap": "Ngày sự kiện phải lớn hơn hoặc bằng ngày lập",
        "BEM_Ngay_sua_cuoi": "Ngày sửa cuối",
        "BEM_Ngay_tac_dong": "Ngày tác động",
        "BEM_Ngay_tiec": "Ngày tiệc",
        "BEM_Ngay_tiec_AL": "Ngày tiệc (AL)",
        "BEM_Ngay_tiec_DL": "Ngày tiệc (DL)",
        "BEM_Ngay_to_chuc": "Ngày tổ chức",
        "BEM_Nghiep_vu": "Nghiệp vụ",
        "BEM_Ngoai_te": "Ngoại tệ",
        "BEM_Nguoi_dai_dien": "Người đại diện",
        "BEM_Nguoi_danh_gia": "Người đánh giá",
        "BEM_Nguoi_danh_gia_bat_buoc_nhap": "Người đánh giá bắt buộc nhập",
        "BEM_Nguoi_dung_khong_su_dung": "Người dùng không sử dụng",
        "BEM_Nguoi_lap": "Người lập",
        "BEM_Nguoi_lapU": "Người lập",
        "BEM_Nguoi_lien_he": "Người liên hệ",
        "BEM_Nguoi_sua_cuoi": "Người sửa cuối",
        "BEM_Nguoi_tiep_nhan": "Người tiếp nhận",
        "BEM_Nguoi_uy_quyen": "Người ủy quyền",
        "BEM_Nhan_vien": "Nhân viên",
        "BEM_Nhap": "Nhập",
        "BEM_Nhap_tiep": "Nhập tiếp",
        "BEM_Nho_mat_khau1": "Nhớ mật khẩu",
        "BEM_Nhom_mon_an": "Nhóm món ăn",
        "BEM_Nhom_thue": "Nhóm thuế",
        "BEM_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "Những ý kiến này sẽ được Công ty chúng tôi ghi nhận và làm cơ sở để nâng cao chất lượng dịch vụ nhằm đáp ứng nhu cầu của khách hàng ngày càng tốt hơn",
        "BEM_No_data": "Không có dữ liệu",
        "BEM_Noi_dung": "Nội dung",
        "BEM_Noi_dung_can_tim": "Nội dung cần tìm",
        "BEM_Noi_dung_trang_tri": "Nội dung trang trí",
        "BEM_Phat_hanh_BEO": "Phát hành BEO",
        "BEM_Phat_sinh": "Phát sinh",
        "BEM_Phien_ban": "Phiên bản",
        "BEM_Phu_luc_hop_dong": "Phụ lục hợp đồng",
        "BEM_Phuong_thuc_thanh_toan": "Phương thức thanh toán",
        "BEM_Phuong_thuc_TT": "Phương thức TT",
        "BEM_phut": "phút",
        "BEM_Quy_uoc_nhu_sau": "Quy ước như sau",
        "BEM_Sanh": "Sảnh",
        "BEM_Sanh_tiec": "Sảnh tiệc",
        "BEM_Sao_chep": "Sao chép",
        "BEM_SDT": "SĐT",
        "BEM_Sl_khach_ban": "SL khách/bàn",
        "BEM_SL_tuy_chon": "SL tùy chọn",
        "BEM_So_ban_chay": "Số bàn chay",
        "BEM_So_ban_chay_hoac_so_ban_chinh_phai_lon_hon": "Số bàn chay hoặc số bàn chính phải lớn hơn",
        "BEM_So_ban_chinh": "Số bàn chính",
        "BEM_So_ban_chinh_thuc": "Số bàn chính thức",
        "BEM_So_ban_du_bi": "Số bàn dự bị",
        "BEM_So_ban_tang": "Số bàn tặng",
        "BEM_So_dien_thoai": "Số điện thoại",
        "BEM_So_dong": "Số dòng",
        "BEM_So_DT": "Số ĐT",
        "BEM_So_Fax": "Số Fax",
        "BEM_So_HD_truoc": "Số HĐ trước",
        "BEM_So_hop_dong": "Số hợp đồng",
        "BEM_So_hop_dong_truoc": "Số hợp đồng trước",
        "BEM_So_luong": "Số lượng",
        "BEM_So_luong_ban_B": "Số lượng bàn",
        "BEM_So_luong_khach": "Số lượng khách",
        "BEM_So_phieu": "Số phiếu",
        "BEM_So_thuc_don": "Số thực đơn",
        "BEM_So_tien_bang_chu": "Số tiền bằng chữ",
        "BEM_So_tien_coc": "Số tiền cọc",
        "BEM_So_tien_thanh_toan_khong_bang_tong_so_tien_can_thanh_toan": "Số tiền thanh toán không bằng tổng số tiền cần thanh toán",
        "BEM_STT": "STT",
        "BEM_STT_len_mon": "STT lên món",
        "BEM_Su_kien": "Sự kiện",
        "BEM_Su_kien_nay_da_lap_phieu_BEO_vui_long_kiem_tra_lai": "Sự kiện này đã lập phiếu BEO, viu lòng kiểm tra lại",
        "BEM_Sua": "Sửa",
        "BEM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "Tài khoản đăng nhập hoặc mật khẩu không đúng.",
        "BEM_Tai_ky_hop_dong": "Tái ký hợp đồng",
        "BEM_Tam_dap_ung_Can_cai_tien_them": "Tạm đáp ứng, cần phải cải tiến thêm",
        "BEM_Tang_luong_truoc_thoi_han": "Tăng lương trước thời hạn",
        "BEM_Tap_tin_dinh_kem_dang_duoc_xu_ly": "Tập tin đính kèm đang được xử lý",
        "BEM_Tat_ca": "Tất cả",
        "BEM_Ten": "Tên",
        "BEM_Ten_bao_cao": "Tên báo cáo",
        "BEM_Ten_bo_phan": "Tên bộ phận",
        "BEM_Ten_CTKM": "Tên CTKM",
        "BEM_Ten_Dang_Nhap": "Tên đăng nhập",
        "BEM_Ten_dich_vu": "Tên dịch vụ",
        "BEM_Ten_dinh_kem": "Tên đính kèm",
        "BEM_Ten_doi_tuong": "Tên đối tượng",
        "BEM_Ten_hang": "Tên hàng",
        "BEM_Ten_KH": "Tên KH",
        "BEM_Ten_khach_hang": "Tên khách hàng",
        "BEM_Ten_menu": "Tên Menu",
        "BEM_Ten_mon": "Tên món",
        "BEM_Ten_mon_an": "Tên món ăn",
        "BEM_Ten_nguoi_tiep_nhan": "Tên người tiếp nhận",
        "BEM_Ten_NVKD": "Tên NVKD",
        "BEM_Ten_san_pham_dich_vu": "Tên sản phẩm/ dịch vụ",
        "BEM_Ten_sanh": "Tên sảnh",
        "BEM_Ten_sanh_tiec": "Tên sảnh tiệc",
        "BEM_Ten_su_kien": "Tên sự kiện",
        "BEM_Tham_so_truyen_vao_khong_hop_le": "Tham số truyền vào không hợp lệ",
        "BEM_Thang_nay": "Tháng này",
        "BEM_Thang_truoc": "Tháng trước",
        "BEM_Thanh_tien": "Thành tiền",
        "BEM_Thanh_tien_dang_nho_hon_%p_ban_vui_long_kiem_tra_lai!": "Thành tiền đang nhỏ hơn %p bạn vui lòng kiểm tra lại!",
        "BEM_Thanh_tien_khuyen_mai": "Thành tiền khuyến mãi",
        "BEM_Thanh_tien_quy_doi": "Thành tiền quy đổi",
        "BEM_Thanh_tien_sau_GG_KM": "Thành tiền sau GG/KM",
        "BEM_Thanh_tien_truoc_GG_KM": "Thành tiền trước GG/KM",
        "BEM_Thay_doi_don_vi_se_quay_lai_trang_chu": "Thay đổi đơn vị sẽ quay lại trang chủ",
        "BEM_Thay_doi_thong_tin_Booking": "Thay đổi thông tin Booking",
        "BEM_The_thanh_vien": "Thẻ thành viên",
        "BEM_Them": "Thêm",
        "BEM_Them_moi1": "Thêm mới",
        "BEM_Them_su_kien": "Thêm sự kiện",
        "BEM_Thoi_gian": "Thời gian",
        "BEM_Thong_bao": "Thông báo",
        "BEM_Thong_bao1": "Thông Báo",
        "BEM_Thong_baoU": "Thông báo",
        "BEM_Thong_tin_bien_nhan": "Thông tin biên nhận",
        "BEM_Thong_tin_booking": "Thông tin booking",
        "BEM_Thong_tin_chi_tiet": "Thông tin chi tiết",
        "BEM_Thong_tin_chinh": "Thông tin chính",
        "BEM_Thong_tin_chung": "Thông tin chung",
        "BEM_Thong_tin_cung_cap_khong_hop_le": "Thông tin cung cấp không hợp lệ!",
        "BEM_Thong_tin_khach_hang": "Thông tin khách hàng",
        "BEM_Thong_tin_luoi_chi_tiet_se_bi_xoa_ban_co_muon_tiep_tuc": "Thông tin lưới chi tiết sẽ bị xóa, bạn có muốn tiếp tục",
        "BEM_Thong_tin_phieu": "Thông tin phiếu",
        "BEM_Thong_tin_sanh": "Thông tin sảnh ",
        "BEM_Thong_tin_su_kien": "Thông tin sự kiện",
        "BEM_Thong_tin_thanh_toan": "Thông tin thanh toán",
        "BEM_Thong_tin_thuong_mai": "Thông tin thương mại",
        "BEM_Thong_tin_to_chuc_su_kien": "Thông tin tổ chức sự kiện",
        "BEM_Thong_tin_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc?": "Thông tin trên lưới %p sẽ bị xóa, bạn có muốn tiếp tục ?",
        "BEM_Thu_tien_coc": "Thu tiền cọc",
        "BEM_Thu_tien_tiec_su_kien": "Thu tiền tiệc sự kiện",
        "BEM_Thuc_don_chay": "Thực đơn chay",
        "BEM_Thuc_don_chinh": "Thực đơn chính",
        "BEM_Thuc_don_phu": "Thực đơn phụ",
        "BEM_Thuc_thu": "Thực thu",
        "BEM_Thue_suat": "Thuế suất",
        "BEM_Tiec": "Tiệc",
        "BEM_Tien_coc": "Tiền cọc",
        "BEM_Tien_coc_cho": "Tiền cọc chỗ",
        "BEM_Tien_coc_quy_doi": "Tiền cọc quy đổi",
        "BEM_Tien_coc_su_kien": "Tiền cọc sự kiện",
        "BEM_Tien_dat_coc": "Tiền đặt cọc",
        "BEM_Tien_dat_coc_quy_doi": "Tiền đặt cọc quy đổi",
        "BEM_Tien_thue": "Tiền thuế",
        "BEM_Tien_thue_quy_doi": "Tiền thuế quy đổi",
        "BEM_Tiep_tuc": "Tiếp tục",
        "BEM_Tim_kiem": "Tìm kiếm",
        "BEM_Tinh_trang": "Tình trạng",
        "BEM_Tong": "Tổng",
        "BEM_Tong_cong": "Tổng cộng",
        "BEM_Tong_diem": "Tổng điểm",
        "BEM_Tong_phat_sinh_sau_tiec": "Tổng phát sinh sau tiệc",
        "BEM_Tong_sl_khach": "Tổng SL khách",
        "BEM_Tong_tien": "Tổng tiền",
        "BEM_Tong_tien_chua_bao_gom_VAT": "Tổng tiền (chưa bao gồm VAT)",
        "BEM_Tong_tien_coc": "Tổng tiền cọc",
        "BEM_Tong_tien_con_lai": "Tổng tiền còn lại",
        "BEM_Tong_tien_con_lai_quy_doi": "Tổng tiền còn lại quy đổi",
        "BEM_Tong_tien_da_bao_gom_VAT": "Tổng tiền (đã bao gồm VAT)",
        "BEM_Tong_tien_dich_vu_dat_them": "Tổng tiền dịch vụ đặt thêm",
        "BEM_Tong_tien_dich_vu_khuyen_mai": "Tổng tiền dịch vụ khuyến mãi",
        "BEM_Tong_tien_giam_gia": "Tổng tiền giảm giá",
        "BEM_Tong_tien_giam_gia_quy_doi": "Tổng tiền giảm giá quy đổi",
        "BEM_Tong_tien_giam_gia_thuc_don": "Tổng tiền giảm giá thực đơn",
        "BEM_Tong_tien_khuyen_mai": "Tổng tiền khuyến mãi",
        "BEM_Tong_tien_khuyen_mai_quy_doi": "Tổng tiền khuyến mãi quy đổi",
        "BEM_Tong_tien_quy_doi_chua_bao_gom_VAT": "Tổng tiền quy đổi (chưa bao gồm VAT)",
        "BEM_Tong_tien_quy_doi_da_bao_gom_VAT": "Tổng tiền quy đổi (đã bao gồm VAT)",
        "BEM_Tong_tien_sau_GG_KM": "Tổng tiền sau GG/KM",
        "BEM_Tong_tien_sau_GG_KM_quy_doi": "Tổng tiền sau GG/KM quy đổi",
        "BEM_Tong_tien_thuc_don_chay": "Tổng tiền thực đơn chay",
        "BEM_Tong_tien_thuc_don_chinh": "Tổng tiền thực đơn chính",
        "BEM_Tong_tien_thuc_don_phu": "Tổng tiền thực đơn phụ",
        "BEM_Tong_tien_thuc_thu": "Tổng tiền thực thu",
        "BEM_Tong_tien_trang_tri": "Tổng tiền trang trí",
        "BEM_Tong_tien_truoc_GG_KM": "Tổng tiền trước GG/KM",
        "BEM_Tong_tien_truoc_GG_KM_quy_doi": "Tổng tiền trước GG/KM quy đổi",
        "BEM_Trang_Chu": "Trang chủ",
        "BEM_Trang_thai": "Trạng thái",
        "BEM_Trang_thai_BEO": "Trạng thái BEO",
        "BEM_Trang_thai_HD": "Trạng thái HĐ",
        "BEM_Trang_thai_hop_dong": "Trạng thái hợp đồng",
        "BEM_Trang_thai_hop_dongU": "Trang thái hợp đồng",
        "BEM_Trang_thai_su_kien": "Trạng thái sự kiện",
        "BEM_Trang_tri": "Trang trí",
        "BEM_Tri_gia": "Trị giá",
        "BEM_Trinh_duyet_khong_co_quyen_mo_popup": "Trình duyệt không có quyền mở popup",
        "BEM_trong": "trống",
        "BEM_Truong_nay_bat_buoc_nhap": "Trường này bắt buộc nhập",
        "BEM_Truy_cap_khong_hop_le": "Truy cập không hợp lệ",
        "BEM_Tu": "Từ",
        "BEM_Tu_choi": "Từ chối",
        "BEM_Tu_gio": "Từ giờ",
        "BEM_Tu_ngay": "Từ ngày",
        "BEM_Tuan_nay": "Tuần này",
        "BEM_Tuan_truoc": "Tuần trước",
        "BEM_Ty_gia": "Tỷ giá",
        "BEM_Version_su_kien": "Version (sự kiện)",
        "BEM_Vi_tri": "Vị trí",
        "BEM_VoucherID_khong_duoc_de_trong": "VoucherID không được để trống",
        "BEM_Vui_long_chon_day_du_mon_an_va_dich_vu": "Vui lòng chọn đầy đủ món ăn và dịch vụ",
        "BEM_Vui_long_nhap_day_du_thong_tin_tren_luoi": "Vui lòng nhập đầy đủ thông tin trên lưới",
        "BEM_Vui_long_truy_cap_sau": "Vui lòng truy cập sau!",
        "BEM_Xac_nhan_BEO_va_YC_vat_tu": "Xác nhận BEO và YC vật tư",
        "BEM_Xac_nhan_phieu_BEO_va_tinh_yeu_cau_vat_tu_cho_tiec_ban_co_muon_tiep_tuc_khong?": "Xác nhận phiếu BEO và Tính yêu cầu vật tư cho cho tiệc. Bạn có muốn tiếp tục không?",
        "BEM_Xac_nhan_va_phat_hanh_BEO": "Xác nhận và phát hành BEO",
        "BEM_Xem_chi_tiet": "Xem chi tiết",
        "BEM_Xem_them": "Xem thêm",
        "BEM_Xem_truoc": "Xem trước",
        "BEM_Xin_cam_on": "Xin cảm ơn",
        "BEM_Xoa": "Xóa",
        "BEM_Xoa_thanh_cong": "Xóa thành công",
        "BEM_Xuat_du_lieu": "Xuất dữ liệu",
        "BEM_Xuat_du_lieu_bao_cao": "Xuất dữ liệu báo cáo",
        "BEM_Y_kien_dong_gop": "Ý kiến đóng góp"
    },
    "en": {
        "BEM_%p_dang_nho_hon_%p_,ban_vui_long_kiem_tra_lai!": "%p_dang_nho_hon_%p_,ban_vui_long_kiem_tra_lai!",
        "BEM_403_loi": "You do not have permission to view this page",
        "BEM_404_loi": "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
        "BEM_AC_vui_long_chon_y_kien_cua_minh": "Please let us know your evaluation on service level",
        "BEM_Ban_can_nhap_chi_tiet_booking": "Ban_can_nhap_chi_tiet_booking",
        "BEM_Ban_chua_chon_%p_,ban_co_muon_tiep_tuc_khong?": "Ban_chua_chon_%p_,ban_co_muon_tiep_tuc_khong?",
        "BEM_Ban_chua_nhap_gia_tri": "You have not entered value",
        "BEM_Ban_chua_nhap_thong_tin_dich_vu_dat_them_trang_tri_ban_co_muon_tiep_tuc_khong?": "Ban_chua_nhap_thong_tin_dich_vu_dat_them_trang_tri_ban_co_muon_tiep_tuc_khong?",
        "BEM_Ban_chua_nhap_thong_tin_tren_luoi": "Ban_chua_nhap_thong_tin_tren_luoi",
        "BEM_Ban_co_chac_muon_huy?": "Ban_co_chac_muon_huy?",
        "BEM_Ban_co_chac_muon_xoa?": "Are you sure to delete?",
        "BEM_Ban_co_muon_huy_phieu_nay_hay_khong": "Ban_co_muon_huy_phieu_nay_hay_khong",
        "BEM_Ban_co_muon_huy_phieu_nay_hay_khong?": "Ban_co_muon_huy_phieu_nay_hay_khong?",
        "BEM_Ban_co_muon_huy_phieu_nay_khong": "Do you want to cancel this voucher?",
        "BEM_Ban_co_muon_roi_khoi_trang_nay_khong?": "Ban_co_muon_roi_khoi_trang_nay_khong?",
        "BEM_Ban_co_muon_tiep_tuc_nhap_thong_tin_tren_luoi_khong?": "Ban_co_muon_tiep_tuc_nhap_thong_tin_tren_luoi_khong?",
        "BEM_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai",
        "BEM_Ban_phai_chon_doanh_nghiep": "Ban_phai_chon_doanh_nghiep",
        "BEM_Ban_phai_chon_gio_tu_truoc": "Ban_phai_chon_gio_tu_truoc",
        "BEM_Ban_phai_nhap_don_gia_truoc_khi_bo_chon_mon": "Ban_phai_nhap_don_gia_truoc_khi_bo_chon_mon",
        "BEM_Ban_phai_nhap_du_lieu_luoi": "Ban_phai_nhap_du_lieu_luoi",
        "BEM_Ban_phai_nhap_thong_tin_chi_tiet": "Ban_phai_nhap_thong_tin_chi_tiet",
        "BEM_Ban_phai_thiet_lap_mau_bao_cao_truoc_khi_xuat_du_lieu": "Ban_phai_thiet_lap_mau_bao_cao_truoc_khi_xuat_du_lieu",
        "BEM_BEO": "BEO",
        "BEM_Bo_phan_tiep_nhan": "Department",
        "BEM_Boi": "By",
        "BEM_Ca_nhan": "Personal",
        "BEM_Cac_gop_y_khac": "Other feedback",
        "BEM_Cac_tham_so_la_bat_buoc": "The parameters are compulsory",
        "BEM_Cac_thong_so_luong": "Salary Parameters",
        "BEM_Cac_tieu_chi_la_bat_buoc_nhap": "Compulsory criteria",
        "BEM_Cai_dat": "Settings",
        "BEM_Cap_co_cau": "Structure level",
        "BEM_Cap_nhat_Booking": "Cap_nhat_Booking",
        "BEM_Cap_nhat_su_kien": "Cap_nhat_su_kien",
        "BEM_Cap_nhat_thanh_cong": "Data was updated successfully",
        "BEM_Cap_nhat_thong_tin_Booking": "Cap_nhat_thong_tin_Booking",
        "BEM_Cap_nhat_trang_thai": "Change Status",
        "BEM_Chi_ho_tro_dinh_kem_cac_file_co_dinh_dang": "Chi_ho_tro_dinh_kem_cac_file_co_dinh_dang",
        "BEM_Chi_tiet": "Detail",
        "BEM_Chinh_sua_thanh_cong": "Successful updating",
        "BEM_Cho_duyet": "Pending Approval",
        "BEM_Chon": "Select",
        "BEM_Chon_doanh_nghiep": "Choose company",
        "BEM_Chon_goi_menu": "Chon_goi_menu",
        "BEM_Chon_ma_booking": "Chon_ma_booking",
        "BEM_Chon_ma_khach_hang": "Chon_ma_khach_hang",
        "BEM_Chon_ma_su_kien": "Chon_ma_su_kien",
        "BEM_Chon_nghiep_vu": "Chon_nghiep_vu",
        "BEM_Chon_thoi_gian": "Time",
        "BEM_Chot_mon": "Chot_mon",
        "BEM_Chu_the_thanh_vien": "Chu_the_thanh_vien",
        "BEM_Chua_co_lien_ket_nao": "There is no link",
        "BEM_Chua_co_mau_thong_tin_tiec_dinh_kem_ban_co_muon_luu_khong?": "Chua_co_mau_thong_tin_tiec_dinh_kem_ban_co_muon_luu_khong?",
        "BEM_Chua_dap_ung": "Not qualified",
        "BEM_Chua_doc": "Chua_doc",
        "BEM_Chuc_nang_chua_phat_trien": "Pending features",
        "BEM_Chuc_vu": "Position",
        "BEM_Chuc_vu_bat_buoc_nhap": "Position is required",
        "BEM_Chuong_trinh_khuyen_mai_dich_vu": "Chuong_trinh_khuyen_mai_dich_vu",
        "BEM_Chuyen_YCTC": "Chuyen_YCTC",
        "BEM_Co": "Credit",
        "BEM_Co_cau_cha": "Father node",
        "BEM_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "An error occurred during processing.",
        "BEM_Co1": "Yes",
        "BEM_Con_lai": "Remaining",
        "BEM_Cong_suat_toi_da": "Cong_suat_toi_da",
        "BEM_Cong_trinh/Cong_ty": "Project/Company",
        "BEM_Cot": "Column",
        "BEM_Cung_ngay_su_kien_gio_tu_den_phai_khac_nhau": "Cung_ngay_su_kien_gio_tu_den_phai_khac_nhau",
        "BEM_Da_dat_coc": "Da_dat_coc",
        "BEM_Da_duoc_book_vui_long_kiem_tra_lai": "Da_duoc_book_vui_long_kiem_tra_lai",
        "BEM_Da_duyet": "Approved",
        "BEM_Da_ket_noi": "Connected to internet",
        "BEM_Da_ky_Ngay": "Signed Date",
        "BEM_Da_phat_hanh_BEO": "Da_phat_hanh_BEO",
        "BEM_Da_thu": "Received",
        "BEM_Dang_duyet": "Pending for Approval",
        "BEM_Dang_nhap": "Log in",
        "BEM_dang_nhap_lai": "Login again",
        "BEM_Dang_nhap_lai2": "You are already logged in on a different device. Please login again.",
        "BEM_Dang_xuat1": "Logout",
        "BEM_Danh_dau_tat_ca_la_da_doc": "Danh_dau_tat_ca_la_da_doc",
        "BEM_Danh_sach_bien_nhan_coc": "Danh_sach_bien_nhan_coc",
        "BEM_DANH_SACH_BIEN_NHAN_COC_CHO": "DANH_SACH_BIEN_NHAN_COC_CHO",
        "BEM_Danh_sach_chi_tiet_thuc_don": "Danh_sach_chi_tiet_thuc_don",
        "BEM_Danh_sach_dinh_kem": "Attachment List",
        "BEM_DANH_SACH_PHIEU_BEO": "DANH_SACH_PHIEU_BEO",
        "BEM_Danh_sach_phieu_tinh_tien_tiec": "Danh_sach_phieu_tinh_tien_tiec",
        "BEM_Dap_ung": "Qualified",
        "BEM_Dat_coc": "Deposit",
        "BEM_Den": "To",
        "BEM_Den_gio": "Hour to",
        "BEM_Den_ngay": "Date to",
        "BEM_Dia_chi": "Address",
        "BEM_Dia_diem_to_chuc_vi_tri": "Dia_diem_to_chuc_vi_tri",
        "BEM_Dich_vu": "Service",
        "BEM_Dich_vu_dat_them": "Dich_vu_dat_them",
        "BEM_Dich_vu_khuyen_mai": "Dich_vu_khuyen_mai",
        "BEM_Dien_giai": "Description",
        "BEM_Dien_giai_HD": "Invoice Description",
        "BEM_Dien_giai_hop_dong": "Contract Description",
        "BEM_Dien_giai_phieu": "Voucher Desc",
        "BEM_Dieu_chinh_hop_dong": "Contract Adjustment",
        "BEM_Dieu_chinh_su_kien": "Dieu_chinh_su_kien",
        "BEM_Dieu_khoan_TM": "Payment Term",
        "BEM_Dieu_khoang_thuong_mai": "Dieu_khoang_thuong_mai",
        "BEM_Dinh_dang_file_khong_hop_le": "Invalid file format",
        "BEM_Dinh_kem": "Attachments",
        "BEM_Doi_tuong_ap_dung": "Applicable objects",
        "BEM_Doi_tuong_GTGT": "VAT Object",
        "BEM_Don_gia": "Unit Price",
        "BEM_Don_vi": "Business Unit",
        "BEM_Don_vi_tinh": "Unit",
        "BEM_Dong1": "Close",
        "BEM_DongC": "Dollar",
        "BEM_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Data unsaved. Do you want to save it?",
        "BEM_Du_lieu_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc_khong?": "Du_lieu_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc_khong?",
        "BEM_Dung_luong_File_khong_duoc_lon_hon": "File size must not be larger than",
        "BEM_Duyet": "Approve",
        "BEM_Duyet_phieu_BEO": "Duyet_phieu_BEO",
        "BEM_DVT": "unit of measurement",
        "BEM_File_da_duoc_dinh_kem": "Files attached",
        "BEM_File_khong_dung_dinh_dang": "Invalid-format files",
        "BEM_File_vuot_qua_dung_luong_cho_phep": "Over-sized files",
        "BEM_File_vuot_qua_so_luong_cho_phep": "Number of files is over limit",
        "BEM_Ghi_chu": "Notes",
        "BEM_Gia_ban": "Sales Price",
        "BEM_Gia_ban/ban": "Gia_ban/ban",
        "BEM_Gia_menu": "Gia_menu",
        "BEM_Gia_tri_bat_buoc_nhap": "Value Obligatory",
        "BEM_Gia_tri_hoa_don": "Invoice Amount",
        "BEM_Gia_tri_hop_dong": "Contract amount",
        "BEM_Giai_doan": "Phase",
        "BEM_Giam_gia": "Reduction",
        "BEM_Giam_gia_thuc_don": "Giam_gia_thuc_don",
        "BEM_Giay": "Seconds",
        "BEM_Gio": "Hour",
        "BEM_Gio_su_kien": "Gio_su_kien",
        "BEM_Gio_tiec": "Gio_tiec",
        "BEM_Gio_tu": "Time from",
        "BEM_Gio_tu_phai_nho_hon_Gio_den": "Time form <= Time to",
        "BEM_Giu_cho": "Reserve",
        "BEM_Gui": "Send",
        "BEM_Hai_long_can_cai_tien_them": "Satifactory and needs improvement",
        "BEM_Hanh_dong": "Action",
        "BEM_He_thong_dang_trong_qua_trinh_nang_cap": "The system is currently undergoing maintenance.",
        "BEM_Hien_thi_phieu_da_huy": "Hien_thi_phieu_da_huy",
        "BEM_Hieu_luc": "Effective.",
        "BEM_Hinh_thuc_tiec": "Hinh_thuc_tiec",
        "BEM_Hoan_toan_hai_long": "Fully satisfied",
        "BEM_Hom_nay": "Today",
        "BEM_Hom_qua": "Yesterday",
        "BEM_Hop_dong_dich_vu": "Service Contract",
        "BEM_Huy": "Cancel",
        "BEM_Huy_BEO": "Huy_BEO",
        "BEM_Huy_phieu": "Cancel the vote",
        "BEM_Huy_phieu1": "Huy_phieu1",
        "BEM_Huy_thanh_cong": "Successfully cancelled",
        "BEM_In": "&Print",
        "BEM_In1": "Print",
        "BEM_Khac": "Other",
        "BEM_Khach_hang": "Customer",
        "BEM_Khao_sat_y_kien_khach_hang": "Customer survey",
        "BEM_Khoi_la_bat_buoc_nhap": "Division is required",
        "BEM_Khong": "No",
        "BEM_khong_co_du_lieu": "No data",
        "BEM_Khong_co_ket_noi": "No conection to internet",
        "BEM_Khong_co_thong_bao": "No notifications",
        "BEM_Khong_du_quyen": "Insufficient permissions",
        "BEM_Khong_tim_thay_EOFFICE_URL": "EOFFICE_URL not found",
        "BEM_Khong_tim_thay_trang": "PAGE NOT FOUND",
        "BEM_Khong_xet1": "not consider",
        "BEM_Khuyen_mai": "Promotion",
        "BEM_Lap_hop_dong_dich_vu": "Create Service Contract",
        "BEM_Lap_phieu_BEO": "Lap_phieu_BEO",
        "BEM_Lap_phieu_bien_nhan_coc_cho": "Lap_phieu_bien_nhan_coc_cho",
        "BEM_Lap_phieu_bien_nhan_coc_su_kien": "Lap_phieu_bien_nhan_coc_su_kien",
        "BEM_Lap_phieu_tinh_tien_tiec": "Lap_phieu_tinh_tien_tiec",
        "BEM_Lich": "Calendar.",
        "BEM_Lich_chi_tiet": "Lich_chi_tiet",
        "BEM_Lich_su_tac_dong": "Transaction History",
        "BEM_Loai_ban_tiec": "Loai_ban_tiec",
        "BEM_Loai_dieu_chinhU": "Adjustment Type",
        "BEM_Loai_doi_tuong_ap_dung": "Applied Object Types",
        "BEM_Loai_doi_tuong_GTGT": "VAT Object Type",
        "BEM_Loai_hinh_su_kien": "Loai_hinh_su_kien",
        "BEM_Loai_hinh_su_kien_": "Loai_hinh_su_kien ",
        "BEM_Loai_hinh_tiec": "Loai_hinh_tiec",
        "BEM_Loai_hop_dong": "Contract Type",
        "BEM_Loai_phieu": "Voucher Type",
        "BEM_Loai_quy_trinh": "Workflow type",
        "BEM_Loai_thuc_don": "Loai_thuc_don",
        "BEM_Loai_tien": "Another currency",
        "BEM_Loc": "Filter",
        "BEM_Loc_chon_hanh_dong": "Select Action",
        "BEM_Loi_chua_xac_dinh": "Error is not determined",
        "BEM_Loi_khong_lay_duoc_token_CDN": "Error while getting CDN token ",
        "BEM_Luoi_%p_chua_nhap_lieu_ban_co_muon_luu_khong?": "Luoi_%p_chua_nhap_lieu_ban_co_muon_luu_khong?",
        "BEM_Luoi_thong_tin_thanh_toan_chua_nhap_lieu,_ban_khong_duoc_luu": "Luoi_thong_tin_thanh_toan_chua_nhap_lieu,_ban_khong_duoc_luu",
        "BEM_Luoi_thong_tin_thanh_toan_chua_nhap_lieu.Ban_khong_duoc_luu": "Luoi_thong_tin_thanh_toan_chua_nhap_lieu.Ban_khong_duoc_luu",
        "BEM_Luu": "Save",
        "BEM_Luu_lich_su_khong_thanh_cong": "Save failed attempt histrory",
        "BEM_Luu_thanh_cong": "Saved successfully",
        "BEM_Luu_thong_tin_chung_tu_khong_thanh_cong": "Document data cannot be saved",
        "BEM_Luu_va_nhap_tiep": "Save and Add New",
        "BEM_Ly_do": "Reason",
        "BEM_Ma": "Code",
        "BEM_Ma_bo_phan": "Work Center Code",
        "BEM_Ma_booking": "Booking No",
        "BEM_Ma_booking_nay_da_duoc_lap_trong_ma_su_kien_%p_ban_khong_the_luu": "Ma_booking_nay_da_duoc_lap_trong_ma_su_kien_%p_ban_khong_the_luu",
        "BEM_Ma_co_cau": "Structure code",
        "BEM_Ma_co_ky_tu_khong_hop_le": "Code with invalid characters.",
        "BEM_Ma_CTKM": "Ma_CTKM",
        "BEM_Ma_dich_vu": "Service Code",
        "BEM_Ma_doi_tuong": "Object Code",
        "BEM_Ma_doi_tuong_GTGT": "VAT Object Code",
        "BEM_Ma_hang": "Item Code",
        "BEM_Ma_KH": "Plan No",
        "BEM_Ma_khach_hang": "Customer Code",
        "BEM_Ma_khach_hangU": "Customer ID",
        "BEM_Ma_menu": "Ma_menu",
        "BEM_Ma_mon_an": "Ma_mon_an",
        "BEM_Ma_nguoi_tiep_nhan": "Receiver",
        "BEM_Ma_NVKD": "Sales person ID",
        "BEM_Ma_phieu_BEO": "Ma_phieu_BEO",
        "BEM_Ma_sanh_tiec": "Ma_sanh_tiec",
        "BEM_Ma_so_thue": "Tax Codes",
        "BEM_Ma_su_kien": "Event Code",
        "BEM_Mat_khau": "Password",
        "BEM_Mau_bao_cao": "Report Code",
        "BEM_Mau_giao_dien": "Theme color",
        "BEM_Menu_nay_da_duoc_chon": "Menu_nay_da_duoc_chon",
        "BEM_Menu_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc?": "Menu_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc?",
        "BEM_Mo_ta": "Description",
        "BEM_Mon_an": "Mon_an",
        "BEM_Muc_do_dong_y": "Agreement level",
        "BEM_Nam_nay": "This Year",
        "BEM_Nam_truoc": "Last Year",
        "BEM_Nam1": "Male",
        "BEM_Ngan_hang": "Banks",
        "BEM_Ngay": "Date",
        "BEM_Ngay_am_lich": "Ngay_am_lich",
        "BEM_Ngay_danh_gia_bat_buoc_nhap": "Evaluation date required",
        "BEM_Ngay_duong_lich": "Ngay_duong_lich",
        "BEM_Ngay_HD": "Invoice Date",
        "BEM_Ngay_hop_dong": "Contract Date",
        "BEM_Ngay_lap": "Created date",
        "BEM_Ngay_phieu": "Voucher Date",
        "BEM_Ngay_su_kien": "Date",
        "BEM_Ngay_su_kien_phai_lon_hon_hoac_bang_ngay_lap": "Ngay_su_kien_phai_lon_hon_hoac_bang_ngay_lap",
        "BEM_Ngay_sua_cuoi": "Last Modify Date",
        "BEM_Ngay_tac_dong": "Trans Date",
        "BEM_Ngay_tiec": "Ngay_tiec",
        "BEM_Ngay_tiec_AL": "Ngay_tiec_AL",
        "BEM_Ngay_tiec_DL": "Ngay_tiec_DL",
        "BEM_Ngay_to_chuc": "Date of organizing",
        "BEM_Nghiep_vu": "Transaction",
        "BEM_Ngoai_te": "Currency",
        "BEM_Nguoi_dai_dien": "Representative person",
        "BEM_Nguoi_danh_gia": "Evaluation Person",
        "BEM_Nguoi_danh_gia_bat_buoc_nhap": "Evaluation person is required",
        "BEM_Nguoi_dung_khong_su_dung": "User is disabled",
        "BEM_Nguoi_lap": "Created by",
        "BEM_Nguoi_lapU": "Employee",
        "BEM_Nguoi_lien_he": "Contact",
        "BEM_Nguoi_sua_cuoi": "Last Modify User",
        "BEM_Nguoi_tiep_nhan": "Receiver",
        "BEM_Nguoi_uy_quyen": "Authorizing person",
        "BEM_Nhan_vien": "Employee",
        "BEM_Nhap": "Text",
        "BEM_Nhap_tiep": "Next",
        "BEM_Nho_mat_khau1": "Remember me",
        "BEM_Nhom_mon_an": "Nhom_mon_an",
        "BEM_Nhom_thue": "VAT Group",
        "BEM_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "This feedback will be taken by our company in oder to improve our service quality level and provide better service to our customers.",
        "BEM_No_data": "No Data",
        "BEM_Noi_dung": "Content",
        "BEM_Noi_dung_can_tim": "Content to searching",
        "BEM_Noi_dung_trang_tri": "Noi_dung_trang_tri",
        "BEM_Phat_hanh_BEO": "Phat_hanh_BEO",
        "BEM_Phat_sinh": "Period",
        "BEM_Phien_ban": "Version",
        "BEM_Phu_luc_hop_dong": "Appendix contract",
        "BEM_Phuong_thuc_thanh_toan": "Payment Method",
        "BEM_Phuong_thuc_TT": "Payment Method",
        "BEM_phut": "minute(s)",
        "BEM_Quy_uoc_nhu_sau": "Agreed as follows",
        "BEM_Sanh": "Sanh",
        "BEM_Sanh_tiec": "Sanh_tiec",
        "BEM_Sao_chep": "Copy",
        "BEM_SDT": "Receiver Phone",
        "BEM_Sl_khach_ban": "Sl_khach_ban",
        "BEM_SL_tuy_chon": "SL_tuy_chon",
        "BEM_So_ban_chay": "So_ban_chay",
        "BEM_So_ban_chay_hoac_so_ban_chinh_phai_lon_hon": "So_ban_chay_hoac_so_ban_chinh_phai_lon_hon",
        "BEM_So_ban_chinh": "So_ban_chinh",
        "BEM_So_ban_chinh_thuc": "So_ban_chinh_thuc",
        "BEM_So_ban_du_bi": "So_ban_du_bi",
        "BEM_So_ban_tang": "So_ban_tang",
        "BEM_So_dien_thoai": "Tel no.",
        "BEM_So_dong": "Number of rows",
        "BEM_So_DT": "Tel no",
        "BEM_So_Fax": "Fax No",
        "BEM_So_HD_truoc": "So_HD_truoc",
        "BEM_So_hop_dong": "Contract No",
        "BEM_So_hop_dong_truoc": "Previous Contract No",
        "BEM_So_luong": "Quantity",
        "BEM_So_luong_ban_B": "So_luong_ban_B",
        "BEM_So_luong_khach": "So_luong_khach",
        "BEM_So_phieu": "Voucher Number",
        "BEM_So_thuc_don": "So_thuc_don",
        "BEM_So_tien_bang_chu": "So_tien_bang_chu",
        "BEM_So_tien_coc": "So_tien_coc",
        "BEM_So_tien_thanh_toan_khong_bang_tong_so_tien_can_thanh_toan": "So_tien_thanh_toan_khong_bang_tong_so_tien_can_thanh_toan",
        "BEM_STT": "No.",
        "BEM_STT_len_mon": "STT_len_mon",
        "BEM_Su_kien": "Event",
        "BEM_Su_kien_nay_da_lap_phieu_BEO_vui_long_kiem_tra_lai": "Su_kien_nay_da_lap_phieu_BEO_vui_long_kiem_tra_lai",
        "BEM_Sua": "Edit",
        "BEM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "Incorrect login or password",
        "BEM_Tai_ky_hop_dong": "Resign Contract",
        "BEM_Tam_dap_ung_Can_cai_tien_them": "Temporarily satisfied, needs improvement",
        "BEM_Tang_luong_truoc_thoi_han": "Increasing salary ahead of schedule",
        "BEM_Tap_tin_dinh_kem_dang_duoc_xu_ly": "Attachments in processing",
        "BEM_Tat_ca": "All",
        "BEM_Ten": "Name",
        "BEM_Ten_bao_cao": "Report Name",
        "BEM_Ten_bo_phan": "Work Center Name",
        "BEM_Ten_CTKM": "Ten_CTKM",
        "BEM_Ten_Dang_Nhap": "Username",
        "BEM_Ten_dich_vu": "Service Name",
        "BEM_Ten_dinh_kem": "Attachment Name",
        "BEM_Ten_doi_tuong": "Object Name",
        "BEM_Ten_hang": "Item Name",
        "BEM_Ten_KH": "Course Name",
        "BEM_Ten_khach_hang": "Customer Name",
        "BEM_Ten_menu": "Ten_menu",
        "BEM_Ten_mon": "Ten_mon",
        "BEM_Ten_mon_an": "Ten_mon_an",
        "BEM_Ten_nguoi_tiep_nhan": "Receiver Name",
        "BEM_Ten_NVKD": "Sales person name",
        "BEM_Ten_san_pham_dich_vu": "Ten_san_pham_dich_vu",
        "BEM_Ten_sanh": "Ten_sanh",
        "BEM_Ten_sanh_tiec": "Ten_sanh_tiec",
        "BEM_Ten_su_kien": "Event Name",
        "BEM_Tham_so_truyen_vao_khong_hop_le": "Input parameters invalid",
        "BEM_Thang_nay": "This month",
        "BEM_Thang_truoc": "Last month",
        "BEM_Thanh_tien": "Amount",
        "BEM_Thanh_tien_dang_nho_hon_%p_ban_vui_long_kiem_tra_lai!": "Thanh_tien_dang_nho_hon_%p_ban_vui_long_kiem_tra_lai!",
        "BEM_Thanh_tien_khuyen_mai": "Thanh_tien_khuyen_mai",
        "BEM_Thanh_tien_quy_doi": "Converted Amount",
        "BEM_Thanh_tien_sau_GG_KM": "Thanh_tien_sau_GG_KM",
        "BEM_Thanh_tien_truoc_GG_KM": "Thanh_tien_truoc_GG_KM",
        "BEM_Thay_doi_don_vi_se_quay_lai_trang_chu": "Thay_doi_don_vi_se_quay_lai_trang_chu",
        "BEM_Thay_doi_thong_tin_Booking": "Thay_doi_thong_tin_Booking",
        "BEM_The_thanh_vien": "The_thanh_vien",
        "BEM_Them": "Add",
        "BEM_Them_moi1": "Add new",
        "BEM_Them_su_kien": "Them_su_kien",
        "BEM_Thoi_gian": "Time",
        "BEM_Thong_bao": "Announcement",
        "BEM_Thong_bao1": "Alert",
        "BEM_Thong_baoU": "Notifications",
        "BEM_Thong_tin_bien_nhan": "Thong_tin_bien_nhan",
        "BEM_Thong_tin_booking": "Thong_tin_booking",
        "BEM_Thong_tin_chi_tiet": "Detail info",
        "BEM_Thong_tin_chinh": "Main",
        "BEM_Thong_tin_chung": "General Information",
        "BEM_Thong_tin_cung_cap_khong_hop_le": "Provided data invalid",
        "BEM_Thong_tin_khach_hang": "Customer Information",
        "BEM_Thong_tin_luoi_chi_tiet_se_bi_xoa_ban_co_muon_tiep_tuc": "Thong_tin_luoi_chi_tiet_se_bi_xoa_ban_co_muon_tiep_tuc",
        "BEM_Thong_tin_phieu": "Voucher Information",
        "BEM_Thong_tin_sanh": "Thong_tin_sanh",
        "BEM_Thong_tin_su_kien": "Thong_tin_su_kien",
        "BEM_Thong_tin_thanh_toan": "Information Billing",
        "BEM_Thong_tin_thuong_mai": "Commercial Information",
        "BEM_Thong_tin_to_chuc_su_kien": "Thong_tin_to_chuc_su_kien",
        "BEM_Thong_tin_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc?": "Thong_tin_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc?",
        "BEM_Thu_tien_coc": "Thu_tien_coc",
        "BEM_Thu_tien_tiec_su_kien": "Thu_tien_tiec_su_kien",
        "BEM_Thuc_don_chay": "Thuc_don_chay",
        "BEM_Thuc_don_chinh": "Thuc_don_chinh",
        "BEM_Thuc_don_phu": "Thuc_don_phu",
        "BEM_Thuc_thu": "Thuc_thu",
        "BEM_Thue_suat": "Tax Rate",
        "BEM_Tiec": "Tiec",
        "BEM_Tien_coc": "Tien_coc",
        "BEM_Tien_coc_cho": "Tien_coc_cho",
        "BEM_Tien_coc_quy_doi": "Tien_coc_quy_doi",
        "BEM_Tien_coc_su_kien": "Tien_coc_su_kien",
        "BEM_Tien_dat_coc": "Amount",
        "BEM_Tien_dat_coc_quy_doi": "Tien_dat_coc_quy_doi",
        "BEM_Tien_thue": "Tax Amount",
        "BEM_Tien_thue_quy_doi": "Tien_thue_quy_doi",
        "BEM_Tiep_tuc": "Continue",
        "BEM_Tim_kiem": "Finding Information",
        "BEM_Tinh_trang": "Status",
        "BEM_Tong": "Total",
        "BEM_Tong_cong": "Total",
        "BEM_Tong_diem": "Total points",
        "BEM_Tong_phat_sinh_sau_tiec": "Tong_phat_sinh_sau_tiec",
        "BEM_Tong_sl_khach": "Tong_sl_khach",
        "BEM_Tong_tien": "Total",
        "BEM_Tong_tien_chua_bao_gom_VAT": "Tong_tien_chua_bao_gom_VAT",
        "BEM_Tong_tien_coc": "Tong_tien_coc",
        "BEM_Tong_tien_con_lai": "Original Remain PO Amount",
        "BEM_Tong_tien_con_lai_quy_doi": "Tong_tien_con_lai_quy_doi",
        "BEM_Tong_tien_da_bao_gom_VAT": "Tong_tien_da_bao_gom_VAT",
        "BEM_Tong_tien_dich_vu_dat_them": "Tong_tien_dich_vu_dat_them",
        "BEM_Tong_tien_dich_vu_khuyen_mai": "Tong_tien_dich_vu_khuyen_mai",
        "BEM_Tong_tien_giam_gia": "Tong_tien_giam_gia",
        "BEM_Tong_tien_giam_gia_quy_doi": "Tong_tien_giam_gia_quy_doi",
        "BEM_Tong_tien_giam_gia_thuc_don": "Tong_tien_giam_gia_thuc_don",
        "BEM_Tong_tien_khuyen_mai": "Tong_tien_khuyen_mai",
        "BEM_Tong_tien_khuyen_mai_quy_doi": "Tong_tien_khuyen_mai_quy_doi",
        "BEM_Tong_tien_quy_doi_chua_bao_gom_VAT": "Tong_tien_quy_doi_chua_bao_gom_VAT",
        "BEM_Tong_tien_quy_doi_da_bao_gom_VAT": "Tong_tien_quy_doi_da_bao_gom_VAT",
        "BEM_Tong_tien_sau_GG_KM": "Tong_tien_sau_GG_KM",
        "BEM_Tong_tien_sau_GG_KM_quy_doi": "Tong_tien_sau_GG_KM_quy_doi",
        "BEM_Tong_tien_thuc_don_chay": "Tong_tien_thuc_don_chay",
        "BEM_Tong_tien_thuc_don_chinh": "Tong_tien_thuc_don_chinh",
        "BEM_Tong_tien_thuc_don_phu": "Tong_tien_thuc_don_phu",
        "BEM_Tong_tien_thuc_thu": "Tong_tien_thuc_thu",
        "BEM_Tong_tien_trang_tri": "Tong_tien_trang_tri",
        "BEM_Tong_tien_truoc_GG_KM": "Tong_tien_truoc_GG_KM",
        "BEM_Tong_tien_truoc_GG_KM_quy_doi": "Tong_tien_truoc_GG_KM_quy_doi",
        "BEM_Trang_Chu": "Home Page",
        "BEM_Trang_thai": "Status",
        "BEM_Trang_thai_BEO": "Trang_thai_BEO",
        "BEM_Trang_thai_HD": "Contract Status",
        "BEM_Trang_thai_hop_dong": "Contract Status",
        "BEM_Trang_thai_hop_dongU": "Trang_thai_hop_dongU",
        "BEM_Trang_thai_su_kien": "Trang_thai_su_kien",
        "BEM_Trang_tri": "Trang_tri",
        "BEM_Tri_gia": "Value",
        "BEM_Trinh_duyet_khong_co_quyen_mo_popup": "Not enough permissions to open popup",
        "BEM_trong": "empty",
        "BEM_Truong_nay_bat_buoc_nhap": "This field is required",
        "BEM_Truy_cap_khong_hop_le": "Invalid access",
        "BEM_Tu": "From",
        "BEM_Tu_choi": "Reject",
        "BEM_Tu_gio": "From hour",
        "BEM_Tu_ngay": "Date from",
        "BEM_Tuan_nay": "This week",
        "BEM_Tuan_truoc": "Last week",
        "BEM_Ty_gia": "Exchange Rate",
        "BEM_Version_su_kien": "Version_su_kien",
        "BEM_Vi_tri": "Job position",
        "BEM_VoucherID_khong_duoc_de_trong": "VoucherID cannot be blank",
        "BEM_Vui_long_chon_day_du_mon_an_va_dich_vu": "Vui_long_chon_day_du_mon_an_va_dich_vu",
        "BEM_Vui_long_nhap_day_du_thong_tin_tren_luoi": "Vui_long_nhap_day_du_thong_tin_tren_luoi",
        "BEM_Vui_long_truy_cap_sau": "Vui_long_truy_cap_sau",
        "BEM_Xac_nhan_BEO_va_YC_vat_tu": "Xac_nhan_BEO_va_YC_vat_tu",
        "BEM_Xac_nhan_phieu_BEO_va_tinh_yeu_cau_vat_tu_cho_tiec_ban_co_muon_tiep_tuc_khong?": "Xac_nhan_phieu_BEO_va_tinh_yeu_cau_vat_tu_cho_tiec_ban_co_muon_tiep_tuc_khong?",
        "BEM_Xac_nhan_va_phat_hanh_BEO": "Xac_nhan_va_phat_hanh_BEO",
        "BEM_Xem_chi_tiet": "View Detail",
        "BEM_Xem_them": "See more",
        "BEM_Xem_truoc": "Preview",
        "BEM_Xin_cam_on": "Xin_cam_on",
        "BEM_Xoa": "Delete",
        "BEM_Xoa_thanh_cong": "Deleted successfully",
        "BEM_Xuat_du_lieu": "Export Data",
        "BEM_Xuat_du_lieu_bao_cao": "Xuat_du_lieu_bao_cao",
        "BEM_Y_kien_dong_gop": "Feedback"
    },
    "ja": {
        "BEM_%p_dang_nho_hon_%p_,ban_vui_long_kiem_tra_lai!": "",
        "BEM_403_loi": "",
        "BEM_404_loi": "",
        "BEM_AC_vui_long_chon_y_kien_cua_minh": "",
        "BEM_Ban_can_nhap_chi_tiet_booking": "",
        "BEM_Ban_chua_chon_%p_,ban_co_muon_tiep_tuc_khong?": "",
        "BEM_Ban_chua_nhap_gia_tri": "",
        "BEM_Ban_chua_nhap_thong_tin_dich_vu_dat_them_trang_tri_ban_co_muon_tiep_tuc_khong?": "",
        "BEM_Ban_chua_nhap_thong_tin_tren_luoi": "",
        "BEM_Ban_co_chac_muon_huy?": "",
        "BEM_Ban_co_chac_muon_xoa?": "",
        "BEM_Ban_co_muon_huy_phieu_nay_hay_khong": "",
        "BEM_Ban_co_muon_huy_phieu_nay_hay_khong?": "",
        "BEM_Ban_co_muon_huy_phieu_nay_khong": "",
        "BEM_Ban_co_muon_roi_khoi_trang_nay_khong?": "",
        "BEM_Ban_co_muon_tiep_tuc_nhap_thong_tin_tren_luoi_khong?": "",
        "BEM_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "",
        "BEM_Ban_phai_chon_doanh_nghiep": "",
        "BEM_Ban_phai_chon_gio_tu_truoc": "",
        "BEM_Ban_phai_nhap_don_gia_truoc_khi_bo_chon_mon": "",
        "BEM_Ban_phai_nhap_du_lieu_luoi": "",
        "BEM_Ban_phai_nhap_thong_tin_chi_tiet": "",
        "BEM_Ban_phai_thiet_lap_mau_bao_cao_truoc_khi_xuat_du_lieu": "",
        "BEM_BEO": "",
        "BEM_Bo_phan_tiep_nhan": "",
        "BEM_Boi": "",
        "BEM_Ca_nhan": "Personal",
        "BEM_Cac_gop_y_khac": "",
        "BEM_Cac_tham_so_la_bat_buoc": "",
        "BEM_Cac_thong_so_luong": "",
        "BEM_Cac_tieu_chi_la_bat_buoc_nhap": "",
        "BEM_Cai_dat": "",
        "BEM_Cap_co_cau": "",
        "BEM_Cap_nhat_Booking": "",
        "BEM_Cap_nhat_su_kien": "",
        "BEM_Cap_nhat_thanh_cong": "",
        "BEM_Cap_nhat_thong_tin_Booking": "",
        "BEM_Cap_nhat_trang_thai": "",
        "BEM_Chi_ho_tro_dinh_kem_cac_file_co_dinh_dang": "",
        "BEM_Chi_tiet": "詳細",
        "BEM_Chinh_sua_thanh_cong": "",
        "BEM_Cho_duyet": "承認待ち",
        "BEM_Chon": "Select",
        "BEM_Chon_doanh_nghiep": "",
        "BEM_Chon_goi_menu": "",
        "BEM_Chon_ma_booking": "",
        "BEM_Chon_ma_khach_hang": "",
        "BEM_Chon_ma_su_kien": "",
        "BEM_Chon_nghiep_vu": "",
        "BEM_Chon_thoi_gian": "",
        "BEM_Chot_mon": "",
        "BEM_Chu_the_thanh_vien": "",
        "BEM_Chua_co_lien_ket_nao": "",
        "BEM_Chua_co_mau_thong_tin_tiec_dinh_kem_ban_co_muon_luu_khong?": "",
        "BEM_Chua_dap_ung": "",
        "BEM_Chua_doc": "",
        "BEM_Chuc_nang_chua_phat_trien": "",
        "BEM_Chuc_vu": "位置",
        "BEM_Chuc_vu_bat_buoc_nhap": "",
        "BEM_Chuong_trinh_khuyen_mai_dich_vu": "",
        "BEM_Chuyen_YCTC": "",
        "BEM_Co": "貸方",
        "BEM_Co_cau_cha": "",
        "BEM_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "",
        "BEM_Co1": "",
        "BEM_Con_lai": "残り",
        "BEM_Cong_suat_toi_da": "",
        "BEM_Cong_trinh/Cong_ty": "",
        "BEM_Cot": "",
        "BEM_Cung_ngay_su_kien_gio_tu_den_phai_khac_nhau": "",
        "BEM_Da_dat_coc": "",
        "BEM_Da_duoc_book_vui_long_kiem_tra_lai": "",
        "BEM_Da_duyet": "承認された",
        "BEM_Da_ket_noi": "",
        "BEM_Da_ky_Ngay": "",
        "BEM_Da_phat_hanh_BEO": "",
        "BEM_Da_thu": "",
        "BEM_Dang_duyet": "承認待ち",
        "BEM_Dang_nhap": "ログイン",
        "BEM_dang_nhap_lai": "再度ログオンを",
        "BEM_Dang_nhap_lai2": "",
        "BEM_Dang_xuat1": "",
        "BEM_Danh_dau_tat_ca_la_da_doc": "",
        "BEM_Danh_sach_bien_nhan_coc": "",
        "BEM_DANH_SACH_BIEN_NHAN_COC_CHO": "",
        "BEM_Danh_sach_chi_tiet_thuc_don": "",
        "BEM_Danh_sach_dinh_kem": "",
        "BEM_DANH_SACH_PHIEU_BEO": "",
        "BEM_Danh_sach_phieu_tinh_tien_tiec": "",
        "BEM_Dap_ung": "",
        "BEM_Dat_coc": "Deposit",
        "BEM_Den": "まで",
        "BEM_Den_gio": "",
        "BEM_Den_ngay": "Date to",
        "BEM_Dia_chi": "住所",
        "BEM_Dia_diem_to_chuc_vi_tri": "",
        "BEM_Dich_vu": "",
        "BEM_Dich_vu_dat_them": "",
        "BEM_Dich_vu_khuyen_mai": "",
        "BEM_Dien_giai": "説明",
        "BEM_Dien_giai_HD": "",
        "BEM_Dien_giai_hop_dong": "",
        "BEM_Dien_giai_phieu": "",
        "BEM_Dieu_chinh_hop_dong": "",
        "BEM_Dieu_chinh_su_kien": "",
        "BEM_Dieu_khoan_TM": "",
        "BEM_Dieu_khoang_thuong_mai": "",
        "BEM_Dinh_dang_file_khong_hop_le": "",
        "BEM_Dinh_kem": "Attachments",
        "BEM_Doi_tuong_ap_dung": "",
        "BEM_Doi_tuong_GTGT": "",
        "BEM_Don_gia": "価格",
        "BEM_Don_vi": "単位",
        "BEM_Don_vi_tinh": "",
        "BEM_Dong1": "",
        "BEM_DongC": "",
        "BEM_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Data not saved. Do you want to save?",
        "BEM_Du_lieu_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc_khong?": "",
        "BEM_Dung_luong_File_khong_duoc_lon_hon": "",
        "BEM_Duyet": "承認",
        "BEM_Duyet_phieu_BEO": "",
        "BEM_DVT": "単位",
        "BEM_File_da_duoc_dinh_kem": "",
        "BEM_File_khong_dung_dinh_dang": "",
        "BEM_File_vuot_qua_dung_luong_cho_phep": "",
        "BEM_File_vuot_qua_so_luong_cho_phep": "",
        "BEM_Ghi_chu": " 言い草",
        "BEM_Gia_ban": "販売価格",
        "BEM_Gia_ban/ban": "",
        "BEM_Gia_menu": "",
        "BEM_Gia_tri_bat_buoc_nhap": "",
        "BEM_Gia_tri_hoa_don": "",
        "BEM_Gia_tri_hop_dong": "Contract amount",
        "BEM_Giai_doan": "",
        "BEM_Giam_gia": "",
        "BEM_Giam_gia_thuc_don": "",
        "BEM_Giay": "",
        "BEM_Gio": "Hour",
        "BEM_Gio_su_kien": "",
        "BEM_Gio_tiec": "",
        "BEM_Gio_tu": "",
        "BEM_Gio_tu_phai_nho_hon_Gio_den": "",
        "BEM_Giu_cho": "",
        "BEM_Gui": "Send",
        "BEM_Hai_long_can_cai_tien_them": "",
        "BEM_Hanh_dong": "",
        "BEM_He_thong_dang_trong_qua_trinh_nang_cap": "",
        "BEM_Hien_thi_phieu_da_huy": "",
        "BEM_Hieu_luc": "有効",
        "BEM_Hinh_thuc_tiec": "",
        "BEM_Hoan_toan_hai_long": "",
        "BEM_Hom_nay": "今日",
        "BEM_Hom_qua": "",
        "BEM_Hop_dong_dich_vu": "",
        "BEM_Huy": "Cancel",
        "BEM_Huy_BEO": "",
        "BEM_Huy_phieu": "",
        "BEM_Huy_phieu1": "",
        "BEM_Huy_thanh_cong": "",
        "BEM_In": "Print",
        "BEM_In1": "Print",
        "BEM_Khac": "その他",
        "BEM_Khach_hang": "顧客",
        "BEM_Khao_sat_y_kien_khach_hang": "",
        "BEM_Khoi_la_bat_buoc_nhap": "",
        "BEM_Khong": "いいえ",
        "BEM_khong_co_du_lieu": "",
        "BEM_Khong_co_ket_noi": "",
        "BEM_Khong_co_thong_bao": "",
        "BEM_Khong_du_quyen": "",
        "BEM_Khong_tim_thay_EOFFICE_URL": "",
        "BEM_Khong_tim_thay_trang": "",
        "BEM_Khong_xet1": "",
        "BEM_Khuyen_mai": "",
        "BEM_Lap_hop_dong_dich_vu": "",
        "BEM_Lap_phieu_BEO": "",
        "BEM_Lap_phieu_bien_nhan_coc_cho": "",
        "BEM_Lap_phieu_bien_nhan_coc_su_kien": "",
        "BEM_Lap_phieu_tinh_tien_tiec": "",
        "BEM_Lich": "",
        "BEM_Lich_chi_tiet": "",
        "BEM_Lich_su_tac_dong": "",
        "BEM_Loai_ban_tiec": "",
        "BEM_Loai_dieu_chinhU": "",
        "BEM_Loai_doi_tuong_ap_dung": "",
        "BEM_Loai_doi_tuong_GTGT": "",
        "BEM_Loai_hinh_su_kien": "",
        "BEM_Loai_hinh_su_kien_": "",
        "BEM_Loai_hinh_tiec": "",
        "BEM_Loai_hop_dong": "契約形式",
        "BEM_Loai_phieu": "ジャーナルタイプ",
        "BEM_Loai_quy_trinh": "",
        "BEM_Loai_thuc_don": "",
        "BEM_Loai_tien": "通貨",
        "BEM_Loc": "フィルター",
        "BEM_Loc_chon_hanh_dong": "",
        "BEM_Loi_chua_xac_dinh": "",
        "BEM_Loi_khong_lay_duoc_token_CDN": "",
        "BEM_Luoi_%p_chua_nhap_lieu_ban_co_muon_luu_khong?": "",
        "BEM_Luoi_thong_tin_thanh_toan_chua_nhap_lieu,_ban_khong_duoc_luu": "",
        "BEM_Luoi_thong_tin_thanh_toan_chua_nhap_lieu.Ban_khong_duoc_luu": "",
        "BEM_Luu": "保存",
        "BEM_Luu_lich_su_khong_thanh_cong": "",
        "BEM_Luu_thanh_cong": "",
        "BEM_Luu_thong_tin_chung_tu_khong_thanh_cong": "",
        "BEM_Luu_va_nhap_tiep": "",
        "BEM_Ly_do": "理由",
        "BEM_Ma": "Code",
        "BEM_Ma_bo_phan": "",
        "BEM_Ma_booking": "",
        "BEM_Ma_booking_nay_da_duoc_lap_trong_ma_su_kien_%p_ban_khong_the_luu": "",
        "BEM_Ma_co_cau": "",
        "BEM_Ma_co_ky_tu_khong_hop_le": "",
        "BEM_Ma_CTKM": "",
        "BEM_Ma_dich_vu": "",
        "BEM_Ma_doi_tuong": "オブジェクトコード",
        "BEM_Ma_doi_tuong_GTGT": "",
        "BEM_Ma_hang": "アイテムコード",
        "BEM_Ma_KH": "",
        "BEM_Ma_khach_hang": "",
        "BEM_Ma_khach_hangU": "",
        "BEM_Ma_menu": "",
        "BEM_Ma_mon_an": "",
        "BEM_Ma_nguoi_tiep_nhan": "",
        "BEM_Ma_NVKD": "",
        "BEM_Ma_phieu_BEO": "",
        "BEM_Ma_sanh_tiec": "",
        "BEM_Ma_so_thue": "",
        "BEM_Ma_su_kien": "",
        "BEM_Mat_khau": "パスワード",
        "BEM_Mau_bao_cao": "レポートコード",
        "BEM_Mau_giao_dien": "",
        "BEM_Menu_nay_da_duoc_chon": "",
        "BEM_Menu_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc?": "",
        "BEM_Mo_ta": "",
        "BEM_Mon_an": "",
        "BEM_Muc_do_dong_y": "",
        "BEM_Nam_nay": "",
        "BEM_Nam_truoc": "",
        "BEM_Nam1": "",
        "BEM_Ngan_hang": "",
        "BEM_Ngay": "年月日 \t",
        "BEM_Ngay_am_lich": "",
        "BEM_Ngay_danh_gia_bat_buoc_nhap": "",
        "BEM_Ngay_duong_lich": "",
        "BEM_Ngay_HD": "",
        "BEM_Ngay_hop_dong": "",
        "BEM_Ngay_lap": "Created Date",
        "BEM_Ngay_phieu": "バウチャー日",
        "BEM_Ngay_su_kien": "",
        "BEM_Ngay_su_kien_phai_lon_hon_hoac_bang_ngay_lap": "",
        "BEM_Ngay_sua_cuoi": "",
        "BEM_Ngay_tac_dong": "",
        "BEM_Ngay_tiec": "",
        "BEM_Ngay_tiec_AL": "",
        "BEM_Ngay_tiec_DL": "",
        "BEM_Ngay_to_chuc": "",
        "BEM_Nghiep_vu": "",
        "BEM_Ngoai_te": "",
        "BEM_Nguoi_dai_dien": "Agency",
        "BEM_Nguoi_danh_gia": "",
        "BEM_Nguoi_danh_gia_bat_buoc_nhap": "",
        "BEM_Nguoi_dung_khong_su_dung": "",
        "BEM_Nguoi_lap": "によって作成された",
        "BEM_Nguoi_lapU": "",
        "BEM_Nguoi_lien_he": "",
        "BEM_Nguoi_sua_cuoi": "",
        "BEM_Nguoi_tiep_nhan": "",
        "BEM_Nguoi_uy_quyen": "",
        "BEM_Nhan_vien": "",
        "BEM_Nhap": "",
        "BEM_Nhap_tiep": "",
        "BEM_Nho_mat_khau1": "",
        "BEM_Nhom_mon_an": "",
        "BEM_Nhom_thue": "",
        "BEM_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "",
        "BEM_No_data": "",
        "BEM_Noi_dung": "内容",
        "BEM_Noi_dung_can_tim": "",
        "BEM_Noi_dung_trang_tri": "",
        "BEM_Phat_hanh_BEO": "",
        "BEM_Phat_sinh": "",
        "BEM_Phien_ban": "",
        "BEM_Phu_luc_hop_dong": "",
        "BEM_Phuong_thuc_thanh_toan": "支払方法",
        "BEM_Phuong_thuc_TT": "",
        "BEM_phut": "",
        "BEM_Quy_uoc_nhu_sau": "",
        "BEM_Sanh": "",
        "BEM_Sanh_tiec": "",
        "BEM_Sao_chep": "",
        "BEM_SDT": "",
        "BEM_Sl_khach_ban": "",
        "BEM_SL_tuy_chon": "",
        "BEM_So_ban_chay": "",
        "BEM_So_ban_chay_hoac_so_ban_chinh_phai_lon_hon": "",
        "BEM_So_ban_chinh": "",
        "BEM_So_ban_chinh_thuc": "",
        "BEM_So_ban_du_bi": "",
        "BEM_So_ban_tang": "",
        "BEM_So_dien_thoai": "Tel no.",
        "BEM_So_dong": "",
        "BEM_So_DT": "",
        "BEM_So_Fax": "",
        "BEM_So_HD_truoc": "",
        "BEM_So_hop_dong": "Contract No",
        "BEM_So_hop_dong_truoc": "",
        "BEM_So_luong": "数量",
        "BEM_So_luong_ban_B": "",
        "BEM_So_luong_khach": "",
        "BEM_So_phieu": "バウチャー番号",
        "BEM_So_thuc_don": "",
        "BEM_So_tien_bang_chu": "",
        "BEM_So_tien_coc": "",
        "BEM_So_tien_thanh_toan_khong_bang_tong_so_tien_can_thanh_toan": "",
        "BEM_STT": "次",
        "BEM_STT_len_mon": "",
        "BEM_Su_kien": "",
        "BEM_Su_kien_nay_da_lap_phieu_BEO_vui_long_kiem_tra_lai": "",
        "BEM_Sua": "Edit",
        "BEM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "",
        "BEM_Tai_ky_hop_dong": "",
        "BEM_Tam_dap_ung_Can_cai_tien_them": "",
        "BEM_Tang_luong_truoc_thoi_han": "",
        "BEM_Tap_tin_dinh_kem_dang_duoc_xu_ly": "",
        "BEM_Tat_ca": "",
        "BEM_Ten": "名前",
        "BEM_Ten_bao_cao": "Report Name",
        "BEM_Ten_bo_phan": "",
        "BEM_Ten_CTKM": "",
        "BEM_Ten_Dang_Nhap": "TÊN ĐĂNG NHẬP",
        "BEM_Ten_dich_vu": "",
        "BEM_Ten_dinh_kem": "",
        "BEM_Ten_doi_tuong": "オブジェクト名",
        "BEM_Ten_hang": "アイテム名",
        "BEM_Ten_KH": "",
        "BEM_Ten_khach_hang": "",
        "BEM_Ten_menu": "",
        "BEM_Ten_mon": "",
        "BEM_Ten_mon_an": "",
        "BEM_Ten_nguoi_tiep_nhan": "",
        "BEM_Ten_NVKD": "",
        "BEM_Ten_san_pham_dich_vu": "",
        "BEM_Ten_sanh": "",
        "BEM_Ten_sanh_tiec": "",
        "BEM_Ten_su_kien": "",
        "BEM_Tham_so_truyen_vao_khong_hop_le": "",
        "BEM_Thang_nay": "今月",
        "BEM_Thang_truoc": "",
        "BEM_Thanh_tien": "Amount",
        "BEM_Thanh_tien_dang_nho_hon_%p_ban_vui_long_kiem_tra_lai!": "",
        "BEM_Thanh_tien_khuyen_mai": "",
        "BEM_Thanh_tien_quy_doi": "",
        "BEM_Thanh_tien_sau_GG_KM": "",
        "BEM_Thanh_tien_truoc_GG_KM": "",
        "BEM_Thay_doi_don_vi_se_quay_lai_trang_chu": "",
        "BEM_Thay_doi_thong_tin_Booking": "",
        "BEM_The_thanh_vien": "",
        "BEM_Them": "Add",
        "BEM_Them_moi1": "新しく作る",
        "BEM_Them_su_kien": "",
        "BEM_Thoi_gian": "時間",
        "BEM_Thong_bao": "Announcement",
        "BEM_Thong_bao1": "",
        "BEM_Thong_baoU": "",
        "BEM_Thong_tin_bien_nhan": "",
        "BEM_Thong_tin_booking": "",
        "BEM_Thong_tin_chi_tiet": "",
        "BEM_Thong_tin_chinh": "",
        "BEM_Thong_tin_chung": "一般的な情報",
        "BEM_Thong_tin_cung_cap_khong_hop_le": "",
        "BEM_Thong_tin_khach_hang": "",
        "BEM_Thong_tin_luoi_chi_tiet_se_bi_xoa_ban_co_muon_tiep_tuc": "",
        "BEM_Thong_tin_phieu": "",
        "BEM_Thong_tin_sanh": "",
        "BEM_Thong_tin_su_kien": "",
        "BEM_Thong_tin_thanh_toan": "",
        "BEM_Thong_tin_thuong_mai": "",
        "BEM_Thong_tin_to_chuc_su_kien": "",
        "BEM_Thong_tin_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc?": "",
        "BEM_Thu_tien_coc": "",
        "BEM_Thu_tien_tiec_su_kien": "",
        "BEM_Thuc_don_chay": "",
        "BEM_Thuc_don_chinh": "",
        "BEM_Thuc_don_phu": "",
        "BEM_Thuc_thu": "",
        "BEM_Thue_suat": "",
        "BEM_Tiec": "",
        "BEM_Tien_coc": "",
        "BEM_Tien_coc_cho": "",
        "BEM_Tien_coc_quy_doi": "",
        "BEM_Tien_coc_su_kien": "",
        "BEM_Tien_dat_coc": "",
        "BEM_Tien_dat_coc_quy_doi": "",
        "BEM_Tien_thue": "課税額",
        "BEM_Tien_thue_quy_doi": "",
        "BEM_Tiep_tuc": "",
        "BEM_Tim_kiem": "",
        "BEM_Tinh_trang": "",
        "BEM_Tong": "",
        "BEM_Tong_cong": "合計",
        "BEM_Tong_diem": "",
        "BEM_Tong_phat_sinh_sau_tiec": "",
        "BEM_Tong_sl_khach": "",
        "BEM_Tong_tien": "Total",
        "BEM_Tong_tien_chua_bao_gom_VAT": "",
        "BEM_Tong_tien_coc": "",
        "BEM_Tong_tien_con_lai": "",
        "BEM_Tong_tien_con_lai_quy_doi": "",
        "BEM_Tong_tien_da_bao_gom_VAT": "",
        "BEM_Tong_tien_dich_vu_dat_them": "",
        "BEM_Tong_tien_dich_vu_khuyen_mai": "",
        "BEM_Tong_tien_giam_gia": "",
        "BEM_Tong_tien_giam_gia_quy_doi": "",
        "BEM_Tong_tien_giam_gia_thuc_don": "",
        "BEM_Tong_tien_khuyen_mai": "",
        "BEM_Tong_tien_khuyen_mai_quy_doi": "",
        "BEM_Tong_tien_quy_doi_chua_bao_gom_VAT": "",
        "BEM_Tong_tien_quy_doi_da_bao_gom_VAT": "",
        "BEM_Tong_tien_sau_GG_KM": "",
        "BEM_Tong_tien_sau_GG_KM_quy_doi": "",
        "BEM_Tong_tien_thuc_don_chay": "",
        "BEM_Tong_tien_thuc_don_chinh": "",
        "BEM_Tong_tien_thuc_don_phu": "",
        "BEM_Tong_tien_thuc_thu": "",
        "BEM_Tong_tien_trang_tri": "",
        "BEM_Tong_tien_truoc_GG_KM": "",
        "BEM_Tong_tien_truoc_GG_KM_quy_doi": "",
        "BEM_Trang_Chu": "ホームページ",
        "BEM_Trang_thai": "ステータス",
        "BEM_Trang_thai_BEO": "",
        "BEM_Trang_thai_HD": "",
        "BEM_Trang_thai_hop_dong": "",
        "BEM_Trang_thai_hop_dongU": "",
        "BEM_Trang_thai_su_kien": "",
        "BEM_Trang_tri": "",
        "BEM_Tri_gia": "",
        "BEM_Trinh_duyet_khong_co_quyen_mo_popup": "",
        "BEM_trong": "",
        "BEM_Truong_nay_bat_buoc_nhap": "",
        "BEM_Truy_cap_khong_hop_le": "",
        "BEM_Tu": "から",
        "BEM_Tu_choi": "拒絶",
        "BEM_Tu_gio": "",
        "BEM_Tu_ngay": "Date from",
        "BEM_Tuan_nay": "Konshū",
        "BEM_Tuan_truoc": "",
        "BEM_Ty_gia": "為替レート",
        "BEM_Version_su_kien": "",
        "BEM_Vi_tri": "Position",
        "BEM_VoucherID_khong_duoc_de_trong": "",
        "BEM_Vui_long_chon_day_du_mon_an_va_dich_vu": "",
        "BEM_Vui_long_nhap_day_du_thong_tin_tren_luoi": "",
        "BEM_Vui_long_truy_cap_sau": "",
        "BEM_Xac_nhan_BEO_va_YC_vat_tu": "",
        "BEM_Xac_nhan_phieu_BEO_va_tinh_yeu_cau_vat_tu_cho_tiec_ban_co_muon_tiep_tuc_khong?": "",
        "BEM_Xac_nhan_va_phat_hanh_BEO": "",
        "BEM_Xem_chi_tiet": "View Detail",
        "BEM_Xem_them": "",
        "BEM_Xem_truoc": "",
        "BEM_Xin_cam_on": "",
        "BEM_Xoa": "Delete",
        "BEM_Xoa_thanh_cong": "",
        "BEM_Xuat_du_lieu": "",
        "BEM_Xuat_du_lieu_bao_cao": "",
        "BEM_Y_kien_dong_gop": ""
    },
    "zh": {
        "BEM_%p_dang_nho_hon_%p_,ban_vui_long_kiem_tra_lai!": "",
        "BEM_403_loi": "",
        "BEM_404_loi": "",
        "BEM_AC_vui_long_chon_y_kien_cua_minh": "",
        "BEM_Ban_can_nhap_chi_tiet_booking": "",
        "BEM_Ban_chua_chon_%p_,ban_co_muon_tiep_tuc_khong?": "",
        "BEM_Ban_chua_nhap_gia_tri": "",
        "BEM_Ban_chua_nhap_thong_tin_dich_vu_dat_them_trang_tri_ban_co_muon_tiep_tuc_khong?": "",
        "BEM_Ban_chua_nhap_thong_tin_tren_luoi": "",
        "BEM_Ban_co_chac_muon_huy?": "",
        "BEM_Ban_co_chac_muon_xoa?": "",
        "BEM_Ban_co_muon_huy_phieu_nay_hay_khong": "",
        "BEM_Ban_co_muon_huy_phieu_nay_hay_khong?": "",
        "BEM_Ban_co_muon_huy_phieu_nay_khong": "",
        "BEM_Ban_co_muon_roi_khoi_trang_nay_khong?": "",
        "BEM_Ban_co_muon_tiep_tuc_nhap_thong_tin_tren_luoi_khong?": "",
        "BEM_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "",
        "BEM_Ban_phai_chon_doanh_nghiep": "",
        "BEM_Ban_phai_chon_gio_tu_truoc": "",
        "BEM_Ban_phai_nhap_don_gia_truoc_khi_bo_chon_mon": "",
        "BEM_Ban_phai_nhap_du_lieu_luoi": "",
        "BEM_Ban_phai_nhap_thong_tin_chi_tiet": "",
        "BEM_Ban_phai_thiet_lap_mau_bao_cao_truoc_khi_xuat_du_lieu": "",
        "BEM_BEO": "",
        "BEM_Bo_phan_tiep_nhan": "",
        "BEM_Boi": "",
        "BEM_Ca_nhan": "Personal",
        "BEM_Cac_gop_y_khac": "",
        "BEM_Cac_tham_so_la_bat_buoc": "",
        "BEM_Cac_thong_so_luong": "",
        "BEM_Cac_tieu_chi_la_bat_buoc_nhap": "",
        "BEM_Cai_dat": "",
        "BEM_Cap_co_cau": "",
        "BEM_Cap_nhat_Booking": "",
        "BEM_Cap_nhat_su_kien": "",
        "BEM_Cap_nhat_thanh_cong": "",
        "BEM_Cap_nhat_thong_tin_Booking": "",
        "BEM_Cap_nhat_trang_thai": "",
        "BEM_Chi_ho_tro_dinh_kem_cac_file_co_dinh_dang": "",
        "BEM_Chi_tiet": "细节",
        "BEM_Chinh_sua_thanh_cong": "",
        "BEM_Cho_duyet": "待批准",
        "BEM_Chon": "Select",
        "BEM_Chon_doanh_nghiep": "",
        "BEM_Chon_goi_menu": "",
        "BEM_Chon_ma_booking": "",
        "BEM_Chon_ma_khach_hang": "",
        "BEM_Chon_ma_su_kien": "",
        "BEM_Chon_nghiep_vu": "",
        "BEM_Chon_thoi_gian": "",
        "BEM_Chot_mon": "",
        "BEM_Chu_the_thanh_vien": "",
        "BEM_Chua_co_lien_ket_nao": "",
        "BEM_Chua_co_mau_thong_tin_tiec_dinh_kem_ban_co_muon_luu_khong?": "",
        "BEM_Chua_dap_ung": "",
        "BEM_Chua_doc": "",
        "BEM_Chuc_nang_chua_phat_trien": "",
        "BEM_Chuc_vu": "位置",
        "BEM_Chuc_vu_bat_buoc_nhap": "",
        "BEM_Chuong_trinh_khuyen_mai_dich_vu": "",
        "BEM_Chuyen_YCTC": "",
        "BEM_Co": "贷方",
        "BEM_Co_cau_cha": "",
        "BEM_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "",
        "BEM_Co1": "",
        "BEM_Con_lai": "剩余",
        "BEM_Cong_suat_toi_da": "",
        "BEM_Cong_trinh/Cong_ty": "",
        "BEM_Cot": "",
        "BEM_Cung_ngay_su_kien_gio_tu_den_phai_khac_nhau": "",
        "BEM_Da_dat_coc": "",
        "BEM_Da_duoc_book_vui_long_kiem_tra_lai": "",
        "BEM_Da_duyet": "已批准",
        "BEM_Da_ket_noi": "",
        "BEM_Da_ky_Ngay": "",
        "BEM_Da_phat_hanh_BEO": "",
        "BEM_Da_thu": "",
        "BEM_Dang_duyet": "待批准",
        "BEM_Dang_nhap": "登录",
        "BEM_dang_nhap_lai": "以再次登陆",
        "BEM_Dang_nhap_lai2": "",
        "BEM_Dang_xuat1": "",
        "BEM_Danh_dau_tat_ca_la_da_doc": "",
        "BEM_Danh_sach_bien_nhan_coc": "",
        "BEM_DANH_SACH_BIEN_NHAN_COC_CHO": "",
        "BEM_Danh_sach_chi_tiet_thuc_don": "",
        "BEM_Danh_sach_dinh_kem": "",
        "BEM_DANH_SACH_PHIEU_BEO": "",
        "BEM_Danh_sach_phieu_tinh_tien_tiec": "",
        "BEM_Dap_ung": "",
        "BEM_Dat_coc": "Deposit",
        "BEM_Den": "至",
        "BEM_Den_gio": "",
        "BEM_Den_ngay": "Date to",
        "BEM_Dia_chi": "地址",
        "BEM_Dia_diem_to_chuc_vi_tri": "",
        "BEM_Dich_vu": "",
        "BEM_Dich_vu_dat_them": "",
        "BEM_Dich_vu_khuyen_mai": "",
        "BEM_Dien_giai": "描述",
        "BEM_Dien_giai_HD": "",
        "BEM_Dien_giai_hop_dong": "",
        "BEM_Dien_giai_phieu": "",
        "BEM_Dieu_chinh_hop_dong": "",
        "BEM_Dieu_chinh_su_kien": "",
        "BEM_Dieu_khoan_TM": "",
        "BEM_Dieu_khoang_thuong_mai": "",
        "BEM_Dinh_dang_file_khong_hop_le": "",
        "BEM_Dinh_kem": "Attachments",
        "BEM_Doi_tuong_ap_dung": "",
        "BEM_Doi_tuong_GTGT": "",
        "BEM_Don_gia": "价格",
        "BEM_Don_vi": "单位",
        "BEM_Don_vi_tinh": "",
        "BEM_Dong1": "",
        "BEM_DongC": "",
        "BEM_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Data not saved. Do you want to save?",
        "BEM_Du_lieu_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc_khong?": "",
        "BEM_Dung_luong_File_khong_duoc_lon_hon": "",
        "BEM_Duyet": "批准",
        "BEM_Duyet_phieu_BEO": "",
        "BEM_DVT": "单位",
        "BEM_File_da_duoc_dinh_kem": "",
        "BEM_File_khong_dung_dinh_dang": "",
        "BEM_File_vuot_qua_dung_luong_cho_phep": "",
        "BEM_File_vuot_qua_so_luong_cho_phep": "",
        "BEM_Ghi_chu": "注解 ",
        "BEM_Gia_ban": "销售价格",
        "BEM_Gia_ban/ban": "",
        "BEM_Gia_menu": "",
        "BEM_Gia_tri_bat_buoc_nhap": "",
        "BEM_Gia_tri_hoa_don": "",
        "BEM_Gia_tri_hop_dong": "Contract amount",
        "BEM_Giai_doan": "",
        "BEM_Giam_gia": "",
        "BEM_Giam_gia_thuc_don": "",
        "BEM_Giay": "",
        "BEM_Gio": "Hour",
        "BEM_Gio_su_kien": "",
        "BEM_Gio_tiec": "",
        "BEM_Gio_tu": "",
        "BEM_Gio_tu_phai_nho_hon_Gio_den": "",
        "BEM_Giu_cho": "",
        "BEM_Gui": "Send",
        "BEM_Hai_long_can_cai_tien_them": "",
        "BEM_Hanh_dong": "",
        "BEM_He_thong_dang_trong_qua_trinh_nang_cap": "",
        "BEM_Hien_thi_phieu_da_huy": "",
        "BEM_Hieu_luc": "有效",
        "BEM_Hinh_thuc_tiec": "",
        "BEM_Hoan_toan_hai_long": "",
        "BEM_Hom_nay": "今天",
        "BEM_Hom_qua": "",
        "BEM_Hop_dong_dich_vu": "",
        "BEM_Huy": "Cancel",
        "BEM_Huy_BEO": "",
        "BEM_Huy_phieu": "",
        "BEM_Huy_phieu1": "",
        "BEM_Huy_thanh_cong": "",
        "BEM_In": "Print",
        "BEM_In1": "Print",
        "BEM_Khac": "其他",
        "BEM_Khach_hang": "顾客",
        "BEM_Khao_sat_y_kien_khach_hang": "",
        "BEM_Khoi_la_bat_buoc_nhap": "",
        "BEM_Khong": " 不是",
        "BEM_khong_co_du_lieu": "",
        "BEM_Khong_co_ket_noi": "",
        "BEM_Khong_co_thong_bao": "",
        "BEM_Khong_du_quyen": "",
        "BEM_Khong_tim_thay_EOFFICE_URL": "",
        "BEM_Khong_tim_thay_trang": "",
        "BEM_Khong_xet1": "",
        "BEM_Khuyen_mai": "",
        "BEM_Lap_hop_dong_dich_vu": "",
        "BEM_Lap_phieu_BEO": "",
        "BEM_Lap_phieu_bien_nhan_coc_cho": "",
        "BEM_Lap_phieu_bien_nhan_coc_su_kien": "",
        "BEM_Lap_phieu_tinh_tien_tiec": "",
        "BEM_Lich": "",
        "BEM_Lich_chi_tiet": "",
        "BEM_Lich_su_tac_dong": "",
        "BEM_Loai_ban_tiec": "",
        "BEM_Loai_dieu_chinhU": "",
        "BEM_Loai_doi_tuong_ap_dung": "",
        "BEM_Loai_doi_tuong_GTGT": "",
        "BEM_Loai_hinh_su_kien": "",
        "BEM_Loai_hinh_su_kien_": "",
        "BEM_Loai_hinh_tiec": "",
        "BEM_Loai_hop_dong": "合同类型",
        "BEM_Loai_phieu": "日志类型",
        "BEM_Loai_quy_trinh": "",
        "BEM_Loai_thuc_don": "",
        "BEM_Loai_tien": "货币",
        "BEM_Loc": "过滤器",
        "BEM_Loc_chon_hanh_dong": "",
        "BEM_Loi_chua_xac_dinh": "",
        "BEM_Loi_khong_lay_duoc_token_CDN": "",
        "BEM_Luoi_%p_chua_nhap_lieu_ban_co_muon_luu_khong?": "",
        "BEM_Luoi_thong_tin_thanh_toan_chua_nhap_lieu,_ban_khong_duoc_luu": "",
        "BEM_Luoi_thong_tin_thanh_toan_chua_nhap_lieu.Ban_khong_duoc_luu": "",
        "BEM_Luu": "保存",
        "BEM_Luu_lich_su_khong_thanh_cong": "",
        "BEM_Luu_thanh_cong": "",
        "BEM_Luu_thong_tin_chung_tu_khong_thanh_cong": "",
        "BEM_Luu_va_nhap_tiep": "",
        "BEM_Ly_do": "原因",
        "BEM_Ma": "Code",
        "BEM_Ma_bo_phan": "",
        "BEM_Ma_booking": "",
        "BEM_Ma_booking_nay_da_duoc_lap_trong_ma_su_kien_%p_ban_khong_the_luu": "",
        "BEM_Ma_co_cau": "",
        "BEM_Ma_co_ky_tu_khong_hop_le": "",
        "BEM_Ma_CTKM": "",
        "BEM_Ma_dich_vu": "",
        "BEM_Ma_doi_tuong": "对象代码",
        "BEM_Ma_doi_tuong_GTGT": "",
        "BEM_Ma_hang": "产品编号",
        "BEM_Ma_KH": "",
        "BEM_Ma_khach_hang": "",
        "BEM_Ma_khach_hangU": "",
        "BEM_Ma_menu": "",
        "BEM_Ma_mon_an": "",
        "BEM_Ma_nguoi_tiep_nhan": "",
        "BEM_Ma_NVKD": "",
        "BEM_Ma_phieu_BEO": "",
        "BEM_Ma_sanh_tiec": "",
        "BEM_Ma_so_thue": "",
        "BEM_Ma_su_kien": "",
        "BEM_Mat_khau": "密码",
        "BEM_Mau_bao_cao": "報告碼",
        "BEM_Mau_giao_dien": "",
        "BEM_Menu_nay_da_duoc_chon": "",
        "BEM_Menu_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc?": "",
        "BEM_Mo_ta": "",
        "BEM_Mon_an": "",
        "BEM_Muc_do_dong_y": "",
        "BEM_Nam_nay": "",
        "BEM_Nam_truoc": "",
        "BEM_Nam1": "",
        "BEM_Ngan_hang": "",
        "BEM_Ngay": "日期",
        "BEM_Ngay_am_lich": "",
        "BEM_Ngay_danh_gia_bat_buoc_nhap": "",
        "BEM_Ngay_duong_lich": "",
        "BEM_Ngay_HD": "",
        "BEM_Ngay_hop_dong": "",
        "BEM_Ngay_lap": "Created Date",
        "BEM_Ngay_phieu": "傳票日期",
        "BEM_Ngay_su_kien": "",
        "BEM_Ngay_su_kien_phai_lon_hon_hoac_bang_ngay_lap": "",
        "BEM_Ngay_sua_cuoi": "",
        "BEM_Ngay_tac_dong": "",
        "BEM_Ngay_tiec": "",
        "BEM_Ngay_tiec_AL": "",
        "BEM_Ngay_tiec_DL": "",
        "BEM_Ngay_to_chuc": "",
        "BEM_Nghiep_vu": "",
        "BEM_Ngoai_te": "",
        "BEM_Nguoi_dai_dien": "Agency",
        "BEM_Nguoi_danh_gia": "",
        "BEM_Nguoi_danh_gia_bat_buoc_nhap": "",
        "BEM_Nguoi_dung_khong_su_dung": "",
        "BEM_Nguoi_lap": "创建者",
        "BEM_Nguoi_lapU": "",
        "BEM_Nguoi_lien_he": "",
        "BEM_Nguoi_sua_cuoi": "",
        "BEM_Nguoi_tiep_nhan": "",
        "BEM_Nguoi_uy_quyen": "",
        "BEM_Nhan_vien": "",
        "BEM_Nhap": "",
        "BEM_Nhap_tiep": "",
        "BEM_Nho_mat_khau1": "",
        "BEM_Nhom_mon_an": "",
        "BEM_Nhom_thue": "",
        "BEM_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "",
        "BEM_No_data": "",
        "BEM_Noi_dung": "内容",
        "BEM_Noi_dung_can_tim": "",
        "BEM_Noi_dung_trang_tri": "",
        "BEM_Phat_hanh_BEO": "",
        "BEM_Phat_sinh": "",
        "BEM_Phien_ban": "",
        "BEM_Phu_luc_hop_dong": "",
        "BEM_Phuong_thuc_thanh_toan": "付款方法",
        "BEM_Phuong_thuc_TT": "",
        "BEM_phut": "",
        "BEM_Quy_uoc_nhu_sau": "",
        "BEM_Sanh": "",
        "BEM_Sanh_tiec": "",
        "BEM_Sao_chep": "",
        "BEM_SDT": "",
        "BEM_Sl_khach_ban": "",
        "BEM_SL_tuy_chon": "",
        "BEM_So_ban_chay": "",
        "BEM_So_ban_chay_hoac_so_ban_chinh_phai_lon_hon": "",
        "BEM_So_ban_chinh": "",
        "BEM_So_ban_chinh_thuc": "",
        "BEM_So_ban_du_bi": "",
        "BEM_So_ban_tang": "",
        "BEM_So_dien_thoai": "Tel no.",
        "BEM_So_dong": "",
        "BEM_So_DT": "",
        "BEM_So_Fax": "",
        "BEM_So_HD_truoc": "",
        "BEM_So_hop_dong": "Contract No",
        "BEM_So_hop_dong_truoc": "",
        "BEM_So_luong": "数量",
        "BEM_So_luong_ban_B": "",
        "BEM_So_luong_khach": "",
        "BEM_So_phieu": "优惠券号码",
        "BEM_So_thuc_don": "",
        "BEM_So_tien_bang_chu": "",
        "BEM_So_tien_coc": "",
        "BEM_So_tien_thanh_toan_khong_bang_tong_so_tien_can_thanh_toan": "",
        "BEM_STT": " 序",
        "BEM_STT_len_mon": "",
        "BEM_Su_kien": "",
        "BEM_Su_kien_nay_da_lap_phieu_BEO_vui_long_kiem_tra_lai": "",
        "BEM_Sua": "Edit",
        "BEM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "",
        "BEM_Tai_ky_hop_dong": "",
        "BEM_Tam_dap_ung_Can_cai_tien_them": "",
        "BEM_Tang_luong_truoc_thoi_han": "",
        "BEM_Tap_tin_dinh_kem_dang_duoc_xu_ly": "",
        "BEM_Tat_ca": "",
        "BEM_Ten": "名称 \t",
        "BEM_Ten_bao_cao": "Report Name",
        "BEM_Ten_bo_phan": "",
        "BEM_Ten_CTKM": "",
        "BEM_Ten_Dang_Nhap": "TÊN ĐĂNG NHẬP",
        "BEM_Ten_dich_vu": "",
        "BEM_Ten_dinh_kem": "",
        "BEM_Ten_doi_tuong": "对象名称",
        "BEM_Ten_hang": "产品名称",
        "BEM_Ten_KH": "",
        "BEM_Ten_khach_hang": "",
        "BEM_Ten_menu": "",
        "BEM_Ten_mon": "",
        "BEM_Ten_mon_an": "",
        "BEM_Ten_nguoi_tiep_nhan": "",
        "BEM_Ten_NVKD": "",
        "BEM_Ten_san_pham_dich_vu": "",
        "BEM_Ten_sanh": "",
        "BEM_Ten_sanh_tiec": "",
        "BEM_Ten_su_kien": "",
        "BEM_Tham_so_truyen_vao_khong_hop_le": "",
        "BEM_Thang_nay": "本月",
        "BEM_Thang_truoc": "",
        "BEM_Thanh_tien": "Amount",
        "BEM_Thanh_tien_dang_nho_hon_%p_ban_vui_long_kiem_tra_lai!": "",
        "BEM_Thanh_tien_khuyen_mai": "",
        "BEM_Thanh_tien_quy_doi": "",
        "BEM_Thanh_tien_sau_GG_KM": "",
        "BEM_Thanh_tien_truoc_GG_KM": "",
        "BEM_Thay_doi_don_vi_se_quay_lai_trang_chu": "",
        "BEM_Thay_doi_thong_tin_Booking": "",
        "BEM_The_thanh_vien": "",
        "BEM_Them": "Add",
        "BEM_Them_moi1": "添新",
        "BEM_Them_su_kien": "",
        "BEM_Thoi_gian": "时间",
        "BEM_Thong_bao": "Announcement",
        "BEM_Thong_bao1": "",
        "BEM_Thong_baoU": "",
        "BEM_Thong_tin_bien_nhan": "",
        "BEM_Thong_tin_booking": "",
        "BEM_Thong_tin_chi_tiet": "",
        "BEM_Thong_tin_chinh": "",
        "BEM_Thong_tin_chung": "一般资料",
        "BEM_Thong_tin_cung_cap_khong_hop_le": "",
        "BEM_Thong_tin_khach_hang": "",
        "BEM_Thong_tin_luoi_chi_tiet_se_bi_xoa_ban_co_muon_tiep_tuc": "",
        "BEM_Thong_tin_phieu": "",
        "BEM_Thong_tin_sanh": "",
        "BEM_Thong_tin_su_kien": "",
        "BEM_Thong_tin_thanh_toan": "",
        "BEM_Thong_tin_thuong_mai": "",
        "BEM_Thong_tin_to_chuc_su_kien": "",
        "BEM_Thong_tin_tren_luoi_%p_se_bi_xoa,_ban_co_muon_tiep_tuc?": "",
        "BEM_Thu_tien_coc": "",
        "BEM_Thu_tien_tiec_su_kien": "",
        "BEM_Thuc_don_chay": "",
        "BEM_Thuc_don_chinh": "",
        "BEM_Thuc_don_phu": "",
        "BEM_Thuc_thu": "",
        "BEM_Thue_suat": "",
        "BEM_Tiec": "",
        "BEM_Tien_coc": "",
        "BEM_Tien_coc_cho": "",
        "BEM_Tien_coc_quy_doi": "",
        "BEM_Tien_coc_su_kien": "",
        "BEM_Tien_dat_coc": "",
        "BEM_Tien_dat_coc_quy_doi": "",
        "BEM_Tien_thue": "稅金額",
        "BEM_Tien_thue_quy_doi": "",
        "BEM_Tiep_tuc": "",
        "BEM_Tim_kiem": "",
        "BEM_Tinh_trang": "",
        "BEM_Tong": "",
        "BEM_Tong_cong": "总额",
        "BEM_Tong_diem": "",
        "BEM_Tong_phat_sinh_sau_tiec": "",
        "BEM_Tong_sl_khach": "",
        "BEM_Tong_tien": "Total",
        "BEM_Tong_tien_chua_bao_gom_VAT": "",
        "BEM_Tong_tien_coc": "",
        "BEM_Tong_tien_con_lai": "",
        "BEM_Tong_tien_con_lai_quy_doi": "",
        "BEM_Tong_tien_da_bao_gom_VAT": "",
        "BEM_Tong_tien_dich_vu_dat_them": "",
        "BEM_Tong_tien_dich_vu_khuyen_mai": "",
        "BEM_Tong_tien_giam_gia": "",
        "BEM_Tong_tien_giam_gia_quy_doi": "",
        "BEM_Tong_tien_giam_gia_thuc_don": "",
        "BEM_Tong_tien_khuyen_mai": "",
        "BEM_Tong_tien_khuyen_mai_quy_doi": "",
        "BEM_Tong_tien_quy_doi_chua_bao_gom_VAT": "",
        "BEM_Tong_tien_quy_doi_da_bao_gom_VAT": "",
        "BEM_Tong_tien_sau_GG_KM": "",
        "BEM_Tong_tien_sau_GG_KM_quy_doi": "",
        "BEM_Tong_tien_thuc_don_chay": "",
        "BEM_Tong_tien_thuc_don_chinh": "",
        "BEM_Tong_tien_thuc_don_phu": "",
        "BEM_Tong_tien_thuc_thu": "",
        "BEM_Tong_tien_trang_tri": "",
        "BEM_Tong_tien_truoc_GG_KM": "",
        "BEM_Tong_tien_truoc_GG_KM_quy_doi": "",
        "BEM_Trang_Chu": "首页",
        "BEM_Trang_thai": "状态",
        "BEM_Trang_thai_BEO": "",
        "BEM_Trang_thai_HD": "",
        "BEM_Trang_thai_hop_dong": "",
        "BEM_Trang_thai_hop_dongU": "",
        "BEM_Trang_thai_su_kien": "",
        "BEM_Trang_tri": "",
        "BEM_Tri_gia": "",
        "BEM_Trinh_duyet_khong_co_quyen_mo_popup": "",
        "BEM_trong": "",
        "BEM_Truong_nay_bat_buoc_nhap": "",
        "BEM_Truy_cap_khong_hop_le": "",
        "BEM_Tu": "从",
        "BEM_Tu_choi": "拒绝",
        "BEM_Tu_gio": "",
        "BEM_Tu_ngay": "Date from",
        "BEM_Tuan_nay": "本星期",
        "BEM_Tuan_truoc": "",
        "BEM_Ty_gia": "汇率",
        "BEM_Version_su_kien": "",
        "BEM_Vi_tri": "Position",
        "BEM_VoucherID_khong_duoc_de_trong": "",
        "BEM_Vui_long_chon_day_du_mon_an_va_dich_vu": "",
        "BEM_Vui_long_nhap_day_du_thong_tin_tren_luoi": "",
        "BEM_Vui_long_truy_cap_sau": "",
        "BEM_Xac_nhan_BEO_va_YC_vat_tu": "",
        "BEM_Xac_nhan_phieu_BEO_va_tinh_yeu_cau_vat_tu_cho_tiec_ban_co_muon_tiep_tuc_khong?": "",
        "BEM_Xac_nhan_va_phat_hanh_BEO": "",
        "BEM_Xem_chi_tiet": "View Detail",
        "BEM_Xem_them": "",
        "BEM_Xem_truoc": "",
        "BEM_Xin_cam_on": "",
        "BEM_Xoa": "Delete",
        "BEM_Xoa_thanh_cong": "",
        "BEM_Xuat_du_lieu": "",
        "BEM_Xuat_du_lieu_bao_cao": "",
        "BEM_Y_kien_dong_gop": ""
    },
    "timestamps": 1701762170219
};

/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 01/11/2021
 * @Example
 */

export const types = {
    WA3F2030_LOAD_GRID_TAB: "WA3F2030_LOAD_GRID_TAB",
    WA3F2030_LOAD_BEO_GRID: "WA3F2030_LOAD_BEO_GRID",
    WA3F2030_DELETE_BEO: "WA3F2030_DELETE_BEO",
    WA3F2030_CANCEL_BEO: "WA3F2030_CANCEL_BEO",
    WA3F2030_CREATE_BEO: "WA3F2030_CREATE_BEO",
};

export function loadGridTab(params, cb) {
    return {
        type: types.WA3F2030_LOAD_GRID_TAB,
        params,
        cb
    }
}

export function loadGrid(params, cb) {
    return {
        type: types.WA3F2030_LOAD_BEO_GRID,
        params,
        cb
    }
}

export function deleteBEO(params, cb) {
    return {
        type: types.WA3F2030_DELETE_BEO,
        params,
        cb
    }
}

export function cancelBEO(params, cb) {
    return {
        type: types.WA3F2030_CANCEL_BEO,
        params,
        cb
    }
}

export function createBEO(params, cb) {
    return {
        type: types.WA3F2030_CREATE_BEO,
        params,
        cb
    }
}

/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 04/11/2021
 * @Example
 */
import { delay } from 'redux-saga';
import { cancel, take, takeLatest } from 'redux-saga/effects';
import Api from '../../../../services/api';
import { types } from "./WA3F2031_actions";

export default function WA3F2100Sagas() {
    return [
        watchLoadForm(),
        watchGetBEOCode(),
        watchAddBEO(),
        watchConfirmBEO()
    ];
}

export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2031/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load form error');
    }
}
export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2031_LOAD_FORM, loadForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getBEOCode(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2031/get-beo-code', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get BEO code error');
    }
}
export function* watchGetBEOCode() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2031_GET_BEO_CODE, getBEOCode);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* addBEO(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2031/add', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('add BEO error');
    }
}
export function* watchAddBEO() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2031_ADD_BEO, addBEO);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* confirmBEO(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2031/confirm-add', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('add BEO error');
    }
}
export function* watchConfirmBEO() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2031_CONFIRM_BEO, confirmBEO);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
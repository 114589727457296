/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 17/06/2021
 * @Example
 */

export const types = {
    WA3F2001_LOAD_FORM: "WA3F2001_LOAD_FORM",
    WA3F2001_ON_SAVE: "WA3F2001_ON_SAVE",
    WA3F2001_ON_SAVE_EDIT: "WA3F2001_ON_SAVE_EDIT",
    WA3F2001_LOAD_CONTRACT_NO: "WA3F2001_LOAD_CONTRACT_NO"
};

export function loadForm(params, cb) {
    return {
        type: types.WA3F2001_LOAD_FORM,
        cb,
        params,
    };
}

export function onSave(params, cb) {
    return {
        type: types.WA3F2001_ON_SAVE,
        cb,
        params,
    };
}

export function onSaveEdit(params, cb) {
    return {
        type: types.WA3F2001_ON_SAVE_EDIT,
        cb,
        params,
    };
}

export function loadConTractNo(params, cb) {
    return {
        type: types.WA3F2001_LOAD_CONTRACT_NO,
        cb,
        params,
    };
}
/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/25/2020
 * @Example
 */

import Api                             from "../../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types}                         from "./WA3F2103_actions";
import {delay}                         from "redux-saga";

export default function WA3F2103Sagas () {
    return [
        watchGetCboBanquet(),
        watchGetForm(),
    ];
}

//get cbo banquet
export function* getCboBanquet (data) {
    try {
        yield delay(300);
        const response = yield Api.put("/wa3f2103/load-list-of-Banquet", data.params);
        if (response && response.data) {
            yield put({
                type: types.WA3F2103_LOAD_BANQUET_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("WA3F2103 load cbo banquet error");
    }
}

export function* watchGetCboBanquet () {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2103_LOAD_BANQUET, getCboBanquet);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get data grid
export function* getForm (data) {
    try {
        yield delay(300);
        const response = yield Api.put("/wa3f2103/load-form", data.params);
        if (response && response.data) {
            yield put({
                type: types.WA3F2103_LOAD_FORM_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("WA3F2103 get form error");
    }
}

export function* watchGetForm () {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2103_LOAD_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 24/11/2021
 * @Example
 */

export const types = {
    WA3F2021_LOAD_FORM: "WA3F2021_LOAD_FORM",
    WA3F2021_SAVE_ADD: "WA3F2021_SAVE_ADD",
    WA3F2021_LOAD_VOUCHER_NO: "WA3F2021_LOAD_VOUCHER_NO",
    WA3F2021_LOAD_BOOKING: "WA3F2021_LOAD_BOOKING",
    WA3F2021_LOAD_GRID_PAYMENT_METHOD: "WA3F2021_LOAD_GRID_PAYMENT_METHOD",
};

export function loadForm(params, cb) {
    return {
        type: types.WA3F2021_LOAD_FORM,
        params,
        cb
    }
};

export function saveAdd(params, cb) {
    return {
        type: types.WA3F2021_SAVE_ADD,
        params,
        cb
    }
};

export function loadVoucherNo(params, cb) {
    return {
        type: types.WA3F2021_LOAD_VOUCHER_NO,
        params,
        cb
    }
};

export function loadBooking(params, cb) {
    return {
        type: types.WA3F2021_LOAD_BOOKING,
        params,
        cb
    }
};

export function loadGridPaymentMethod(params, cb) {
    return {
        type: types.WA3F2021_LOAD_GRID_PAYMENT_METHOD,
        params,
        cb
    }
};




/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example 
 */

import { delay } from "redux-saga";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import Config from "../../config";
import { types } from "../../redux/general/general_actions";
import Api from '../../services/api';

export default function GeneralSagas() {
    return [
        watchGetOrgCharts(),
        watchGetCboProjects(),
        watchGetCboDuty(),
        watchGetCboEmployees(),
        watchGetAttachmentsByTransID(),
        watchExportExcelTemplate(),
        watchGetCboDepartments(),
        watchGetCboTeam(),
        watchGetCboWorks(),
        watchGetCboDepartments(),
        watchGetCboAppStatus(),
        watchgetGridGeneralInformation(),
        watchLoadHistory(),
        watchLoadCboReport(),
        watchExportReport(),
        watchGetInventoriesSelectModal(),
        watchGetCompaniesSelectModal(),
        watchOnSaveStateExportBtn(),
        watchPassParametersExport(),
        watchExportFlexReport(),
        watchGetObjectsSelectModal(),
        watchGetCboDivision(),
        watchGetCboContractType(),
        watchGetCboEStatus(),
        watchGetCboCard(),
        watchGetCboCardOwner(),
        watchGetCboVATObjectType(),
        watchGetCboVATObject(),
        watchGetCboPaymentTerm(),
        watchGetCboPaymentMethod(),
        watchGetCboSalesPerson(),
        watchGetCboCurrency(),
        watchGetCboVATGroup(),
        watchGetCboCreateUser(),
        watchGetCboCustomer(),
        watchGetCboMenuType(),
        watchGetCboMenuID(),
        watchGetCboInventoryMH(),
        watchGetCboInventoryDV(),
        watchGetCboUnit(),
        watchDetDataBookingNo(),
        watchGetCboStatusBEO(),
        watchGetCboDepartment(),
        watchGetCboReceptionist(),
        watchGetCboVoucherType(),
        watchGetReportList(),
        watchExportTemplate(),
        watchLoadVoucherNo(),
        watchLoadDataEvent(),
        watchLoadCboBanquetCode(),
        watchGetCboBank(),
        watchGetCboPaymentMethodForm()
    ];
}

//get organiaztional..
export function* getOrgCharts(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/get-structure', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_ORGCHART_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get organizational error');
    }
}

export function* watchGetOrgCharts() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_ORGCHART, getOrgCharts);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get duty..
export function* getCboDuty(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2161/load-drd-produty', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_DUTY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo duty error');
    }
}

export function* watchGetCboDuty() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_DUTY, getCboDuty);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo employee..
export function* getCboEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-employee', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_EMPLOYEE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo employees error');
    }
}

export function* watchGetCboEmployees() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_EMPLOYEE, getCboEmployees);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo project..
export function* getCboProjects(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2161/load-cbo-project', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_PROJECT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo projects error');
    }
}

export function* watchGetCboProjects() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_PROJECT, getCboProjects);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo departments..
export function* getCboDepartments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-department', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_CBO_DEPARTMENTS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo departments error');
    }
}

export function* watchGetCboDepartments() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_DEPARTMENTS, getCboDepartments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo team..
export function* getCboTeam(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-team', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_CBO_TEAM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo team error');
    }
}

export function* watchGetCboTeam() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_TEAM, getCboTeam);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo works..
export function* getCboWorks(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-combo-work', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_CBO_WORKS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo works error');
    }
}

export function* watchGetCboWorks() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_WORKS, getCboWorks);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboAppStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-app-status', data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_APP_STATUS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo appstatus error');
    }
}

export function* watchGetCboAppStatus() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_APP_STATUS, getCboAppStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get attachments by TransID..
export function* getAttachmentsByTransID(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/attachment/get-attachment-by-key', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get attachments by transID error');
    }
}

export function* watchGetAttachmentsByTransID() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_ATTACHMENTS_BY_TRANSID, getAttachmentsByTransID);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

/**
 * Export excel template...
 * @param data: {params, cb}
 * @param params: {
 *     token,
 *     ReportTypeID
 *     ReportID,
 *     TemplateURL,
 *     isPDF,
 *     Params,
 * }
 * @returns {IterableIterator<Promise<true>|*>}
 */

function checkUrlFileName(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return filename = decodeURIComponent(reFileName);
    }
    return filename; // else return original data 
}

export function* exportExcelTemplate(data) {
    const fileType = data.params?.FileExt;
    try {
        yield delay(300);
        let response = yield Api.putExport('/report/export-report', data.params, fileType);
        if (response && response.ok) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                let defaultFileType = ".xls";
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                if (fileType === "html" || false) defaultFileType = ".doc";
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-erp-" + datetime + defaultFileType;
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('export excel template error');
    }
}

export function* watchExportExcelTemplate() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_EXPORT_EXCEL_TEMPLATE, exportExcelTemplate);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getGridGeneralInformation(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w75f2000/load-general-information', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data[0])
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchgetGridGeneralInformation() {
    while (true) {
        const watcher = yield takeLatest(types.GET_GRID_GENERAL_INFORMATION, getGridGeneralInformation);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadHistory(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/history/search', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load history error');
    }

}

export function* watchLoadHistory() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_LOAD_HISTORY, loadHistory);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadCboReport(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/report/get-reports', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_LOAD_CBO_REPORT_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load combo report error');
    }

}

export function* watchLoadCboReport() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_LOAD_CBO_REPORT, loadCboReport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* exportReport(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/report/export-report', data.params);
        if (response && response.data) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-hr-" + datetime + '.xls';
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('export report error');
    }
}

export function* watchExportReport() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_EXPORT_REPORT, exportReport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get select inventories modal data
export function* getInventoriesSelectModal(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-inventories', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_INVENTORIES_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo inventories error');
    }
}

export function* watchGetInventoriesSelectModal() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_INVENTORIES, getInventoriesSelectModal);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get select company modal data
export function* getCompaniesSelectModal(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/get-combo-fumigation-company', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_COMPANIES_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data companies error');
    }
}

export function* watchGetCompaniesSelectModal() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_COMPANIES, getCompaniesSelectModal);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save combo UnitData local only
export function* onSaveStateExportBtn(data) {
    yield put({ type: types.GENERAL_SAVE_EXPORT_BTN_STATE_SUCCESS, btnExportState: data.btnExportState });
}

export function* watchOnSaveStateExportBtn() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_SAVE_EXPORT_BTN_STATE, onSaveStateExportBtn);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Check param export
export function* passParametersExport(data) {
    try {
        yield delay(300);
        let response = yield Api.put('/report/passing-parameter', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('pass params report error');
    }
}

export function* watchPassParametersExport() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_PASS_PARAM_EXPORT, passParametersExport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* exportFlexReport(data) {
    try {
        yield delay(300);
        const response = yield Api.putExport('/report/export-flex-report', data.params);
        if (response && response.ok) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-erp-" + datetime + '.xls';
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('export flex report error');
    }
}

export function* watchExportFlexReport() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_EXPORT_FLEX_REPORT, exportFlexReport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get select object modal data
export function* getObjectsSelectModal(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w91f0001/load-objects', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_OBJECT_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log("get data objects error");
    }
}

export function* watchGetObjectsSelectModal() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_OBJECT, getObjectsSelectModal);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboDivision(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/standard/load-cbo-division');
        if (response && response.data) {
            yield put({ type: types.GENERAL_GET_CBO_DIVISION_SUCCESS, payload: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchGetCboDivision() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_DIVISION, getCboDivision);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboContractType(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/standard/load-cbo-contract-type");
        if (res && res.data) {
            yield put({ type: types.GENERAL_GET_CBO_CONTRACT_TYPE_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo contract type error", e);
    }
}

export function* watchGetCboContractType() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_CONTRACT_TYPE, getCboContractType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboEStatus(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/standard/load-cbo-contract-status");
        if (res && res.data) {
            yield put({ type: types.GENERAL_GET_CBO_STATUS_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo status error", e);
    }
}

export function* watchGetCboEStatus() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_STATUS, getCboEStatus);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboCard(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-card", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo card error", e);
    }
}

export function* watchGetCboCard() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_CARD, getCboCard);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboCardOwner(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-card-owner", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo card owner error", e);
    }
}

export function* watchGetCboCardOwner() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_CARD_OWNER, getCboCardOwner);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getCboVATObjectType(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-value-added-type", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo vat object type error", e);
    }
}

export function* watchGetCboVATObjectType() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_VAT_OBJECT_TYPE, getCboVATObjectType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboVATObject(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-value-added-id", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo vat object error", e);
    }
}

export function* watchGetCboVATObject() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_VAT_OBJECTS, getCboVATObject);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboPaymentTerm(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/standard/load-cbo-payment-term");
        if (res && res.data) {
            yield put({ type: types.GENERAL_GET_CBO_PAYMENT_TERM_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo payment term error", e);
    }
}

export function* watchGetCboPaymentTerm() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_PAYMENT_TERM, getCboPaymentTerm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboPaymentMethod(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/standard/load-cbo-payment-method");
        if (res && res.data) {
            yield put({ type: types.GENERAL_GET_CBO_PAYMENT_METHOD_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo payment method error", e);
    }
}

export function* watchGetCboPaymentMethod() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_PAYMENT_METHOD, getCboPaymentMethod);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboSalesPerson(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-sales-person", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo sales person error", e);
    }
}

export function* watchGetCboSalesPerson() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_SALES_PERSON, getCboSalesPerson);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboCurrency(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/standard/load-cbo-currency");
        if (res && res.data) {
            yield put({ type: types.GENERAL_GET_CBO_CURRENCY_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo currency error", e);
    }
}

export function* watchGetCboCurrency() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_CURRENCY, getCboCurrency);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboVATGroup(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/standard/load-cbo-vat-group");
        if (res && res.data) {
            yield put({ type: types.GENERAL_GET_CBO_VAT_GROUP_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo vat group error", e);
    }
}

export function* watchGetCboVATGroup() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_VAT_GROUP, getCboVATGroup);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboCreateUser(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-create-user", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo create user error", e);
    }
}

export function* watchGetCboCreateUser() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_CBO_CREATE_USER, getCboCreateUser);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboCustomer(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-customer", data.params);
        if (res && res.data) {
            yield put({ type: types.GENERAL_GET_CBO_CUSTOMER_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo customer error", e);
    }
}

export function* watchGetCboCustomer() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_CUSTOMER, getCboCustomer);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboMenuType(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-menu-type", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo menu type error", e);
    }
}

export function* watchGetCboMenuType() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_MENU_TYPE, getCboMenuType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboMenuID(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-menu-id", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo menu id error", e);
    }
}

export function* watchGetCboMenuID() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_MENU_ID, getCboMenuID);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboInventoryMH(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-inventory-id-mh", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo intentory_mh error", e);
    }
}

export function* watchGetCboInventoryMH() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_INVENTORY_MH, getCboInventoryMH);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboInventoryDV(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-inventory-id-dv", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo intentory_dv error", e);
    }
}

export function* watchGetCboInventoryDV() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_INVENTORY_DV, getCboInventoryDV);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboUnit(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-unit-id", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo unit error", e);
    }
}

export function* watchGetCboUnit() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_UNIT, getCboUnit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getDataBookingNo(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-popup-booking-no", data.params);
        if (res && res.data) {
            yield put({ type: types.GENERAL_GET_DATA_BOOKING_NO_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get data booking no error", e);
    }
}

export function* watchDetDataBookingNo() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_DATA_BOOKING_NO, getDataBookingNo);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
 
export function* getCboStatusBEO(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-status-beo", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo status BEO error", e);
    }
}

export function* watchGetCboStatusBEO() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_STATUS_BEO, getCboStatusBEO);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboDepartment(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-dropdown-department", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo department error", e);
    }
}

export function* watchGetCboDepartment() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_DEPARTMENT, getCboDepartment);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboReceptionist(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-dropdown-receptionist", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo receptionist error", e);
    }
}

export function* watchGetCboReceptionist() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_RECEPTIONIST, getCboReceptionist);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboVoucherType(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/standard/load-cbo-voucher-type");
        if (res && res.data) {
            yield put({ type: types.GENERAL_GET_CBO_VOUCHER_TYPE_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("get cbo voucher type error", e);
    }
}

export function* watchGetCboVoucherType() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_GET_CBO_VOUCHER_TYPE, getCboVoucherType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getReportList(data) {
    try {
      yield delay(300);
      const res = yield Api.put("/report/load-report-id", data.params);
      if (res && res.data) {
        yield put({ type: types.GENERAL_LOAD_REPORT_LIST_SUCCESS, data: res.data });
        data.cb && data.cb(null, res.data);
        return;
      } else {
        data.cb && data.cb(res, null);
      }
    } catch (e) {
      console.log("load report list error", e);
    }
  }
  
  export function* watchGetReportList() {
    while (true) {
      const watcher = yield takeLatest(types.GENERAL_LOAD_REPORT_LIST, getReportList);
      yield take(["LOGOUT", "NETWORK"]);
      yield cancel(watcher);
    }
  }

  function checkUrlFileNamev2(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("filename");
    if (specialChar !== -1) {
        let reFileName = filename.slice(0, specialChar).trim();
        const semicolonIndex = filename.search(";");
        if (semicolonIndex + 1 === reFileName.length) reFileName = reFileName.slice(0, semicolonIndex);
        return filename = decodeURI(reFileName);
    }
    return filename; // else return original data 
}
  
  export function* exportTemplate(data) {
    const fileType = data.params?.FileExt;
    try {
      yield delay(300);
      let response = yield Api.putExport('/report/export', data.params, fileType);
      if (response && response.ok) {
        let _res = null;
        if (response.type === "json") {
          _res = yield response.data.json();
          if (_res && _res.data) {
            data.cb && data.cb(null, _res.data);
          } else {
            data.cb && data.cb(_res, null)
          }
        } else {
          let defaultFileType = ".xls";
          const content = yield response.data.blob();
          const datetime = new Date().getTime();
          if (fileType === "html" || false) defaultFileType = ".doc";
          const _fileName = response.filename ? checkUrlFileNamev2(response.filename) : "data-export-bem-" + datetime + defaultFileType;
          _res = {
            fileName: _fileName,
            fileExt: Config.helpers.getExtFile(_fileName),
            URL: URL.createObjectURL(content),
          };
          if (_res && _res.URL) {
            data.cb && data.cb(null, _res);
          } else {
            data.cb && data.cb(_res, null);
          }
        }
      }
      else {
        data.cb && data.cb(response, null)
      }
    }
    catch (e) {
      console.log('export template error');
    }
  }
  
  export function* watchExportTemplate() {
    while (true) {
      const watcher = yield takeLatest(types.GENERAL_EXPORT_TEMPLATE, exportTemplate);
      yield take(["LOGOUT", "NETWORK"]);
      yield cancel(watcher);
    }
  }
  
  export function* loadVoucherNo(data) {
      try {
          yield delay(300);
          const response = yield Api.put('/wa3f2001/load-contract-no', data.params);
          if (response && response.data) {
              data.cb && data.cb(null, response.data)
          }
          else {
              data.cb && data.cb(response, null)
          }
      }
      catch (e) {
          console.log('load voucher no error');
      }
  };
  
  export function* watchLoadVoucherNo() {
      while (true) {
          const watcher = yield takeLatest(types.GENERAL_LOAD_VOUCHER_NO, loadVoucherNo);
          yield take(['LOGOUT', 'NETWORK']);
          yield cancel(watcher);
      }
  };
  
  export function* loadDataEvent(data) {
      try {
          yield delay(300);
          const response = yield Api.put('/standard/load-data-event', data.params);
          if (response && response.data) {
              data.cb && data.cb(null, response.data);
          }
          else {
              data.cb && data.cb(response, null);
          }
      }
      catch (e) {
          console.log('load data event error');
      }
  };
  
  export function* watchLoadDataEvent() {
      while (true) {
          const watcher = yield takeLatest(types.GENERAL_LOAD_DATA_EVENT, loadDataEvent);
          yield take(['LOGOUT', 'NETWORK']);
          yield cancel(watcher);
      }
  };
  
  export function* loadCboBanquetCode(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/standard/get-combobox');
        if (response && response.data) {
            yield put({ type: types.GENERAL_LOAD_CBO_BANQUET_CODE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    }
    catch (e) {
        console.log('load data event error');
    }
};

export function* watchLoadCboBanquetCode() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_LOAD_CBO_BANQUET_CODE, loadCboBanquetCode);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};
  
export function* getCboBank(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-bank', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_LOAD_CBO_BANK_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    }
    catch (e) {
        console.log('load cbo bank error');
    }
};

export function* watchGetCboBank() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_LOAD_CBO_BANK, getCboBank);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};
  
export function* getCboPaymentMethodForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-payment-method-new', data.params);
        if (response && response.data) {
            yield put({ type: types.GENERAL_LOAD_CBO_PAYMENT_METHOD_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    }
    catch (e) {
        console.log('load cbo payment method form error');
    }
};

export function* watchGetCboPaymentMethodForm() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_LOAD_CBO_PAYMENT_METHOD_FORM, getCboPaymentMethodForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};
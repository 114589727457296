import Api from "../../services/api";

export const types = {
    GET_PERMISSION: 'GET_PERMISSION',
    GET_PERMISSION_SUCCESS: 'GET_PERMISSION_SUCCESS',
    GET_CBO_ORGCHART: 'GET_CBO_ORGCHART',
    GET_CBO_ORGCHART_SUCCESS: 'GET_CBO_ORGCHART_SUCCESS',
    //filter
    GET_CBO_DUTY: 'GET_CBO_DUTY',
    GET_CBO_DUTY_SUCCESS: 'GET_CBO_DUTY_SUCCESS',
    GET_CBO_EMPLOYEE: 'GET_CBO_EMPLOYEE',
    GET_CBO_EMPLOYEE_SUCCESS: 'GET_CBO_EMPLOYEE_SUCCESS',
    GET_CBO_PROJECT: 'GET_CBO_PROJECT',
    GET_CBO_PROJECT_SUCCESS: 'GET_CBO_PROJECT_SUCCESS',
    GET_GRID_GENERAL_INFORMATION: "GET_GRID_GENERAL_INFORMATION",
    GENERAL_GET_CBO_DEPARTMENTS: 'GENERAL_GET_CBO_DEPARTMENTS',
    GENERAL_GET_CBO_DEPARTMENTS_SUCCESS: 'GENERAL_GET_CBO_DEPARTMENTS_SUCCESS',
    GENERAL_GET_CBO_TEAM: 'GENERAL_GET_CBO_TEAM',
    GENERAL_GET_CBO_TEAM_SUCCESS: 'GENERAL_GET_CBO_TEAM_SUCCESS',
    GENERAL_GET_CBO_WORKS: 'GENERAL_GET_CBO_WORKS',
    GENERAL_GET_CBO_WORKS_SUCCESS: 'GENERAL_GET_CBO_WORKS_SUCCESS',
    GET_CBO_APP_STATUS: "GET_CBO_APP_STATUS",
    GET_CBO_APP_STATUS_SUCCESS: "GET_CBO_APP_STATUS_SUCCESS",
    GENERAL_GET_ATTACHMENTS_BY_TRANSID: 'GENERAL_GET_ATTACHMENTS_BY_TRANSID',
    GENERAL_EXPORT_EXCEL_TEMPLATE: 'GENERAL_EXPORT_EXCEL_TEMPLATE',
    GENERAL_EXPORT_EXCEL_TEMPLATE_SUCCESS: 'GENERAL_EXPORT_EXCEL_TEMPLATE_SUCCESS',
    GENERAL_LOAD_HISTORY: "GENERAL_LOAD_HISTORY",
    GENERAL_LOAD_HISTORY_SUCCESS: "GENERAL_LOAD_HISTORY_SUCCESS",
    GENERAL_LOAD_CBO_REPORT: "GENERAL_LOAD_CBO_REPORT",
    GENERAL_LOAD_CBO_REPORT_SUCCESS: "GENERAL_LOAD_CBO_REPORT_SUCCESS",
    GENERAL_EXPORT_REPORT: "GENERAL_EXPORT_REPORT",
    GENERAL_EXPORT_REPORT_SUCCESS: "GENERAL_EXPORT_REPORT_SUCCESS",
    GENERAL_GET_INVENTORIES: "GENERAL_GET_INVENTORIES",
    GENERAL_GET_INVENTORIES_SUCCESS: "GENERAL_GET_INVENTORIES_SUCCESS",
    GENERAL_GET_COMPANIES: "GENERAL_GET_COMPANIES",
    GENERAL_GET_COMPANIES_SUCCESS: "GENERAL_GET_COMPANIES_SUCCESS",
    GENERAL_SAVE_EXPORT_BTN_STATE: "GENERAL_SAVE_EXPORT_BTN_STATE",
    GENERAL_SAVE_EXPORT_BTN_STATE_SUCCESS: "GENERAL_SAVE_EXPORT_BTN_STATE_SUCCESS",
    GENERAL_PASS_PARAM_EXPORT: "GENERAL_PASS_PARAM_EXPORT",
    GENERAL_EXPORT_FLEX_REPORT: "GENERAL_EXPORT_FLEX_REPORT",
    GENERAL_GET_OBJECT: "GENERAL_GET_OBJECT",
    GENERAL_GET_OBJECT_SUCCESS: "GENERAL_GET_OBJECT_SUCCESS",
    GENERAL_GET_CBO_DIVISION: 'GENERAL_GET_CBO_DIVISION',
    GENERAL_GET_CBO_DIVISION_SUCCESS: 'GENERAL_GET_CBO_DIVISION_SUCCESS',
    GENERAL_GET_CBO_CUSTOMER: "GENERAL_GET_CBO_CUSTOMER",
    GENERAL_GET_CBO_CUSTOMER_SUCCESS: "GENERAL_GET_CBO_CUSTOMER_SUCCESS",
    GENERAL_GET_CBO_CONTRACT_TYPE: "GENERAL_GET_CBO_CONTRACT_TYPE",
    GENERAL_GET_CBO_CONTRACT_TYPE_SUCCESS: "GENERAL_GET_CBO_CONTRACT_TYPE_SUCCESS",
    GENERAL_GET_CBO_STATUS: "GENERAL_GET_CBO_STATUS",
    GENERAL_GET_CBO_STATUS_SUCCESS: "GENERAL_GET_CBO_STATUS_SUCCESS",
    GENERAL_GET_CBO_CARD: "GENERAL_GET_CBO_CARD",
    GENERAL_GET_CBO_CARD_OWNER: "GENERAL_GET_CBO_CARD_OWNER",
    GENERAL_GET_CBO_VAT_OBJECT_TYPE: "GENERAL_GET_CBO_VAT_OBJECT_TYPE",
    GENERAL_GET_CBO_VAT_OBJECTS: "GENERAL_GET_CBO_VAT_OBJECTS",
    GENERAL_GET_CBO_PAYMENT_TERM: "GENERAL_GET_CBO_PAYMENT_TERM",
    GENERAL_GET_CBO_PAYMENT_TERM_SUCCESS: "GENERAL_GET_CBO_PAYMENT_TERM_SUCCESS",
    GENERAL_GET_CBO_PAYMENT_METHOD: "GENERAL_GET_CBO_PAYMENT_METHOD",
    GENERAL_GET_CBO_PAYMENT_METHOD_SUCCESS: "GENERAL_GET_CBO_PAYMENT_METHOD_SUCCESS",
    GENERAL_GET_CBO_SALES_PERSON: "GENERAL_GET_CBO_SALES_PERSON",
    GENERAL_GET_CBO_CURRENCY: "GENERAL_GET_CBO_CURRENCY",
    GENERAL_GET_CBO_CURRENCY_SUCCESS: "GENERAL_GET_CBO_CURRENCY_SUCCESS",
    GENERAL_GET_CBO_VAT_GROUP: "GENERAL_GET_CBO_VAT_GROUP",
    GENERAL_GET_CBO_VAT_GROUP_SUCCESS: "GENERAL_GET_CBO_VAT_GROUP_SUCCESS",
    GENERAL_CBO_CREATE_USER: "GENERAL_CBO_CREATE_USER",
    GENERAL_GET_CBO_CONTRACT_NO: "GENERAL_GET_CBO_CONTRACT_NO",
    GENERAL_GET_CBO_CONTRACT_NO_SUCCESS: "GENERAL_GET_CBO_CONTRACT_NO_SUCCESS",
    GENERAL_GET_CBO_MENU_TYPE: "GENERAL_GET_CBO_MENU_TYPE",
    GENERAL_GET_CBO_MENU_ID: "GENERAL_GET_CBO_MENU_ID",
    GENERAL_GET_CBO_INVENTORY_MH: "GENERAL_GET_CBO_INVENTORY_MH",
    GENERAL_GET_CBO_INVENTORY_DV: "GENERAL_GET_CBO_INVENTORY_DV",
    GENERAL_GET_CBO_UNIT: "GENERAL_GET_CBO_UNIT",
    GENERAL_GET_DATA_BOOKING_NO: "GENERAL_GET_DATA_BOOKING_NO",
    GENERAL_GET_DATA_BOOKING_NO_SUCCESS: "GENERAL_GET_DATA_BOOKING_NO_SUCCESS",
    GENERAL_GET_CBO_STATUS_BEO: "GENERAL_GET_CBO_STATUS_BEO",
    GENERAL_GET_CBO_DEPARTMENT: "GENERAL_GET_CBO_DEPARTMENT",
    GENERAL_GET_CBO_RECEPTIONIST: "GENERAL_GET_CBO_RECEPTIONIST",
    GENERAL_GET_CBO_VOUCHER_TYPE: "GENERAL_GET_CBO_VOUCHER_TYPE",
    GENERAL_GET_CBO_VOUCHER_TYPE_SUCCESS: "GENERAL_GET_CBO_VOUCHER_TYPE_SUCCESS",
    GENERAL_LOAD_REPORT_LIST: "GENERAL_LOAD_REPORT_LIST",
    GENERAL_LOAD_REPORT_LIST_SUCCESS: "GENERAL_LOAD_REPORT_LIST_SUCCESS",
    GENERAL_EXPORT_TEMPLATE: "GENERAL_EXPORT_TEMPLATE",
    GENERAL_LOAD_VOUCHER_NO: "GENERAL_LOAD_VOUCHER_NO",
    GENERAL_LOAD_DATA_EVENT: "GENERAL_LOAD_DATA_EVENT",
    GENERAL_LOAD_CBO_BANQUET_CODE: "GENERAL_LOAD_CBO_BANQUET_CODE",
    GENERAL_LOAD_CBO_BANQUET_CODE_SUCCESS: "GENERAL_LOAD_CBO_BANQUET_CODE_SUCCESS",
    GENERAL_LOAD_CBO_BANK: "GENERAL_LOAD_CBO_BANK",
    GENERAL_LOAD_CBO_BANK_SUCCESS: "GENERAL_LOAD_CBO_BANK_SUCCESS",
    GENERAL_LOAD_CBO_PAYMENT_METHOD_FORM: "GENERAL_LOAD_CBO_PAYMENT_METHOD_FORM",
    GENERAL_LOAD_CBO_PAYMENT_METHOD_FORM_SUCCESS: "GENERAL_LOAD_CBO_PAYMENT_METHOD_FORM_SUCCESS"
};

export function resetPermission() {
    return async (dispatch) => {
        dispatch({ type: types.GET_PERMISSION_SUCCESS, data: 1 });
    };
}
/**
 * Get permission
 * @param params: @formIDs (array or string)
 * @param cb
 * @returns {Promise<void>}
 */
export function getPermission(formIDs, cb, flag) {
    let listScreenIDs = Array.isArray(formIDs) ? formIDs : [formIDs];
    const params = { FormID: JSON.stringify(listScreenIDs) };

    return async (dispatch) => {
        !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: null });
        await Api.put('/user/get-role-user', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: 0 });
                    cb && cb(0);
                } else if (result.data && result.data.length > 0) {
                    if (formIDs && Array.isArray(formIDs)) {
                        !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: result.data[0].Permission });
                        cb && cb(result.data);
                    }
                    else if (result.data[0] && result.data[0].Permission) {
                        !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: result.data[0].Permission });
                        cb && cb(result.data[0].Permission);
                    }
                    else {
                        !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: 0 });
                        cb && cb(0);
                    }
                } else {
                    !flag && dispatch({ type: types.GET_PERMISSION_SUCCESS, data: 0 });
                    cb && cb(0);
                }
            });
    }
}

//get cbo orgcharts..
export function getOrgCharts(params, cb) {
    return {
        type: types.GET_CBO_ORGCHART,
        params,
        cb
    }
}

//get cbo duty..
export function getCboDuty(params, cb) {
    return {
        type: types.GET_CBO_DUTY,
        params,
        cb
    }
}

//get cbo employees..
export function getCboEmployees(params, cb) {
    return {
        type: types.GET_CBO_EMPLOYEE,
        params,
        cb
    }
}

//get cbo projects..
export function getCboProjects(params, cb) {
    return {
        type: types.GET_CBO_PROJECT,
        params,
        cb
    }
}

//get cbo departments..
export function getCboDepartments(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_DEPARTMENTS,
        params,
        cb
    }
}

//get cbo departments..
export function getCboTeam(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_TEAM,
        params,
        cb
    }
}

//get cbo departments..
export function getCboWorks(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_WORKS,
        params,
        cb
    }
}

//get attachments by TransID (Key01ID)..
export function getAttachmentsByTransID(params, cb) {
    return {
        type: types.GENERAL_GET_ATTACHMENTS_BY_TRANSID,
        params,
        cb
    }
}

export function getCboAppStatus(params, cb) {
    return {
        type: types.GET_CBO_APP_STATUS,
        params,
        cb
    };
}


//export excel template....
export function exportExcelTemplate(params, cb) {
    return {
        type: types.GENERAL_EXPORT_EXCEL_TEMPLATE,
        params,
        cb
    }
}

//Get Grid General Information
export function getGridGeneralInformation(params, cb) {
    return {
        type: types.GET_GRID_GENERAL_INFORMATION,
        params,
        cb
    }
}


//Load History
export function loadHistory(params, cb) {
    return {
        type: types.GENERAL_LOAD_HISTORY,
        params,
        cb
    }
}

//Load Combo Report
export function loadCboReport(params, cb) {
    return {
        type: types.GENERAL_LOAD_CBO_REPORT,
        params,
        cb
    }
}

//Export Report
export function exportReport(params, cb) {
    return {
        type: types.GENERAL_EXPORT_REPORT,
        params,
        cb
    }
}

//Load History
export function getReports(params, cb) {
    return {
        type: types.GENERAL_LOAD_CBO_REPORT,
        params,
        cb
    }
}

//Save History
export function saveHistory(params, cb) {
    return async () => {
        return await Api.put('/history/add', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(result, null);
                    return null;
                } else if (result.data) {
                    cb && cb(null, result.data);
                    return result.data;
                } else {
                    cb && cb(result, null);
                    return null;
                }
            });
    }
}

//Load Select Inventory Modal
export function getInventories(params, cb) {
    return {
        type: types.GENERAL_GET_INVENTORIES,
        params,
        cb
    }
}

//Load Select Company Modal
export function getCompanies(params, cb) {
    return {
        type: types.GENERAL_GET_COMPANIES,
        params,
        cb
    }
}

//Save export button state
export function onSaveExportBtnState(btnExportState) {
    return {
        type: types.GENERAL_SAVE_EXPORT_BTN_STATE,
        btnExportState
    }
}

//Export flex report
export function exportFlexReport(params, cb) {
    return {
        type: types.GENERAL_EXPORT_FLEX_REPORT,
        params,
        cb
    }
}

//Check param export
export function passParametersExport(params, cb) {
    return {
        type: types.GENERAL_PASS_PARAM_EXPORT,
        params,
        cb
    }
}

//Load Select Object Modal
export function getObject(params, cb) {
    return {
        type: types.GENERAL_GET_OBJECT,
        params,
        cb
    }
}

export function getCboDivision(cb) {
    return {
        type: types.GENERAL_GET_CBO_DIVISION,
        cb,
    }
}

export function getCboCustomer(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_CUSTOMER,
        cb,
        params,
    };
}

export function getCboContractType(cb) {
    return {
        type: types.GENERAL_GET_CBO_CONTRACT_TYPE,
        cb,
    };
}

export function getCboEStatus(cb) {
    return {
        type: types.GENERAL_GET_CBO_STATUS,
        cb,
    };
}

export function getCboCard(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_CARD,
        cb,
        params,
    };
}

export function getCboCardOwner(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_CARD_OWNER,
        cb,
        params,
    };
}

export function getCboVATObjectType(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_VAT_OBJECT_TYPE,
        cb,
        params,
    };
}

export function getCboVATObject(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_VAT_OBJECTS,
        cb,
        params,
    };
}

export function getCboPaymentTerm(cb) {
    return {
        type: types.GENERAL_GET_CBO_PAYMENT_TERM,
        cb,
    };
}

export function getCboPaymentMethod(cb) {
    return {
        type: types.GENERAL_GET_CBO_PAYMENT_METHOD,
        cb,
    };
}

export function getCboSalesPerson(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_SALES_PERSON,
        cb,
        params,
    };
}

export function getCboCurrency(cb) {
    return {
        type: types.GENERAL_GET_CBO_CURRENCY,
        cb,
    };
}

export function getCboVATGroup(cb) {
    return {
        type: types.GENERAL_GET_CBO_VAT_GROUP,
        cb,
    };
}

//get cbo employees..
export function getCboCreateUser(params, cb) {
    return {
        type: types.GENERAL_CBO_CREATE_USER,
        params,
        cb
    }
}

export function getCboContractNo(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_CONTRACT_NO,
        cb,
        params,
    };
}

export function getCboMenuType(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_MENU_TYPE,
        params,
        cb,
    };
}

export function getCboMenuID(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_MENU_ID,
        params,
        cb,
    };
}

export function getCboInventoryMH(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_INVENTORY_MH,
        params,
        cb,
    };
}

export function getCboInventoryDV(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_INVENTORY_DV,
        params,
        cb,
    };
}

export function getCboUnit(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_UNIT,
        params,
        cb,
    };
}

export function getDataBookingNo(params, cb) {
    return {
        type: types.GENERAL_GET_DATA_BOOKING_NO,
        params,
        cb,
    };
}

export function getCboStatusBEO(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_STATUS_BEO,
        params,
        cb,
    };
}

export function getCboDepartment(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_DEPARTMENT,
        params,
        cb,
    };
}

export function getCboReceptionist(params, cb) {
    return {
        type: types.GENERAL_GET_CBO_RECEPTIONIST,
        params,
        cb,
    };
}

export function getCboVoucherType(cb) {
    return {
        type: types.GENERAL_GET_CBO_VOUCHER_TYPE,
        cb,
    };
}

export function getReportList(params, cb) {
    return {
        type: types.GENERAL_LOAD_REPORT_LIST,
        cb,
        params,
    };
}

export function exportTemplate(params, cb) {
    return {
        type: types.GENERAL_EXPORT_TEMPLATE,
        cb,
        params,
    };
}

export function loadVoucherNo(params, cb) {
    return {
        type: types.GENERAL_LOAD_VOUCHER_NO,
        cb,
        params,
    };
}

export function loadDataEvent(params, cb) {
    return {
        type: types.GENERAL_LOAD_DATA_EVENT,
        cb,
        params,
    };
}

export function loadCboBanquetCode(cb) {
    return {
        type: types.GENERAL_LOAD_CBO_BANQUET_CODE,
        cb
    };
}

export function getCboBank(params, cb) {
    return {
        type: types.GENERAL_LOAD_CBO_BANK,
        cb,
        params,
    };
}

export function getCboPaymentMethodForm(params, cb) {
    return {
        type: types.GENERAL_LOAD_CBO_PAYMENT_METHOD_FORM,
        cb,
        params,
    };
}
/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 16/06/2021
 * @Example
 */

export const types = {
    WA3F2000_LOAD_CONTRACT_GRID: "WA3F2000_LOAD_CONTRACT_GRID",
    WA3F2000_LOAD_CONTRACT_GRID_SUCCESS: "WA3F2000_LOAD_CONTRACT_GRID_SUCCESS",
    WA3F2000_LOAD_EVENT_GRID: "WA3F2000_LOAD_EVENT_GRID",
    WA3F2000_LOAD_TAB_CONTRACT: "WA3F2000_LOAD_TAB_CONTRACT",
    WA3F2000_SAVE_UPDATE_STATUS: "WA3F2000_SAVE_UPDATE_STATUS",
    WA3F2000_DELETE_CONTRACT: "WA3F2000_DELETE_CONTRACT",
    WA3F2000_DELETE_EVENT: "WA3F2000_DELETE_EVENT",
    WA3F2000_CHECK_EDIT_EVENT: "WA3F2000_CHECK_EDIT_EVENT",
    WA3F2000_CANCEL_CONTRACT: "WA3F2000_CANCEL_CONTRACT",
    WA3F2000_CANCEL_EVENT: "WA3F2000_CANCEL_EVENT",
};

export function loadContractGrid(params, cb) {
    return {
        type: types.WA3F2000_LOAD_CONTRACT_GRID,
        cb,
        params,
    };
}

export function loadEventGrid(params, cb) {
    return {
        type: types.WA3F2000_LOAD_EVENT_GRID,
        cb,
        params,
    };
}

export function loadTabContract(params, cb) {
    return {
        type: types.WA3F2000_LOAD_TAB_CONTRACT,
        cb,
        params
    };
}

export function saveUpdateStatus(params, cb) {
    return {
        type: types.WA3F2000_SAVE_UPDATE_STATUS,
        cb,
        params,
    };
}

export function deleteContract(params, cb) {
    return {
        type: types.WA3F2000_DELETE_CONTRACT,
        cb,
        params,
    };
}

export function deleteEvent(params, cb) {
    return {
        type: types.WA3F2000_DELETE_EVENT,
        cb,
        params,
    };
}

export function checkEditEvent(params, cb) {
    return {
        type: types.WA3F2000_CHECK_EDIT_EVENT,
        cb,
        params,
    };
}

export function cancelContract(params, cb) {
    return {
        type: types.WA3F2000_CANCEL_CONTRACT,
        cb,
        params,
    };
}

export function cancelEvent(params, cb) {
    return {
        type: types.WA3F2000_CANCEL_EVENT,
        cb,
        params,
    };
}


/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 18/11/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from "../../../../services/api";
import { types } from "./WA3F2105_actions";

export default function WA3F2105Sagas() {
    return [
        watchViewDetail(),
        watchCheckEdit(),
        watchOnDelete(),
        watchCheckChangeBookingInfo(),
        watchOnCancel(),
    ];
}

export function* viewDetail(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/wa3f2105/view-detail", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("view detail error");
    }
}

export function* watchViewDetail() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2105_VIEW_DETAIL, viewDetail);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* checkEdit(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/wa3f2105/check-edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("check edit error");
    }
}

export function* watchCheckEdit() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2105_CHECK_EDIT, checkEdit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* onDelete(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/wa3f2105/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete error");
    }
}

export function* watchOnDelete() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2105_ON_DELETE, onDelete);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* checkChangeBookingInfo(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/wa3f2105/check-change", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("check change booking info error");
    }
}

export function* watchCheckChangeBookingInfo() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2105_CHECK_CHANGE_BOOKING_INFO, checkChangeBookingInfo);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* onCancel(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/wa3f2105/cancel", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("cancel error");
    }
}

export function* watchOnCancel() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2105_CANCEL, onCancel);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
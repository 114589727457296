/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 24/11/2021
 * @Example
 */

export const types = {
    WA3F2020_LOAD_GRID: "WA3F2020_LOAD_GRID",
    WA3F2020_LOAD_GRID_TAB: "WA3F2020_LOAD_GRID_TAB",
    WA3F2020_CANCEL_ROW: "WA3F2020_CANCEL_ROW",
    WA3F2021_DELETE_ROW: "WA3F2021_DELETE_ROW",
};

export function loadGrid(params, cb) {
    return {
        type: types.WA3F2020_LOAD_GRID,
        params,
        cb
    }
};

export function loadTab(params, cb) {
    return {
        type: types.WA3F2020_LOAD_GRID_TAB,
        params,
        cb
    }
};

export function onCancel(params, cb) {
    return {
        type: types.WA3F2020_CANCEL_ROW,
        params,
        cb
    }
};

export function deleteRow(params, cb) {
    return {
        type: types.WA3F2021_DELETE_ROW,
        params,
        cb
    }
};

import { combineReducers } from 'redux';
import Main from '../redux/main/main_reducers';
import Home from '../redux/home/home_reducers';
import General from '../redux/general/general_reducers';
import History from '../redux/history/history_reducers';
import WA3F2103 from '../redux/modules/WA3/WA3F2103/WA3F2103_reducers';
import WA3F2000 from '../redux/modules/WA3/WA3F2000/WA3F2000_reducers';

export default combineReducers({
    main: Main,
    home: Home,
    general: General,
    history: History,
    WA3F2103: WA3F2103,
    WA3F2000: WA3F2000,
});

import {all} from 'redux-saga/effects';

import MainSagas     from "../redux/main/main_sagas";
import GeneralSagas  from "../redux/general/general_sagas";
import HomeSagas     from "../redux/home/home_sagas"
import AdminSagas    from "../redux/admin/admin_sagas";
import FileSagas     from "../redux/files/file_sagas";
import WA3F2100Sagas from '../redux/modules/WA3/WA3F2100/WA3F2100_sagas';
import WA3F2103Sagas from "../redux/modules/WA3/WA3F2103/WA3F2103_sagas";
import WA3F2110Sagas from "../redux/modules/WA3/WA3F2110/WA3F2110_sagas";
import WA3F2000Sagas from "../redux/modules/WA3/WA3F2000/WA3F2000_sagas";
import WA3F2001Sagas from "../redux/modules/WA3/WA3F2001/WA3F2001_sagas";
import WA3F2010Sagas from "../redux/modules/WA3/WA3F2010/WA3F2010_sagas";
import WA3F2030Sagas from "../redux/modules/WA3/WA3F2030/WA3F2030_sagas";
import WA3F2031Sagas from "../redux/modules/WA3/WA3F2031/WA3F2031_sagas";
import WA3F2105Sagas from "../redux/modules/WA3/WA3F2105/WA3F2105_sagas";
import WA3F2020Sagas from "../redux/modules/WA3/WA3F2020/WA3F2020_sagas";
import WA3F2021Sagas from "../redux/modules/WA3/WA3F2021/WA3F2021_sagas";
import WA3F2022Sagas from "../redux/modules/WA3/WA3F2022/WA3F2022_sagas";
import WA3F2040Sagas from "../redux/modules/WA3/WA3F2040/WA3F2040_sagas";
import WA3F2041Sagas from "../redux/modules/WA3/WA3F2041/WA3F2041_sagas";
import WA3F2106Sagas from "../redux/modules/WA3/WA3F2106/WA3F2106_sagas";

export default function* rootSaga() {
    yield all([
        //Main
        MainSagas(),

        FileSagas(),

        //Admin
        AdminSagas(),

        //Generals
        GeneralSagas(),

        //Home
        HomeSagas(),

        //WA3
        WA3F2100Sagas(),
        WA3F2103Sagas(),
        WA3F2110Sagas(),
        WA3F2000Sagas(),
        WA3F2001Sagas(),
        WA3F2010Sagas(),
        WA3F2030Sagas(),
        WA3F2031Sagas(),
        WA3F2105Sagas(),
        WA3F2020Sagas(),
        WA3F2021Sagas(),
        WA3F2022Sagas(),
        WA3F2040Sagas(),
        WA3F2041Sagas(),
        WA3F2106Sagas(),

    ].flat(1))
}

/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 16/06/2021
 * @Example
 */

import Api from "../../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./WA3F2000_actions";
import { delay } from "redux-saga";

export default function WA3F2000Sagas() {
  return [
    watchLoadContractGrid(),
    watchLoadEventGrid(),
    watchLoadTabContract(),
    watchSaveUpdateStatus(),
    watchDeleteContract(),
    watchDeleteEvent(),
    watchcheckEditEvent(),
    watchCancelContract(),
    watchCancelEvent(),
  ];
}

export function* loadContractGrid(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2000/load-grid-contract", data.params);
    if (res && res.data) {
      yield put({ type: types.WA3F2000_LOAD_CONTRACT_GRID_SUCCESS, data: res.data });
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load contract grid error", e);
  }
}

export function* watchLoadContractGrid() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2000_LOAD_CONTRACT_GRID, loadContractGrid);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* loadEventGrid(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2000/load-grid-event", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load event grid error", e);
  }
}

export function* watchLoadEventGrid() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2000_LOAD_EVENT_GRID, loadEventGrid);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* loadTabContract(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2000/load-tab-contract", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load header title tab error", e);
  }
}

export function* watchLoadTabContract() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2000_LOAD_TAB_CONTRACT, loadTabContract);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* saveUpdateStatus(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2000/update-estatus", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("save update status error", e);
  }
}

export function* watchSaveUpdateStatus() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2000_SAVE_UPDATE_STATUS, saveUpdateStatus);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* deleteContract(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2000/delete-contract", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("delete contract error", e);
  }
}

export function* watchDeleteContract() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2000_DELETE_CONTRACT, deleteContract);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* deleteEvent(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2000/delete-event", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("delete event error", e);
  }
}

export function* watchDeleteEvent() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2000_DELETE_EVENT, deleteEvent);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* checkEditEvent(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2000/check-update", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("check edit event error", e);
  }
}

export function* watchcheckEditEvent() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2000_CHECK_EDIT_EVENT, checkEditEvent);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* cancelContract(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2000/cancel-contract", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("cancel contract error", e);
  }
}

export function* watchCancelContract() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2000_CANCEL_CONTRACT, cancelContract);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* cancelEvent(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2000/cancel-event", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("cancel event error", e);
  }
}

export function* watchCancelEvent() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2000_CANCEL_EVENT, cancelEvent);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

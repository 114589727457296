/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 18/11/2021
 * @Example
 */
export const types = {
    WA3F2105_VIEW_DETAIL: "WA3F2105_VIEW_DETAIL",
    WA3F2105_CHECK_EDIT: "WA3F2105_CHECK_EDIT",
    WA3F2105_ON_DELETE: "WA3F2105_ON_DELETE",
    WA3F2105_CHECK_CHANGE_BOOKING_INFO: "WA3F2105_CHECK_CHANGE_BOOKING_INFO",
    WA3F2105_CANCEL: "WA3F2105_CANCEL",
};

export function viewDetail(params, cb) {
    return {
        type: types.WA3F2105_VIEW_DETAIL,
        params,
        cb
    };
}

export function checkEdit(params, cb) {
    return {
        type: types.WA3F2105_CHECK_EDIT,
        params,
        cb
    };
}

export function onDelete(params, cb) {
    return {
        type: types.WA3F2105_ON_DELETE,
        params,
        cb
    };
}

export function checkChangeBookingInfo(params, cb) {
    return {
        type: types.WA3F2105_CHECK_CHANGE_BOOKING_INFO,
        params,
        cb
    };
}

export function onCancel(params, cb) {
    return {
        type: types.WA3F2105_CANCEL,
        params,
        cb
    };
}


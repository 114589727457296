/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 24/11/2021
 * @Example
 */

import { delay } from 'redux-saga';
import { cancel, take, takeLatest } from 'redux-saga/effects';
import Api from '../../../../services/api';
import { types } from "./WA3F2020_actions";

export default function WA3F2020Sagas() {
    return [
        watchLoadGrid(),
        watchLoadTab(),
        watchCancelRow(),
        watchDeleteRow(),
    ];
};

export function* loadTab(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2020/load-form-tab', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load tab error');
    }
};

export function* watchLoadTab() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2020_LOAD_GRID_TAB, loadTab);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2020/load-form-grid', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load grid error');
    }
};

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2020_LOAD_GRID, loadGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};

export function* cancelRow(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2020/cancel', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('cancel row error');
    }
};

export function* watchCancelRow() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2020_CANCEL_ROW, cancelRow);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2020/delete', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('delete row error');
    }
};

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2021_DELETE_ROW, deleteRow);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};
/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 05/05/2022
 * @Example
 */

import { delay } from 'redux-saga';
import { cancel, take, takeLatest } from 'redux-saga/effects';
import Api from '../../../../services/api';
import { types } from "./WA3F2040_actions";

export default function WA3F2100Sagas() {
    return [
        watchLoadGrid(),
        watchOnCancel()
    ];
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2040/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load grid error');
    }
}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2040_LOAD_GRID, loadGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* onCancel(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2040/cancel', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('cancel error');
    }
}

export function* watchOnCancel() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2040_CANCEL, onCancel);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 01/11/2021
 * @Example
 */

import { delay } from 'redux-saga';
import { cancel, take, takeLatest } from 'redux-saga/effects';
import Api from '../../../../services/api';
import { types } from "./WA3F2030_actions";

export default function WA3F2100Sagas() {
    return [
        watchLoaDGridTab(),
        watchLoadBEOGrid(),
        watchDeleteBEO(),
        watchCancelBEO(),
        watchCreateBEO(),
    ];
}

export function* loaDGridTab(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2030/load-tab', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load grid tab error');
    }
}

export function* watchLoaDGridTab() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2030_LOAD_GRID_TAB, loaDGridTab);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadBEOGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2030/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load BEO grid error');
    }
}

export function* watchLoadBEOGrid() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2030_LOAD_BEO_GRID, loadBEOGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* deleteBEO(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2030/delete', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('delete BEO error');
    }
}

export function* watchDeleteBEO() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2030_DELETE_BEO, deleteBEO);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* cancelBEO(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2030/cancel', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('cancel BEO error');
    }
}

export function* watchCancelBEO() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2030_CANCEL_BEO, cancelBEO);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* createBEO(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2030/create', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('create BEO error');
    }
}
export function* watchCreateBEO() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2030_CREATE_BEO, createBEO);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
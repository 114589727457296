/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 25/04/2022
 * @Example
 */

export const types = {
    WA3F2022_LOAD_FORM: "WA3F2022_LOAD_FORM",
    WA3F2022_SAVE_ADD: "WA3F2022_SAVE_ADD",
    WA3F2022_LOAD_VOUCHER_NO: "WA3F2022_LOAD_VOUCHER_NO",
    WA3F2022_LOAD_CBO_EVENT_CODE: "WA3F2022_LOAD_CBO_EVENT_CODE",
    WA3F2022_LOAD_GRID_PAYMENT_METHOD: "WA3F2022_LOAD_GRID_PAYMENT_METHOD"
};

export function loadForm(params, cb) {
    return {
        type: types.WA3F2022_LOAD_FORM,
        params,
        cb
    }
};

export function saveAdd(params, cb) {
    return {
        type: types.WA3F2022_SAVE_ADD,
        params,
        cb
    }
};

export function loadVoucherNo(params, cb) {
    return {
        type: types.WA3F2022_LOAD_VOUCHER_NO,
        params,
        cb
    }
};

export function loadCboEventCode(params, cb) {
    return {
        type: types.WA3F2022_LOAD_CBO_EVENT_CODE,
        params,
        cb
    }
};

export function loadGridPaymentMethod(params, cb) {
    return {
        type: types.WA3F2022_LOAD_GRID_PAYMENT_METHOD,
        params,
        cb
    }
};



/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 25/04/2022
 * @Example
 */
import { delay } from 'redux-saga';
import { cancel, take, takeLatest } from 'redux-saga/effects';
import Api from '../../../../services/api';
import { types } from "./WA3F2022_actions";

export default function WA3F2022Sagas() {
    return [
        watchLoadForm(),
        watchSaveAdd(),
        watchLoadVoucherNo(),
        watchLoadCboEventCode(),
        watchLoadGridPaymentMethod()
    ];
};

export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2022/load-view-detail', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load form error');
    }
};

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2022_LOAD_FORM, loadForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};

export function* saveAdd(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2022/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save add error');
    }
};

export function* watchSaveAdd() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2022_SAVE_ADD, saveAdd);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};

export function* loadVoucherNo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2001/load-contract-no', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load voucher no error');
    }
};

export function* watchLoadVoucherNo() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2022_LOAD_VOUCHER_NO, loadVoucherNo);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};

export function* loadCboEventCode(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2022/load-combo-event-code', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load cbo event code error');
    }
};

export function* watchLoadCboEventCode() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2022_LOAD_CBO_EVENT_CODE, loadCboEventCode);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};

export function* loadGridPaymentMethod(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2021/load-grid-payment-method', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load grid payment method error');
    }
};

export function* watchLoadGridPaymentMethod() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2022_LOAD_GRID_PAYMENT_METHOD, loadGridPaymentMethod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};
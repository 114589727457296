/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/18/2019
 * @Example
 */
import {delay}                    from "redux-saga";
import {types}                    from "../files/file_actions";
import {ApiCDN}                   from "../../components/common/utils/CDN";
import {cancel, take, takeLatest} from "redux-saga/effects";

export default function FileSagas() {
    return [
        watchRemoveFileCDN(),
    ];
}
export function* removeFileCDN(data) {
    try {
        yield delay(300);
        const response = yield ApiCDN.deleteCDN('/file/remove', data.params, data.tokenCDN);
        if (response && response.data) {
            data.cb && data.cb(null, response)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('remove file cdn is error');
    }

}

export function* watchRemoveFileCDN() {
    while (true){
        const watcher = yield takeLatest(types.FILE_REMOVE, removeFileCDN);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}


/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 05/05/2022
 * @Example
 */

export const types = {
    WA3F2040_LOAD_GRID: "WA3F2040_LOAD_GRID",
    WA3F2040_CANCEL: "WA3F2040_CANCEL"
};

export function loadGrid(params, cb) {
    return {
        type: types.WA3F2040_LOAD_GRID,
        params,
        cb
    }
}

export function onCancel(params, cb) {
    return {
        type: types.WA3F2040_CANCEL,
        params,
        cb
    }
}
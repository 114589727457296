/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @file src/routes/routes.js
 */

import React, { Component } from 'react';
import { Router, Route, browserHistory, IndexRoute } from 'react-router'
import App from '../container/app';
import Config from '../config';

import ModuleNotFound from '../components/common/layouts/errors/not-found';
import ErrorPage      from '../components/common/layouts/errors/error-500';
import NotPermission  from '../components/common/layouts/errors/not-permission';

// import Dashboard    from '../components/dashboard/dashboard';
import AuthPage     from '../components/login/auth';
import WA3F1025     from "../components/modules/WA3/WA3F1025/WA3F1025";
import WA3F2100 from '../components/modules/WA3/WA3F2100/WA3F2100';
import WA3F2103     from "../components/modules/WA3/WA3F2103/WA3F2103";
import WA3F2000 from '../components/modules/WA3/WA3F2000/WA3F2000';
import WA3F0100 from '../components/modules/WA3/WA3F0100/WA3F0100';
import WA3F2010     from "../components/modules/WA3/WA3F2010/WA3F2010";
import WA3F2030     from "../components/modules/WA3/WA3F2030/WA3F2030";
import WA3F2020     from "../components/modules/WA3/WA3F2020/WA3F2020";
import WA3F2040     from "../components/modules/WA3/WA3F2040/WA3F2040";
import WA3F2041     from "../components/modules/WA3/WA3F2041/WA3F2041";

let oldRouter = '';
class Routes extends Component {

    onRouteUpdate = (e) => {
        window.scrollTo(0, 0);
        // ===== DEBUGGER =====
        if (oldRouter !== browserHistory.getCurrentLocation()?.pathname && Config?.debugger) {
            let dataDebuggerError = Config.debugger.dataDebuggerError;
            Config.debugger.setData(dataDebuggerError);
        }
        oldRouter = browserHistory.getCurrentLocation()?.pathname;
        // ===== DEBUGGER =====
    };

    render() {
        return (
            <Router onUpdate={this.onRouteUpdate} history={browserHistory}>
                <Route path={Config.getRootPath()} component={App}>
                    <IndexRoute component={WA3F2100} />

                    <Route path="admin"
                           component={()=><section>Redirecting...</section>}/>

                    {/* Modules */}
                    <Route path="WA3F1025" component={WA3F1025} />
                    <Route path="WA3F2103" component={WA3F2103} />

                    <Route path="dashboard" component={WA3F2100} />
                    <Route path="auth" component={AuthPage} />

                    {/* WA3 */}
                    <Route path="WA3F0100" component={WA3F0100} />
                    <Route path="WA3F2100" component={WA3F2100} />
                    <Route path="WA3F2000" component={WA3F2000} />
                    <Route path="WA3F2010" component={WA3F2010} />
                    <Route path="WA3F2030" component={WA3F2030} />
                    <Route path="WA3F2020" component={WA3F2020} />
                    <Route path="WA3F2040" component={WA3F2040} />
                    <Route path="WA3F2041" component={WA3F2041} />

                    <Route path="error-page" component={ErrorPage} />

                    <Route path="not-permission" component={NotPermission} />

                    <Route path='*' exact={true} component={ModuleNotFound} />
                </Route>
            </Router>
        );
    };
}

export default Routes;

/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 12/07/2021
 * @Example
 */

export const types = {
    WA3F2010_LOAD_FORM: "WA3F2010_LOAD_FORM",
    WA3F2010_ON_SAVE_EDIT: "WA3F2010_ON_SAVE_EDIT",
    WA3F2010_GET_BOOKING_NO: "WA3F2010_GET_BOOKING_NO",
    WA3F2010_ON_SAVE_ADD: "WA3F2010_ON_SAVE_ADD",
    WA3F2010_LOAD_DATA_GRID_MENU: "WA3F2010_LOAD_DATA_GRID_MENU",
    WA3F2010_CHECK_EDIT: "WA3F2010_CHECK_EDIT",
    WA3F2010_LOAD_DEPOSIT_AMOUNT01: "WA3F2010_LOAD_DEPOSIT_AMOUNT01",
    WA3F2010_LOAD_PROMOTIONS: "WA3F2010_LOAD_PROMOTIONS",
    WA3F2010_CHECK_BOOKING_ID: "WA3F2010_CHECK_BOOKING_ID",
    WA3F2010_LOAD_CBO_CTKM: "WA3F2010_LOAD_CBO_CTKM",
    WA3F2010_LOAD_GRID_PROMOTION_SERVICE: "WA3F2010_LOAD_GRID_PROMOTION_SERVICE",
    WA3F2010_CHECK_RECEIPT: "WA3F2010_CHECK_RECEIPT",
    WA3F2010_CHECK_BEO: "WA3F2010_CHECK_BEO"
};

export function loadForm(params, cb) {
    return {
        type: types.WA3F2010_LOAD_FORM,
        cb,
        params
    };
}

export function onSaveEdit(params, cb) {
    return {
        type: types.WA3F2010_ON_SAVE_EDIT,
        cb,
        params
    };
}

export function getBookingNo(params, cb) {
    return {
        type: types.WA3F2010_GET_BOOKING_NO,
        cb,
        params
    };
}

export function onSaveAdd(params, cb) {
    return {
        type: types.WA3F2010_ON_SAVE_ADD,
        cb,
        params
    };
}

export function loadGridMenu(params, cb) {
    return {
        type: types.WA3F2010_LOAD_DATA_GRID_MENU,
        cb,
        params
    };
}

export function checkEdit(params, cb) {
    return {
        type: types.WA3F2010_CHECK_EDIT,
        cb,
        params
    };
}


export function loadDepositAmount01(params, cb) {
    return {
        type: types.WA3F2010_LOAD_DEPOSIT_AMOUNT01,
        cb,
        params
    };
}

export function loadPromotions(params, cb) {
    return {
        type: types.WA3F2010_LOAD_PROMOTIONS,
        cb,
        params
    }
}

export function checkBookingID(params, cb) {
    return {
        type: types.WA3F2010_CHECK_BOOKING_ID,
        cb,
        params
    }
}

export function loadCboCTKM(params, cb) {
    return {
        type: types.WA3F2010_LOAD_CBO_CTKM,
        cb,
        params
    }
}

export function loadGridPromotionService(params, cb) {
    return {
        type: types.WA3F2010_LOAD_GRID_PROMOTION_SERVICE,
        cb,
        params
    }
}

export function checkReceipt(params, cb) {
    return {
        type: types.WA3F2010_CHECK_RECEIPT,
        cb,
        params
    }
}

export function checkBeo(params, cb) {
    return {
        type: types.WA3F2010_CHECK_BEO,
        cb,
        params
    }
}




/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 5/4/2021
 * @Example
 */
export const types = {
    WA3F2103_LOAD_BANQUET:         "WA3F2103_LOAD_BANQUET",
    WA3F2103_LOAD_BANQUET_SUCCESS: "WA3F2103_LOAD_BANQUET_SUCCESS",
    WA3F2103_LOAD_FORM:            "WA3F2103_LOAD_FORM",
    WA3F2103_LOAD_FORM_SUCCESS:    "WA3F2103_LOAD_FORM_SUCCESS",
};

export function loadCboBanquet (params, cb) {
    return {
        type: types.WA3F2103_LOAD_BANQUET,
        params,
        cb
    };
}

export function loadForm (params, cb) {
    return {
        type: types.WA3F2103_LOAD_FORM,
        params,
        cb
    };
}


/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 17/06/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from "../../../../services/api";
import { types } from "./WA3F2001_actions";

export default function WA3F2001Sagas() {
  return [
    watchLoadForm(),
    watchOnSave(),
    watchOnSaveEdit(),
    watchLoadConTractNo()
  ];
}

export function* loadForm(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2001/load-form", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load form error", e);
  }
}

export function* watchLoadForm() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2001_LOAD_FORM, loadForm);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* onSave(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2001/save", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("save data error", e);
  }
}

export function* watchOnSave() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2001_ON_SAVE, onSave);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* onSaveEdit(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2001/edit", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("save edit data error", e);
  }
}

export function* watchOnSaveEdit() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2001_ON_SAVE_EDIT, onSaveEdit);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* loadConTractNo(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2001/load-contract-no", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load contract no error", e);
  }
}

export function* watchLoadConTractNo() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2001_LOAD_CONTRACT_NO, loadConTractNo);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
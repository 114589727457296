/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 05/05/2022
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest, takeEvery } from "redux-saga/effects";
import Api from "../../../../services/api";
import { types } from "./WA3F2041_actions";

export default function WA3F2041Sagas() {
  return [
    watchLoadCboInventory(),
    watchLoadEvent(),
    watchLoadCboUnit(),
    watchLoadForm(),
    watchLoadGridDetail(),
    watchOnSave(),
    watchLoadCboDropdown(),
    watchLoadGrid3(),
  ];
}

export function* loadCboInventory(data) {
  try {
    yield delay(300);
    const response = yield Api.put('/wa3f2041/load-inventory', data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data)
    } else {
      data.cb && data.cb(response, null)
    }
  }
  catch (e) {
    console.log('load cbo inventory error');
  }
};

export function* watchLoadCboInventory() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2041_LOAD_CBO_INVENTORY, loadCboInventory);
    yield take(['LOGOUT', 'NETWORK']);
    yield cancel(watcher);
  }
};

export function* loadEvent(data) {
  try {
    yield delay(300);
    const response = yield Api.put('/wa3f2022/load-combo-event-code', data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data)
    } else {
      data.cb && data.cb(response, null)
    }
  }
  catch (e) {
    console.log('load event error');
  }
};

export function* watchLoadEvent() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2041_LOAD_EVENT, loadEvent);
    yield take(['LOGOUT', 'NETWORK']);
    yield cancel(watcher);
  }
};

export function* loadCboUnit(data) {
  try {
    yield delay(300);
    const response = yield Api.put('/wa3f2041/load-unit', data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data)
    } else {
      data.cb && data.cb(response, null)
    }
  }
  catch (e) {
    console.log('load cbo unit error');
  }
};

export function* watchLoadCboUnit() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2041_LOAD_CBO_UNIT, loadCboUnit);
    yield take(['LOGOUT', 'NETWORK']);
    yield cancel(watcher);
  }
};

export function* loadForm(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2041/load-view", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load form error", e);
  }
}

export function* watchLoadForm() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2041_LOAD_FORM, loadForm);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* onSave(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2041/save", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("save data error", e);
  }
}

export function* watchOnSave() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2041_ON_SAVE, onSave);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* loadGridDetail(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2041/load-grid-detail", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("save grid detail error", e);
  }
}

export function* watchLoadGridDetail() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2041_LOAD_GRID_DETAIL, loadGridDetail);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* loadCboDropdown(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2041/load-combo-dropdown", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load cbo dropdown error", e);
  }
}

export function* watchLoadCboDropdown() {
  while (true) {
    const watcher = yield takeEvery(types.WA3F2041_LOAD_CBO_DROPDOWN, loadCboDropdown);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* loadGrid3(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/wa3f2041/load-form", data.params);
    if (res && res.data) {
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {
    console.log("load grid error", e);
  }
}

export function* watchLoadGrid3() {
  while (true) {
    const watcher = yield takeLatest(types.WA3F2041_LOAD_GRID3, loadGrid3);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
import { types } from "./general_actions";
import _ from "lodash";

const initialState = {
    iPermission: null,
    paramsCboGeneralReportBeforce: {},
    getOrgCharts: [],
    getCboDuty: [],
    getCboEmployees: [],
    getCboProjects: [],
    getCboDepartments: [],
    getCboTeam: [],
    getCboWorks: [],
    getCboHandOver: [],
    getCboProjectsW29f2005: [],
    getCboAppStatus: [],
    getCboGeneralReport: [],
    getCboDivision: [],
    dataSelectModal: [],
    exportBtnState: {},
    dataCboContractType: [],
    dataCboEStatus: [],
    dataCboPaymentTerm: [],
    dataCboPaymentMethod: [],
    dataCboCurrency: [],
    dataCboVATGroup: [],
    dataCboContractNo: [],
    dataBookingNo: [],
    dataCboVoucherType: [],
    dataCboReport: [],
    dataCboCustomer: {},
    dataCboBanquetCode: [],
    dataCboBank: [],
    dataCboPaymentMethodForm: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.GET_PERMISSION_SUCCESS:
            return {
                ...state,
                iPermission: action.data
            };
        case types.GET_CBO_ORGCHART_SUCCESS:
            return {
                ...state,
                getOrgCharts: action.data
            };
        case types.GET_CBO_DUTY_SUCCESS:
            return {
                ...state,
                getCboDuty: action.data
            };
        case types.GET_CBO_PROJECT_SUCCESS:
            return {
                ...state,
                getCboProjects: action.data
            };
        case types.GENERAL_GET_CBO_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                getCboDepartments: action.data
            };
        case types.GENERAL_GET_CBO_TEAM_SUCCESS:
            return {
                ...state,
                getCboTeam: action.data
            };
        case types.GENERAL_GET_CBO_WORKS_SUCCESS:
            return {
                ...state,
                getCboWorks: action.data
            };
        case types.GET_CBO_EMPLOYEE_SUCCESS:
            return {
                ...state,
                getCboEmployees: action.data
            };
        case types.GET_CBO_APP_STATUS_SUCCESS:
            return {
                ...state,
                getCboAppStatus: action.data
            };
        case types.GENERAL_LOAD_CBO_REPORT_SUCCESS:
            return {
                ...state,
                getCboGeneralReport: action.data,
                paramsCboGeneralReportBeforce: action.params
            };
        case types.GENERAL_GET_CBO_DIVISION_SUCCESS:
            return {
                ...state,
                getCboDivision: action.data
            };
        case types.GENERAL_GET_INVENTORIES_SUCCESS:
        case types.GENERAL_GET_COMPANIES_SUCCESS:
        case types.GENERAL_GET_OBJECT_SUCCESS:
            if (action.params.skip !== 0 || !_.isEmpty(action.params.StrSearch)) return { ...state };
            return { ...state, dataSelectModal: action.data };
        default:
            return state;
        case types.GENERAL_SAVE_EXPORT_BTN_STATE_SUCCESS:
            return { ...state, exportBtnState: action.btnExportState };

        case types.GENERAL_GET_CBO_CONTRACT_TYPE_SUCCESS: {
            return { ...state, dataCboContractType: action.data };
        }
        case types.GENERAL_GET_CBO_STATUS_SUCCESS: {
            return { ...state, dataCboEStatus: action.data };
        }
        case types.GENERAL_GET_CBO_PAYMENT_TERM_SUCCESS: {
            return { ...state, dataCboPaymentTerm: action.data };
        }
        case types.GENERAL_GET_CBO_PAYMENT_METHOD_SUCCESS: {
            return { ...state, dataCboPaymentMethod: action.data };
        }
        case types.GENERAL_GET_CBO_CURRENCY_SUCCESS: {
            return { ...state, dataCboCurrency: action.data };
        }
        case types.GENERAL_GET_CBO_VAT_GROUP_SUCCESS: {
            return { ...state, dataCboVATGroup: action.data };
        }
        case types.GENERAL_GET_CBO_CONTRACT_NO_SUCCESS: {
            return { ...state, dataCboContractNo: action.data };
        }
        case types.GENERAL_GET_DATA_BOOKING_NO_SUCCESS: {
            return { ...state, dataCboContractNo: action.data };
        }
        case types.GENERAL_GET_CBO_VOUCHER_TYPE_SUCCESS: {
            return { ...state, dataCboVoucherType: action.data };
        }
        case types.GENERAL_LOAD_REPORT_LIST_SUCCESS: {
            let dataReport = action.data;
            if (!_.isEmpty(dataReport)) {
                dataReport = dataReport.map(item => {
                    const line = !_.isEmpty(item.ReportID) || !_.isEmpty(item.ReportName) ? "-" : "";
                    return ({ ...item, ReportCustomName: `${item.ReportID} ${line} ${item.ReportName}` })
                });
            }
            return { ...state, dataCboReport: dataReport };
        }
        case types.GENERAL_GET_CBO_CUSTOMER_SUCCESS: {
            return { ...state, dataCboCustomer: action.data };
        }
        case types.GENERAL_LOAD_CBO_BANQUET_CODE_SUCCESS: {
            return { ...state, dataCboBanquetCode: action.data };
        }
        case types.GENERAL_LOAD_CBO_BANK_SUCCESS: {
            return { ...state, dataCboBank: action.data };
        }
        case types.GENERAL_LOAD_CBO_PAYMENT_METHOD_FORM_SUCCESS: {
            return { ...state, dataCboPaymentMethodForm: action.data };
        }
    }
}

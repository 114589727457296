/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 24/06/2022
 * @Example
 */

export const types = {
    WA3F2106_LOAD_FORM: "WA3F2106_LOAD_FORM",
    WA3F2106_SAVE: "WA3F2106_SAVE_ADD",
};

export function loadForm(params, cb) {
    return {
        type: types.WA3F2106_LOAD_FORM,
        params,
        cb
    }
};

export function save(params, cb) {
    return {
        type: types.WA3F2106_SAVE,
        params,
        cb
    }
};



/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/04/15 21:10
 * @update 2019/04/15 21:10
 */

import Config from '../config';

class Api {
    static isLogout = false;
    static headers() {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': 'content-type,token,secret'
        };

        if (Config.getToken) {
            Config.getToken = false;
            headers.secret = (process && process.env && process.env.REACT_APP_SECRET) ? process.env.REACT_APP_SECRET : 'd9had0kC4Lnx7CvQ1NwOe3uGi0cW0ya2kv3dg';
        } else {
            headers.token = Config.token.id;
        }

        return headers;
    }

    static get(route) {
        return this.xhr(route, null, 'GET');
    }

    static put(route, params) {
        return this.xhr(route, params, 'PUT');
    }

    static post(route, params) {
        return this.xhr(route, params, 'POST');
    }

    static delete(route, params) {
        return this.xhr(route, params, 'DELETE')
    }

    static upload(route, params) {
        const url = Config.env.api + route;
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'token': Config.token.id
            },
            body: params
        };

        return fetch(url, options)
            .then(resp => {
                if (resp && resp.ok) {
                    return resp.json();
                }

                return resp.json().then(err => {
                    Api.checkSystemError(err);
                });
            });
    }

    static getCDN(route) {
        const url = Config.env.cdn + route;
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'secret': Config.env.secretCDN,
            }
        };

        return fetch(url, options)
            .then(resp => {
                if (resp && resp.ok) {
                    return resp.json();
                }

                return resp.json().then(err => {
                    Api.checkSystemError(err);
                });
            });
    }

    static putExport(route, params, fileType = "") {
        let url = route.indexOf("://");
        url = url === -1 ? Config.env.api + route : route;
        let defaultFileType = ".xlsx";
        const data = {
            method: 'PUT',
            headers: Api.headers(),
            body: JSON.stringify(params)
        };

        return fetch(url, data)
            .then(async response => {
                let content_dispostion = response.headers.get('Content-Disposition');
                if (content_dispostion) {
                    content_dispostion = content_dispostion ? content_dispostion.split('filename=') : '';
                    if (fileType === "html") defaultFileType = ".doc";
                    const filename = content_dispostion.length > 1 ? content_dispostion[1] : "report-filename." + defaultFileType;

                    return await {ok: response.ok, type: "blob", filename: filename.replace(/"/g, ''), data: response};
                } else {
                    return await {ok: response.ok, type: "json", data: response};
                }
            })
            .then(async (result) => {
                if (result && result.ok) {
                    return await result;
                } else {
                    return await Api.checkSystemError({code: 'SYS001', message: 'System error!'});
                }
            })
            .catch(e => {
                // clearTimeout(timer);
                return Api.checkSystemError(e);
            });;
    }

    static putCDN(route, params, tokenCDN) {
        const url = Config.env.cdn + route;
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'token': tokenCDN
            },
            body: params
        };

        return fetch(url, options)
            .then(resp => {
                if (resp && resp.ok) {
                    return resp.json();
                }

                return resp.json().then(err => {
                    Api.checkSystemError(err);
                });
            });
    }

    static checkAPI (apiName) {
        const isHave = this.dataAPIWHRP.find(i=>i===apiName);
        return !!isHave;
    }

    static xhr(route, params, method, header) {

        const dataAPI = ['/device/add'];
        let url = route.indexOf("://");
        url = url === -1 ? Config.env.api + route : route;
        // if(method !== 'GET') params.AppID='WHRP';
        if(dataAPI.find(api=>api===route)) params.AppID = Config.env.productID || "WBEM";
        header = header || Api.headers();
        const options = {
            method: method,
            headers: header,
            body: params ? JSON.stringify(params) : null
        };

        // console.log('==== url: ', url);
        // console.log('==== options: ', options);

        const promise = new Promise( (resolve, reject) => {
            // const timer = setTimeout(() => {
            //     return reject({code: 'SYS001', message: 'System error!'});
            // }, 2000);
            fetch(url, options)
                .then(async (resp) => {
                    // clearTimeout(timer);
                    if (resp && resp.ok) {
                        // console.log('===== API.xhr => data:',url, resp);
                        try {
                            const data = await resp.json();

                            // console.log('===== API.xhr => data:',url, data);

                            // ===== DEBUGGER =====
                            if (Config.debugger) Config.debugger.pushData({ ...data, route });
                            // ===== DEBUGGER =====

                            if (data && data.code && data.message) {
                                return resolve(Api.checkSystemError(data, route));
                            } else {
                                return resolve(data);
                            }

                        } catch (e) {
                            return resolve(Api.checkSystemError({code: 'SYS001', message: 'System error!'}, route));
                        }
                    } else {
                        if (resp.status === 404 && route.indexOf("://") === -1 && Config.env.mookAPI && Config.env.mookAPI !== "NO") {
                            const url = Config.env.mookAPI || "";
                            if (route !== url + route) {
                                const mockHeader = {'Content-Type':'application/x-www-form-urlencoded'};
                                return resolve(this.xhr(url + route, params, method, mockHeader));
                            } else {
                                return resolve(Api.checkSystemError({
                                    code:    'SYS001',
                                    message: 'System error!'
                                }, route));
                            }
                        } else {
                            return resolve(Api.checkSystemError({
                                code:    'SYS001',
                                message: 'System error!'
                            }, route));
                        }
                    }
                }).catch(e => {
                    // clearTimeout(timer);
                    return resolve(Api.checkSystemError(e, route));
                });
        });

        return promise.then((result) => {
            return result;
        }, (err) => {
            return err;
        });

    }

    static checkSystemError = (error, route) => {
        const code = error.code || null;

        const online = navigator.onLine;
        if (!online) {
            return {code: 'SYS000', message: Config.lang('Khong_co_ket_noi')};
        }

        switch (code) {
            case "SYS001":
                error = {code: 'SYS001', message: 'System error!'};
                break;
            case "SYS002":
                error = {code: 'SYS002', message: "Authentication token is required"};
                break;
            case "SYS003":
                error = {code: 'SYS003', message: "Authentication token are not matching"};
                break;
            case "SYS004":
                error = {code: 'SYS004', message: "Authentication token is expired"};
                alert("Phiên làm việc đã hết hạn.\nVui lòng đăng nhập lại.");
                break;
            case "SYS005":
                error = {code: 'SYS005', message: "Authentication error request timeout"};
                break;
            case "SYS006":
                error = {code: 'SYS006', message: "Update token fail"};
                break;
            case "SYS007":
                error = {code: 'SYS007', message: "Socket error"};
                break;
            case "SYS008":
                error = {code: 'SYS008', message: "The data is not in JSON format"};
                break;
            case "SYS009":
                error = {code: 'SYS009', message: "The data is not in list"};
                break;
            case "SYS010":
                error = {code: 'SYS010', message: "The data is not number"};
                break;
            case "SYS011":
                error = {code: 'SYS011', message: "The data is unique"};
                break;
            case "SYS500":
                error = {code: 'SYS500', message: "The unknown error has occurred"};
                break;
            default:
                error = {code: 'SYS500', message: "The unknown error has occurred", ...error};
                break;
        }

        if (error.code === 'SYS500' || error.code === 'SYS001') {
            Config.notifyError && Config.notifyError.show('error', Config.lang("Co_loi_xay_ra_trong_qua_trinh_xu_ly"), 5000);
            // browserHistory.push(Config.getRootPath() + 'error-page');
            console.log("Error: " + route, error);
            return true;
        }
        if (error.code === 'SYS002' || error.code === 'SYS003' || error.code === 'SYS030') {
            const tokenLogin = Config.getLocalStorage('TOKENBEM_LOGIN');
            if(process && process.env && process.env.REACT_APP_DEV_ENV === 'YES' && tokenLogin){
                window.location.reload();
            } else {
                if (!this.isLogout) {
                    const message = error.code === 'SYS030' ? Config.lang('Khong_du_quyen') : Config.lang('Dang_nhap_lai');
                    this.isLogout = true;
                    Config.popup.show('INFO', message, null, null, () => {
                        Config.logout();
                    });
                    return true;
                }
            }

        }

        return error;
    }
}

export default Api;

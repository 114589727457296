/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 04/11/2021
 * @Example
 */

export const types = {
    WA3F2031_LOAD_FORM: "WA3F2031_LOAD_FORM",
    WA3F2031_GET_BEO_CODE: "WA3F2031_GET_BEO_CODE",
    WA3F2031_ADD_BEO: "WA3F2031_ADD_BEO",
    WA3F2031_CONFIRM_BEO: "WA3F2031_CONFIRM_BEO"
};

export function loadForm(params, cb) {
    return {
        type: types.WA3F2031_LOAD_FORM,
        params,
        cb
    }
}

export function getBEOCode(params, cb) {
    return {
        type: types.WA3F2031_GET_BEO_CODE,
        params,
        cb
    }
}

export function addBEO(params, cb) {
    return {
        type: types.WA3F2031_ADD_BEO,
        params,
        cb
    }
}

export function confirmBEO(params, cb) {
    return {
        type: types.WA3F2031_CONFIRM_BEO,
        params,
        cb
    }
}


/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 16/06/2021
 * @Example
 */

import { types } from "./WA3F2000_actions";

const initialState = {
    dataContractGrid: {}
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.WA3F2000_LOAD_CONTRACT_GRID_SUCCESS: {
            return { ...state, dataContractGrid: action.data };
        }
        default: {
            return state;
        }
    }
}

/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 5/4/2021
 * @Example
 */

import {types} from "./WA3F2103_actions";

const initialState = {
    getForm:        null,
    dataCboBanquet: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.WA3F2103_LOAD_BANQUET_SUCCESS: {
            return {
                ...state,
                dataCboBanquet: action.data
            };
        }
        case types.WA3F2103_LOAD_FORM_SUCCESS: {
            return {
                ...state,
                getForm: action.data
            };
        }
        default:
            return state;
    }
}

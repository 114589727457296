/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 24/06/2022
 * @Example
 */
import { delay } from 'redux-saga';
import { cancel, take, takeLatest } from 'redux-saga/effects';
import Api from '../../../../services/api';
import { types } from "./WA3F2106_actions";

export default function WA3F2106Sagas() {
    return [
        watchLoadForm(),
        watchSave(),
    ];
};

export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2106/get-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load form error');
    }
};

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2106_LOAD_FORM, loadForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/wa3f2106/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save error');
    }
};

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.WA3F2106_SAVE, save);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};